import moment from "moment";
import { getCurrentMonthFirstDate } from "../../../common/shared/utils";

export class IRetrunReplacement {
    returnNo?: number = 0;
    returnDate?: string = getCurrentMonthFirstDate()?.currentDate;
    isReplacement?: boolean = false;
    isReurn?: boolean = false;
    posid?: number = 0;
    returnAmount?: number = 0;
    tempreturnAmount?: number = 0;
    invoiceId?: number = 0
    itemId?: number = 0;
    batchNo?: string = "";
    barcode?: string = "";
    batchId?: number = 0;
    returnQty?: number = 0;
    tempreturnQty?: number = 0;
    returnAmt?: number = 0;
    reason?: string = "";
    replaceItemId?: number = 0;
    replaceBatchId?: number = 0;
    replaceBatchNo?: string = "";
    replaceBarcode?: string = "";
    replaceQty?: number = 0;
    replaceItemName?: string = "";
    enteredBy?: number = 0;
    itemName?: string = "";
    memberId?: number = 0;
    outletId?: number = 0;
    isPos?: boolean = false;
    isInvoice?: boolean = false;
    isSameItem?: boolean = false;
    isOtherItem?: boolean = false;
    terminalId?: number = 0;
    posId?: string = ""
    returnreplacementdtl?: IRetrunReplacementDetails[]
}

export class IRetrunReplacementDetails {
    returnid?: number = 0;
    RposId?: number = 0;
    invoiceId?: number = 0;
    itemId?: number = 0;
    batchNo?: string = "";
    barcode?: string = "";
    batchId?: number = 0;
    returnQty?: number = 0;
    tempreturnQty?: number = 0;
    returnAmt?: number = 0;
    reason?: string = "";
    replaceItemId?: number = 0;
    replaceBatchId?: number = 0;
    replaceBatchNo?: string = "";
    replaceBarcode?: string = "";
    replaceQty?: number = 0;
    replaceItemName?: string = "";
    enteredBy?: number = 0;
    itemName?: string = "";
    memberId?: number = 0;
    outletId?: number = 0;
    isPos?: boolean = false;
    isInvoice?: boolean = false;
    isSameItem?: boolean = false;
    isOtherItem?: boolean = false;
    terminalId?: number = 0;
    posId?: string = ""

}

export class IEntryNoRequest {
    flag?: string = "";
    outletId?: number = 0;
}


export class IPOSItemRequest {
    posId?: number = 0;
    itemId?: number = 0;
    invoiceId?: number = 0;
}