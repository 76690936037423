import React, { useState, useMemo, useEffect, ChangeEvent, useRef } from 'react';
import { Button, Image, Modal, Spinner } from "react-bootstrap";
import { useNavigate, useParams } from 'react-router-dom';
import { IInwardDetails, IInwardLedgers, IItemInward } from "./model";
import AutoComplete from "../../common/shared/autoComplete";
import useStores from "../../hooks";
import { observer } from "mobx-react-lite";
import inwardMasterValidation, { addInwardDetailValidation, addLedgerValidation } from "./validation";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import LedgerMaster from "../../Accounts/LedgerMaster/LedgerMaster";
import { LightTooltip } from "../../common/shared/lightToolPit";
// import Toolpit from '@mui/material/Toolpit';
import HelpIcon from '@mui/icons-material/Help';
import moment from "moment";
import { removeDuplicates } from "../../common/shared/utils";
import Cookies from 'js-cookie';

const ItemInWards = observer((): JSX.Element => {

    const navigate = useNavigate();
    const { inwardNo } = useParams()

    const { supplierMasterStore, commonStore, inwardStore, itemMasterStore, ledgerMasterStore } = useStores();
    const { loadSupplierMastes, loadSuppliers, loadWarehouse, loadWarehouses } = commonStore;
    const { loadItemMasters, loadInwardItemMaster, getBarcodeInward } = inwardStore

    const [inwardDetail, setInwardDetial] = useState<IItemInward>(new IItemInward())
    const [inwardDetailList, setInwardDetailList] = useState<IInwardDetails[]>(new Array<IInwardDetails>())
    const [itemInward, setItemInward] = useState<IInwardDetails>(new IInwardDetails())
    const { getInwardMaster, itemInwardList, getInwardDetails, iteminwardDetails, getLedgers } = inwardStore;

    const [ledger, setLedger] = useState<IInwardLedgers>(new IInwardLedgers());
    const [ledgers, setLedgers] = useState<IInwardLedgers[]>(new Array<IInwardLedgers>());

    const [submitbtn, setSubmitbtn] = useState(false);
    const [errors, setErrors] = useState<any>({});

    const [isBundle, setIsBundle] = useState('')
    const [isBarcode, setIsBarcode] = useState('')
    const [isAgainstPo, setAgainsPo] = useState('')
    const [isLoading, setLoading] = useState(true);
    const [isSpinner, setSpinner] = useState(false);
    const [isSuccessModal, setSuccessModal] = useState(false);
    const [isFailureModal, setFailureModal] = useState(false);
    const [isLoadModal, setLoadModal] = useState(false);
    const [isledgerMaster, setLedgerMaster] = useState(false);
    const [isPoSupplierModel, setIsPosSupplierModel] = useState(false);
    const [poSupplierDetail, setPoSupplierDetail] = useState<any[]>([])
    const [poItemList, setPOItemList] = useState<any[]>([])
    const [loadBarcode, setLoadBarcode] = useState<any>([])
    const [canAdd, setCanAdd] = useState(false);
    const [canEdit, setCanEdit] = useState(false);
    const [canView, setCanView] = useState(false);
    // const [canPrint, setCanPrint] = useState(false);  
    // const [canUpdate, setCanUpdate] = useState(false); 

    const handleFailureModalClose = () => setFailureModal(false);

    console.log('poItemList:', poItemList);
    console.log('loadItemMasters:', loadItemMasters);

    const handleCancel = () => {
        navigate('/Admin/ItemInward/itemInwardDetails');
    }
    const handleSuccessModalClose = () => {
        navigate('/Admin/ItemInward/itemInwardDetails');
    }
    const handleAccessModalClose = () => {
        navigate('/Admin/Home');
    }

    function removeItem(index) {
        if (inwardDetailList?.some((obj, i) => i === index)) {
            inwardDetailList?.splice(index, 1)
            setInwardDetailList([...inwardDetailList])
        }
    }

    function handleItemInwardDiscount(e) {
        const { name, value } = e.target;
        let discountValue: any = 0;
        let gstValue: any = 0;

        if (name === 'discountPercentage' && value !== undefined) {
            discountValue = (Number(value) / 100) * (Number(itemInward.inwardItemQuantity) * Number(itemInward.inwardItemRate));
            let values = (Number(itemInward.inwardItemvalue) - discountValue);
            gstValue = (Number(itemInward.inwardGstPercentage) / 100) * values
        }
        if (name === 'discountPercentage') {
            setItemInward({ ...itemInward, inwardDisPercentage: Number(value), inwardDiscountvalue: (discountValue.toFixed(2) ?? '0'), inwardGstValue: Number(gstValue.toFixed(2)) })
        } else {
            setItemInward({ ...itemInward, [name]: value })
        }
        // setItemInward({ ...itemInward, inwardDisPercentage: Number(e.target.value) })
    }

    function handleInputChange(e) {
        const { name, value } = e.target;
        let discountValue: any = 0;
        let gstValue: any = 0;

        if (name === "discountType") {
            inwardDetail.discount = 0;
            inwardDetail.discountAmount = 0;
            inwardDetail.discountValue = discountValue?.toFixed(2);
            setInwardDetial({ ...inwardDetail, [name]: value });
        } else if (name === 'discount' && Number(value) !== undefined) {
            let totalValue = inwardDetailList != null ? inwardDetailList.reduce((a, v) => a = a + v.inwardTotalValue!, 0) : 0;
            if (inwardDetail.discountType === 'Dis%') {
                inwardDetail.discountAmount = totalValue! / 100 * Number(value);
            } else if (inwardDetail.discountType === 'Value') {
                inwardDetail.discountAmount = Number(value);
            }
            setInwardDetial({ ...inwardDetail, [name]: Number(value) })
        } else {
            setInwardDetial({ ...inwardDetail, [name]: value })
        }
    }

    function handlePurcRate(event) {
        setItemInward({ ...itemInward, inwardItemRate: Number(event.target.value) });
        setErrors({ ...errors, inwardItemRate: '' })
    }

    const handleLedgerChange = (e) => {
        const { name, value } = e.target;
        setLedger({ ...ledger, [name]: Number(value) });
    }

    const handleSupplierValue = async (name: any, val: any) => {
        if (name === "supplierId" && val != undefined) {
            inwardDetail.supplierName = val.supplierName;
            setInwardDetial({ ...inwardDetail, [name]: val.supplierId });
            if (isAgainstPo) {
                setSpinner(true)
                getSuppPurchaseDetail(val.supplierId)
                setPOItemList([])
            }
        } else if (name === "warehouseId" && val != undefined) {
            setInwardDetial({ ...inwardDetail, [name]: val.warehouseId, wareHouseName: val.warehouseName });
        } else if (name === "ledgerId" && val != undefined) {
            setLedger({ ...ledger, ledgerId: val.ledgerId, ledgerName: val.ledgerName });
        }
    }

    async function getSuppPurchaseDetail(supid) {
        const supPurchaseDetail = await inwardStore.getPurchaseOrderDetail(supid)
        setPoSupplierDetail([...supPurchaseDetail])
        setIsPosSupplierModel(true)
        setSpinner(false)
    }

    function handleFilterItems(event, Supplier_id) {
        const { checked, value } = event.target;
        if (checked) {
            const itemList = poSupplierDetail?.filter((obj) => obj?.Purchase_Order_No === value);
            itemList?.map((obj) => {
                loadItemMasters?.map((itemMaster) => {
                    if (itemMaster?.itemid === obj?.itemid) {
                        obj['gstPercentage'] = itemMaster?.gstPercentage;
                        obj['gst'] = itemMaster?.gst;
                        obj['pono'] = Number(obj?.Purchase_Order_No);
                        return obj;
                    }
                })
            })
            let isExistingSupplier = poItemList?.some((each) => each?.Supplier_id === Supplier_id)
            if (isExistingSupplier) {
                setPOItemList([...poItemList, ...itemList])
            } else {
                setPOItemList([...itemList])
            }
        } else {
            setPOItemList(prevArr => prevArr.filter((obj) => obj?.Purchase_Order_No != value))
        }
    }

    const addDaysToDate = (days, currentDate) => {
        const dateObj = new Date(currentDate);
        if (isNaN(dateObj.getTime())) {
            throw new Error("Invalid date format.");
        }
        dateObj.setDate(dateObj.getDate() + days);
        return dateObj.toISOString().split('T')[0];
    };

    const handleGetBarcodeInward = async (val: any) => {
        try {
            const itemInwardList = await inwardStore.getBarcodeInward(val?.itemid);
            console.log(itemInwardList, 'itemInwardList');
            setLoadBarcode([...itemInwardList]);
            console.log(loadBarcode, 'loadBarcode');
        } catch (error) {
            console.error('Error fetching barcode data:', error);
        }
    };

    useEffect(() => {
        console.log(loadBarcode, 'Updated loadBarcode');
    }, [loadBarcode]);

    const handleItemMaster = (name: any, val: any) => {
        console.log(val, 'valValues');

        if (val && name === "itemId") {
            handleGetBarcodeInward(val);
            console.log(loadBarcode, 'loadBarcode');
            const expiryDays = Number(val?.expiryDays) || 0;
            const currentDate = new Date();
            setItemInward(prev => ({
                ...prev,
                inwardItemid: val?.itemid,
                inwardItemName: isAgainstPo ? val?.itemname : val?.itemName,
                inwardGstPercentage: val?.gstPercentage,
                gstLedger: val?.gst,
                inwardDisPercentage: 0,
                pono: val?.pono,
                exp_Date: addDaysToDate(expiryDays, currentDate),
                expiryDays: expiryDays
            }));
        }
    }

    const handleSellingInput = (e) => {

        let b2bamount: any = 0;
        let b2camount: any = 0;

        b2bamount = (Number(itemInward.inwardItemMrpRate) - (Number(itemInward?.inwardItemMrpRate) * 0.1))?.toFixed(2);
        b2camount = (Number(itemInward.inwardItemMrpRate) - (Number(itemInward?.inwardItemMrpRate) * 0.12))?.toFixed(2);
        itemInward.b2Bper! = 10;
        itemInward.b2cper! = 12;
        itemInward.b2CnewPrice = Number(b2camount);
        itemInward.b2BnewPrice = Number(b2bamount)

    }

    function addInwardDetails(e) {

        e.preventDefault();
        itemInward.inwardTotalValue = (Number(itemInward.inwardItemvalue) + Number(itemInward.inwardGstValue) - Number(itemInward.inwardDiscountvalue ?? 0));
        itemInward.inwardValue = Number(itemInward.inwardItemvalue) - Number(itemInward.inwardDiscountvalue ?? 0);

        if (isBundle === "on") {
            itemInward.inwardbundleqty! = itemInward?.inwardFreeQuantiity!
            itemInward.inwardFreeQuantiity! = 0;
        } else {
            itemInward.inwardbundleqty = 0;
        }

        let error: any = {};
        error = addInwardDetailValidation(itemInward);
        setErrors(error);

        if (Object.keys(error).length === 0) {
            setSubmitbtn(true);
            handleSellingInput(e)
            setInwardDetailList([...inwardDetailList, itemInward])
            setItemInward(new IInwardDetails())
            setIsBundle('')
        }

    }

    function addLedgers(e) {
        e.preventDefault();
        let error: any = {};
        error = addLedgerValidation(ledger)
        setErrors(error);
        if (Object.keys(error)?.length === 0) {
            setLedgers([...ledgers, ledger]);
            setLedger({ ...ledger, ledgerId: 0, ledgerName: '', ledgerAmount: 0 })
        }
    }

    const deleteLedger = (Id) => {
        setLedgers(ledgers.filter(m => m.Id !== Id));
    }

    async function saveInwardDetails(e) {
        e.preventDefault();
        inwardDetail.inwardDetailList = inwardDetailList;
        inwardDetail.ledgers = ledgers;


        inwardDetail.Ntotalvalue = Number((Number(inwardDetail.inwardDetailList?.reduce((a, v) => a + v?.inwardItemvalue!, 0)) - Number(inwardDetail.inwardDetailList?.reduce((a, v) => a + Number(v?.inwardDiscountvalue!), 0)) + Number(inwardDetail?.inwardDetailList?.reduce((a, v) => a + v?.inwardGstValue!, 0))));
        inwardDetail.NDiscountV = Number(inwardDetail.inwardDetailList?.reduce((a, v) => a + Number(v?.inwardDiscountvalue!), 0));
       
        
        const totalgstValue = inwardDetail.inwardDetailList?.reduce((total, item) => total + (item?.inwardGstValue || 0),0);
      
        const totalItemValue = inwardDetail.inwardDetailList?.reduce((total, item) => total + (item?.inwardItemvalue || 0),0);
        const totalDiscountValue = inwardDetail.inwardDetailList?.reduce((total, item) => total + Number((item?.inwardDiscountvalue)|| 0),0);
        inwardDetail.Netvalue = Number((totalItemValue - totalDiscountValue).toFixed(2));
        inwardDetail.Ntotalvalue =Number((totalItemValue - totalDiscountValue + totalgstValue).toFixed(2));
        // inwardDetail.discount = inwardDetail?.discountAmount?.toFixed(2);
        let error: any = {};

        error = inwardMasterValidation(inwardDetail, 'itemInward')
        console.log(inwardDetail, 'itemInward');
        
        setErrors(error)
        if (Object.keys(error)?.length === 0) {
            setLoading(true);
            const status = await inwardStore.saveItemInward(inwardDetail);

            if (status === "Success") {
                setLoading(false);
                setSuccessModal(true)
            }
            else {
                setLoading(false);
                setFailureModal(true);
            }
        }
    }

    async function handleLedgerClose() {
        await itemMasterStore.getTaxMaster('InwardLedger');
        setLedgerMaster(false)
    }

    const isInitialRender = useRef(true);

    async function fetchPreLoadngData() {
        /**
       * EDIT FLOW
       */
        if (inwardNo) {
            const data = await getInwardMaster(); // Fetch the data
            const datadtls = await getInwardDetails(Number(inwardNo));
            const filteredData = data.filter(obj => Number(obj.inwardNo) === Number(inwardNo)); // Filter items with billno === 12
          
            console.log(datadtls,'datadtls555')

            const date = new Date(filteredData[0]?.billDate);
            const options: any = { timeZone: 'Asia/Kolkata', year: 'numeric', month: '2-digit', day: '2-digit' };
            const [day, month, year] = date.toLocaleDateString('en-GB', options).split('/');
            const formattedDate = `${year}-${month}-${day}`;

            const date1 = new Date(filteredData[0]?.inwarddate);
            const [day1, month1, year1] = date1.toLocaleDateString('en-GB', options).split('/');
            const formattedDate1 = `${year1}-${month1}-${day1}`;

            const updatedInwardDetail = {
                ...filteredData[0],
                inwardNo: Number(inwardNo),
                billDate: formattedDate,
                inwardDate:formattedDate1,
            };
            console.log(updatedInwardDetail,'filteredData22');
            setInwardDetial(updatedInwardDetail);
            setInwardDetailList(datadtls);
            setSubmitbtn(true);
            setLoading(false)


        } else {
            /**
             * ADD FLOW
             */



            setLoading(false)
            const [entryNo] = await Promise.all([itemMasterStore.getEntryNo('Inward'),
           , 
           
            ])
            setInwardDetial({ ...inwardDetail, inwardNo: entryNo?.entryNo + 1 })
        }
        await loadSupplierMastes('');
        await ledgerMasterStore.getLedgerMaster();
        await itemMasterStore.getTaxMaster('InwardLedger');
        await  loadWarehouse();
        await loadInwardItemMaster();
        await itemMasterStore.getTaxMaster('InwardLedger');

    }

    const netAmount = useMemo(() => {
        let totalNetAmount: number = inwardDetailList?.length ? inwardDetailList.reduce((a, v) => a = a + v.inwardTotalValue!, 0) : 0;
        if (!ledgers?.length) {
            if (inwardDetail.discountAmount && totalNetAmount && inwardDetail?.discountType !== "None") {
                return Math.round(totalNetAmount - (inwardDetail?.discountAmount ?? 0))?.toFixed(2)
            } else {
                return Math.round(totalNetAmount)?.toFixed(2)
            }
        } else if (ledgers?.length) {
            return Math.round(totalNetAmount + ledgers?.reduce((a, v) => a = a + v.ledgerAmount!, 0) - Number(inwardDetail.discountAmount ?? 0))
        }
        return 0;
    }, [ledgers?.length, inwardDetail, inwardDetailList])

    const discountType = useMemo(() => {
        if (!inwardDetail?.discountType || inwardDetail?.discountType === "None") {
            let discountValue: any = 0;
            setInwardDetial({ ...inwardDetail, discount: 0, discountAmount: 0, discountType: 'None', discountValue: discountValue?.toFixed(2) })
        }
    }, [inwardDetail?.discountType])

    const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.checked) {
            setIsBarcode("someValue");
        } else {
            setIsBarcode("");
        }
    };

    // useEffect(() => {
    //     if (isInitialRender.current) {
    //         fetchPreLoadngData();
    //         isInitialRender.current = false;
    //     } else {
    //     }
    //     return () => {
    //         // Cleanup logic (if needed)
    //     };
    // }, []);
    const isCurrenPage = useRef(true)
    const empid = Number(Cookies.get('userId'));
    const formName = 'Item_inward';

    useEffect(() => {

        const fetchRights = async () => {

            try {
                const data = await supplierMasterStore.fetchRights(empid, formName);

                if (Array.isArray(data) && data.length > 0) {
                    const specificFieldValue = data[0].sts;
                    const rightsValue = data[0].rights;
                    const lettersArray = rightsValue.split('');
                    if (specificFieldValue === 1) {
                        if (isCurrenPage.current) {
                            fetchPreLoadngData();
                            let getCities: any;
                            isCurrenPage.current = false
                            if (lettersArray.includes('a')) {
                                setCanAdd(true);
                            } else {
                                navigate('/Admin/ItemInward/itemInwardDetails');
                            }
                            if (lettersArray.includes('e')) {
                                setCanEdit(true);
                            }
                            // if (lettersArray.includes('v')) {
                            //   setCanView(true); 
                            // }     
                            // if (lettersArray.includes('p')) {
                            //   setCanPrint(true); 
                            // }       
                            // if (lettersArray.includes('u')) {
                            //   setCanUpdate(true); 
                            // }                     
                        }
                    } else if (specificFieldValue === 0) {
                        setLoadModal(true)
                    }
                }

            } catch (error) {
                console.error("Error fetching rights:", error);
            }
        };
        fetchRights();
    }, [empid, formName]);

    if (isLoadModal) {
        return (
            <div className="container">
                {isLoadModal && <h1>Access Denied</h1>}
                <Modal show={isLoadModal} onHide={handleAccessModalClose} className='SubmitModal'>
                    <Modal.Body>
                        <div className='Details Success'>
                            <div className='imgBox'>
                                <Image src={require('../../../gsmecom/images/warning.png')} />
                            </div>
                            <h4>Access Denied</h4>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleAccessModalClose}>Ok</Button>
                    </Modal.Footer>
                </Modal>
            </div>
        );
    }
    else {

        return (
            <>

                <div className='container'>
                    {isSpinner ?
                        <div className='SpinnerBox'>
                            <Spinner animation="grow" size="sm" />
                        </div> : null}
                    <div className='vertical-space-20'></div>
                    <div className='outletInputField inputFormBox LgInputField'>
                        <div className='hrBox'>
                            <h3>Item Inward</h3>
                        </div>
                        <div className='inputBoxLists'>
                            <div className='ItemInwardInputBox'>
                                <div className="row">
                                    <div className="col-sm-3">
                                        <div className='inputBox'>
                                            <label>Entry No <span>*</span></label>
                                            <input type="text" placeholder="Entry No"
                                                name='inwardNo' value={inwardDetail.inwardNo} disabled>
                                            </input>
                                        </div>
                                    </div>
                                    <div className="col-sm-3">
                                        <div className='inputBox'>
                                            <label>Date <span>*</span></label>
                                            <input type="date" placeholder="DD-MMM-YYYY" data-date=""
                                                name='inwardDate' value={inwardDetail.inwardDate = inwardDetail.inwardDate ?? new Date().toISOString().substr(0, 10)}
                                                onKeyDown={(event) => event.preventDefault()}
                                                data-date-format="DD MMMM YYYY" onChange={handleInputChange}>
                                            </input>
                                            {errors.inwardDate && <p style={{ color: 'red' }}>{errors.inwardDate}</p>}
                                        </div>
                                    </div>
                                    <div className="col-sm-2">
                                        <div className='inputradio mt-3'>
                                            <input type='checkbox' checked={isAgainstPo != ""} onChange={(e) => setAgainsPo(e.target.value)} className='mt-4 justifyContentStart'
                                                name='againstPo' ></input>
                                            <label className="mx-2">Against PO</label>
                                        </div>
                                    </div>
                                </div>
                                <div className='vertical-space-10'></div>
                                <div className='row'>
                                    <div className='col-sm-3'>
                                        <div className='inputBox'>
                                            <label>Supplier <span>*</span></label>
                                            <AutoComplete options={loadSuppliers} getOptionLabel='supplierName'
                                              value={inwardDetail.supplierName}
                                                placeholder={"Select Supplier..."}
                                                emitOption={(option: any) => handleSupplierValue('supplierId', option)}
                                                clsName="full-width"
                                            />
                                            {errors.supplierId && <p style={{ color: 'red' }}>{errors.supplierId}</p>}
                                        </div>
                                        <div className='vertical-space-10'></div>
                                    </div>
                                    <div className='col-sm-3'>
                                        <div className='inputBox'>
                                            <label>Warehouse <span>*</span></label>
                                            <AutoComplete options={loadWarehouses} getOptionLabel='warehouseName'
                                             value={inwardDetail.wareHouseName}
                                                placeholder={"Select Warehouse..."} clsName="full-width"
                                                emitOption={(option: any) => handleSupplierValue('warehouseId', option)}
                                            />
                                            {errors.warehouseId && <p style={{ color: 'red' }}>{errors.warehouseId}</p>}
                                        </div>
                                        <div className='vertical-space-10'></div>
                                    </div>
                                    <div className="col-sm-3">
                                        <div className='inputBox'>
                                            <label>Bill Number <span>*</span></label>
                                            <input type="text" className='' name='billNo'
                                                value={inwardDetail.billNo} onChange={handleInputChange}
                                                style={{ width: "100%" }} placeholder='Bill Number..'></input>
                                            {errors.billNo && <p style={{ color: 'red' }}>{errors.billNo}</p>}
                                        </div>
                                        <div className='vertical-space-10'></div>
                                    </div>
                                    <div className='col-sm-3'>
                                        <div className='inputBox'>
                                            <label>Bill Date <span>*</span></label>
                                            <input type="date" style={{ width: "100%" }} placeholder="DD-MMM-YYYY"
                                                name='billDate' value={inwardDetail.billDate = inwardDetail.billDate ?? new Date().toISOString().substr(0, 10)}
                                                data-date="" data-date-format="DD MMMM YYYY" onKeyDown={(event) => event.preventDefault()} onChange={handleInputChange}></input>
                                            {errors.billDate && <p style={{ color: 'red' }}>{errors.billDate}</p>}
                                        </div>
                                        <div className='vertical-space-10'></div>
                                    </div>
                                </div>
                                <div className='vertical-space-20'></div>
                                <div className='row'>
                                    <div className='col-sm-6'>
                                        <div className='inputBox'>
                                            <label>Item <span>*</span></label>
                                            <React.Fragment>
                                                <AutoComplete
                                                    placeholder="Select Item"
                                                    emitOption={(val) => handleItemMaster('itemId', val)}
                                                    value={itemInward?.inwardItemName}
                                                    //options={loadItemMasters}
                                                    options={isAgainstPo ? poItemList : loadItemMasters}
                                                    getOptionLabel='label'
                                                    clsName="full-width"
                                                />
                                            </React.Fragment>
                                            {errors.inwardItemName && <p style={{ color: 'red' }}>{errors.inwardItemName}</p>}
                                        </div>
                                        <div className='vertical-space-10'></div>
                                    </div>
                                    <div className='col-sm-2'>
                                        <div className='inputBox'>
                                            <label>Bill Qty <span>*</span></label>
                                            <input type="number" className='' style={{ width: "100%" }}
                                                name='inwardItemQuantity'
                                                value={itemInward?.inwardItemQuantity!}
                                                onChange={(e) => setItemInward({ ...itemInward, inwardItemQuantity: Number(e.target.value) })}
                                                placeholder='Bill Qty..' />

                                            {errors.inwardItemQuantity && <p style={{ color: 'red' }}>{errors.inwardItemQuantity}</p>}
                                        </div>
                                        <div className='vertical-space-10'></div>
                                    </div>
                                    <div className='col-sm-2'>
                                        <div className='inputBox'>
                                            <label>Free Qty</label>
                                            <input type="number" className='' style={{ width: "100%" }}
                                                name='freeQuantiity'
                                                value={itemInward?.inwardFreeQuantiity!}
                                                onChange={(e) => setItemInward({ ...itemInward, inwardFreeQuantiity: Number(e.target.value) })}
                                                placeholder='Free Qty..' />
                                            {errors.freeQuantiity && <p style={{ color: 'red' }}>{errors.freeQuantiity}</p>}
                                        </div>
                                        <div className='vertical-space-10'></div>
                                    </div>
                                    <div className="col-sm-2">
                                        <div className='inputradio mt-3'>
                                            <input type='checkbox' checked={isBundle != ""} onChange={(e) => setIsBundle(e.target.value)} className='mt-4 justifyContentStart' name='bundleQty' ></input>
                                            <label className="mx-2">Bundle Qty
                                                <LightTooltip placement="top" arrow title="Must click if Free from Brandowner & it won't add stock / Bill value, without click this then it will goes stock, but not added billvalue">
                                                    <HelpIcon sx={{ marginLeft: '2px', color: '#DC3545', fontSize: '1.25rem', marginBottom: '2px' }} />
                                                </LightTooltip>
                                            </label>
                                        </div>
                                    </div>
                                    <div className='col-sm-2'>
                                        <div className='inputBox'>
                                            <label>Mfg Date <span>*</span></label>
                                            <input type="date" className='' style={{ width: "100%" }}
                                                name='mfg_Date'
                                                defaultValue={new Date().toISOString().substr(0, 10)}
                                                value={itemInward.mfg_Date = itemInward?.mfg_Date ? itemInward?.mfg_Date : new Date().toISOString().substr(0, 10)}
                                                onChange={(e) => {
                                                    const selectedDate = e.target.value;
                                                    const expiryDays = itemInward?.expiryDays || 0;
                                                    const expDate = addDaysToDate(expiryDays, selectedDate);

                                                    setItemInward(prev => ({
                                                        ...prev,
                                                        mfg_Date: selectedDate,
                                                        exp_Date: expDate
                                                    }));
                                                }}
                                                placeholder="DD-MMM-YYYY" data-date="" data-date-format="DD MMMM YYYY" />
                                            {errors.mfg_Date && <p style={{ color: 'red' }}>{errors.mfg_Date}</p>}
                                        </div>
                                        <div className='vertical-space-10'></div>
                                    </div>
                                    <div className='col-sm-2'>
                                        <div className='inputBox'>
                                            <label>Expiry Date <span>*</span></label>
                                            <input type="date" className='' style={{ width: "100%" }}
                                                name='exp_Date'
                                                disabled={itemInward.expiryDays !== 0}
                                                defaultValue={new Date().toISOString().substr(0, 10)}
                                                value={itemInward.exp_Date = itemInward?.exp_Date ? itemInward?.exp_Date : new Date().toISOString().substr(0, 10)}
                                                onChange={(e) => setItemInward({ ...itemInward, exp_Date: e.target.value })}
                                                placeholder="DD-MMM-YYYY" data-date="" data-date-format="DD MMMM YYYY" />
                                            {errors.exp_Date && <p style={{ color: 'red' }}>{errors.exp_Date}</p>}
                                        </div>
                                        <div className='vertical-space-10'></div>
                                    </div>
                                    <div className='col-sm-2'>
                                        <div className='inputBox'>
                                            <label>Barcode </label>
                                            {isBarcode !== "" ? (
                                                <input type="text" className='' style={{ width: "100%" }}
                                                    name='barcode'
                                                    value={itemInward?.barcode!}
                                                    onChange={(e) => setItemInward({ ...itemInward, barcode: e.target.value })}
                                                    placeholder='Enter Barcode..' />
                                            ) : (
                                                <AutoComplete
                                                    clsName='full-width'
                                                    value={itemInward?.barcode}
                                                    placeholder='Select Barcode..'
                                                    options={removeDuplicates(loadBarcode, 'barcode')}
                                                    emitOption={(option) => setItemInward({ ...itemInward, barcode: option.barcode })} // Correct emitOption function
                                                    getOptionLabel='barcode'
                                                    freeSolo={true}
                                                />
                                            )}
                                            {errors.barcode && <p style={{ color: 'red' }}>{errors.barcode}</p>}
                                            {/* <input type="text" className='' style={{ width: "100%" }}
                                                    name='barcode'
                                                    value={itemInward?.barcode!}
                                                    onChange={(e) => setItemInward({ ...itemInward, barcode: e.target.value })}
                                                    placeholder='Enter Barcode..' /> */}
                                            {/* {errors.barcode && <p style={{ color: 'red' }}>{errors.barcode}</p>} */}
                                        </div>
                                        <div className='vertical-space-10'></div>
                                    </div>
                                    <div className="col-sm-2">
                                        <div className='inputradio mt-3'>
                                            <input type='checkbox' checked={isBarcode != ""} onChange={handleCheckboxChange} className='mt-4 justifyContentStart' name='bundleQty' ></input>
                                            <label className="mx-2">New Barcode
                                            </label>
                                        </div>
                                    </div>
                                    <div className='col-sm-2'>
                                        <div className='inputBox'>
                                            <label>Batch No </label>
                                            <input type="text" className='' style={{ width: "100%" }}
                                                name='batchNo'
                                                value={itemInward?.batchNo!}
                                                onChange={(e) => setItemInward({ ...itemInward, batchNo: e.target.value })}
                                                placeholder='Enter Batch No..' />
                                            {errors.batchNo && <p style={{ color: 'red' }}>{errors.batchNo}</p>}
                                        </div>
                                        <div className='vertical-space-10'></div>
                                    </div>
                                    <div className='col-sm-2'>
                                        <div className='inputBox'>
                                            <label>MRP <span>*</span>
                                                <LightTooltip placement="top" arrow title="MRP value reflects to Selling Price Update">
                                                    <HelpIcon sx={{ marginLeft: '2px', color: '#DC3545', fontSize: '1.25rem', marginBottom: '2px' }} />
                                                </LightTooltip>
                                            </label>
                                            <input type="number" className='' style={{ width: "100%" }}
                                                name='inwardItemMrpRate'
                                                value={itemInward?.inwardItemMrpRate!}
                                                onChange={(e) => setItemInward({ ...itemInward, inwardItemMrpRate: Number(e.target.value) })}
                                                placeholder='MRP..' />
                                            {errors.inwardItemMrpRate && <p style={{ color: 'red' }}>{errors.inwardItemMrpRate}</p>}
                                        </div>
                                        <div className='vertical-space-10'></div>
                                    </div>
                                    <div className='col-sm-2'>
                                        <div className='inputBox'>
                                            <label>Pur. Rate <span>*</span>
                                                <LightTooltip placement="top" arrow title="It reflects for Party Accounts">
                                                    <HelpIcon sx={{ marginLeft: '2px', color: '#DC3545', fontSize: '1.25rem', marginBottom: '2px' }} />
                                                </LightTooltip>
                                            </label>
                                            <input type="number" className='' style={{ width: "100%" }}
                                                name='itemRate'
                                                value={itemInward?.inwardItemRate!}
                                                onChange={(e) => Number(e.target.value) <= Number(itemInward?.inwardItemMrpRate!) ? handlePurcRate(e) : setErrors({ ...errors, inwardItemRate: 'Pur. Rate cannot be greater than MRP' })}
                                                placeholder='Rate..' />
                                            {errors.inwardItemRate && <p style={{ color: 'red' }}>{errors.inwardItemRate}</p>}
                                        </div>
                                        <div className='vertical-space-10'></div>
                                    </div>
                                    <div className='col-sm-2'>
                                        <div className='inputBox'>
                                            <label>Total Value</label>
                                            <input disabled type="text" className='' name='totalValue'
                                                value={itemInward.inwardItemvalue = (Number(itemInward.inwardItemQuantity) * Number(itemInward.inwardItemRate))}
                                                style={{ width: "100%" }} placeholder='Total Value..' />
                                        </div>
                                        <div className='vertical-space-10'></div>
                                    </div>
                                    <div className='col-sm-2'>
                                        <div className='inputBox'>
                                            <label>Dis %</label>
                                            <input type="number" className=''
                                                style={{ width: "100%" }}
                                                onChange={handleItemInwardDiscount}
                                                value={itemInward?.inwardDisPercentage!}
                                                name='discountPercentage'
                                                placeholder='Dis %..'></input>
                                            {errors.discountPercentage && <p style={{ color: 'red' }}>{errors.discountPercentage}</p>}
                                        </div>
                                        <div className='vertical-space-10'></div>
                                    </div>
                                    <div className='col-sm-2'>
                                        <div className='inputBox'>
                                            <label>Dis Value</label>
                                            <input type="number" disabled
                                                value={itemInward?.inwardDiscountvalue!}
                                                className='' style={{ width: "100%" }} name='inwardDiscountvalue' placeholder='Dis Value..'></input>
                                        </div>
                                        <div className='vertical-space-10'></div>
                                    </div>
                                    {itemInward.gstLedger ?
                                        <div className='col-sm-4'>
                                            <div className='inputBox'>
                                                <label>GST Ledger </label>
                                                <input disabled type="text" className='' style={{ width: "100%" }}
                                                    name='gstLedger' value={itemInward.gstLedger}
                                                    placeholder='GST Ledger..'></input>
                                            </div>
                                            <div className='vertical-space-10'></div>
                                        </div> : null
                                    }
                                    {itemInward.inwardGstPercentage ?
                                        <div className='col-sm-2'>
                                            <div className='inputBox'>
                                                <label>GST % </label>
                                                <input disabled type="text" className='' style={{ width: "100%" }}
                                                    value={itemInward.inwardGstPercentage} name='gstPercentage'
                                                    placeholder='GST %..'></input>
                                            </div>
                                            <div className='vertical-space-10'></div>
                                        </div> : null
                                    }
                                    {
                                        itemInward.inwardGstPercentage ?
                                            <div className='col-sm-2'>
                                                <div className='inputBox'>
                                                    <label>GST Value </label>
                                                    <input type="text" style={{ width: "100%" }}
                                                        name='gstValue' disabled
                                                        defaultValue={itemInward.inwardGstValue = ((Number(itemInward.inwardGstPercentage) / 100) * (Number(itemInward.inwardItemvalue) - ((Number(itemInward?.inwardDisPercentage! ?? 0) / 100) * (Number(itemInward.inwardItemQuantity) * Number(itemInward.inwardItemRate)))))}
                                                        value={itemInward?.inwardGstValue?.toFixed(2)}
                                                        placeholder='GST Value..' />
                                                </div>
                                            </div> : null
                                    }

                                    <div className='col-sm-4 text-right'>
                                        <button className='dfBtn' onClick={addInwardDetails}>ADD</button>
                                    </div>
                                </div>
                            </div>
                            <div className='vertical-space-10'></div>
                            <div className='tableBox' style={{ overflow: 'scroll', maxHeight: '400px' }}>
                                <table className="table table-striped">
                                    <thead>
                                        <tr>
                                            <th scope="col">Name</th>
                                            <th scope="col">Bill Qty</th>
                                            <th scope="col">Free Qty</th>
                                            <th scope="col">Mfg Date</th>
                                            <th scope="col">Expiry Date</th>
                                            <th scope="col">Barcode </th>
                                            <th scope="col">Batch No</th>
                                            <th scope="col">MRP</th>
                                            <th scope="col">Pur. Rate</th>
                                            <th scope="col">Dis %</th>
                                            <th scope="col">Dis Value</th>
                                            <th scope="col">GST %</th>
                                            <th scope="col">GST Value</th>
                                            <th scope="col">Total</th>
                                            <th scope="col"></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {inwardDetailList.map((val, key) => {
                                            return (
                                                <>
                                                    <tr key={key}>
                                                        <td>{val.inwardItemName}</td>
                                                        <td>{val.inwardItemQuantity}</td>
                                                        <td>{val.inwardbundleqty ? val.inwardbundleqty : val.inwardFreeQuantiity}</td>
                                                        <td>{val?.mfg_Date}</td>
                                                        <td>{val?.exp_Date}</td>
                                                        <td>{val?.barcode}</td>
                                                        <td>{val?.batchNo}</td>
                                                        <td>{val?.inwardItemMrpRate}</td>
                                                        <td>{val.inwardItemRate}</td>
                                                        <td>{val.inwardDisPercentage}</td>
                                                        <td>{val.inwardDiscountvalue}</td>
                                                        <td>{val.inwardGstPercentage!.toFixed(2)}</td>
                                                        <td>{Number(val.inwardGstValue)?.toFixed(2)}</td>
                                                        <td>{val.inwardTotalValue!.toFixed(2)}</td>
                                                        <td><button onClick={() => removeItem(key)} className='delete'><FontAwesomeIcon icon={faTrashAlt} /></button></td>
                                                    </tr>
                                                </>
                                            )
                                        })}
                                        <tr>
                                            <td colSpan={12}>Grand Total</td>
                                            <td>{inwardDetailList != null ? inwardDetailList.reduce((a, v) => a = a + v.inwardTotalValue!, 0).toFixed(2) : 0}</td>
                                        </tr>
                                    </tbody>
                                </table>
                                {errors?.inwardDetailList && <p style={{ color: 'red' }}>{errors?.inwardDetailList}</p>}
                            </div>
                            <div className='vertical-space-10'></div>
                            <div className='row'>
                                <div className='col-sm-2'>
                                    <div className='inputBox'>
                                        <label>DiscountType </label>
                                        <select className="form-selected full-width"
                                            name='discountType'
                                            id="Mode" value={inwardDetail.discountType} onChange={handleInputChange}>
                                            <option selected>None</option>
                                            <option value="Dis%">Dis %</option>
                                            <option value="Value">Value</option>
                                        </select>
                                    </div>
                                    <div className='vertical-space-10'></div>
                                </div>
                                <div className='col-sm-2'>
                                    <div className='inputBox'>
                                        <label>Discount </label>
                                        <input type="number" className='' style={{ width: "100%" }}
                                            name='discount' value={inwardDetail?.discount}
                                            onChange={handleInputChange}
                                            placeholder='Discount..' />
                                    </div>
                                    <div className='vertical-space-10'></div>
                                </div>
                                <div className='col-sm-2'>
                                    <div className='inputBox'>
                                        <label>Discount Amount </label>
                                        <input type="number" className='' style={{ width: "100%" }}
                                            disabled
                                            name='discountAmount' value={inwardDetail?.discountAmount?.toFixed(2)}
                                        />
                                    </div>
                                    <div className='vertical-space-10'></div>
                                </div>
                            </div>

                            <div className='row'>
                                <div className='col-sm-4'>
                                    <div className='inputBox'>
                                        <label>Ledger </label>
                                        <AutoComplete value={ledger?.ledgerName} options={itemMasterStore?.taxMasters} getOptionLabel='warehouseName'
                                            placeholder={"Select Warehouse..."} clsName="full-width"
                                            emitOption={(option: any) => handleSupplierValue('ledgerId', option)}
                                        />
                                    </div>
                                    {errors.ledgerId && <p style={{ color: 'red' }}>{errors.ledgerId}</p>}
                                    <div className='vertical-space-10'></div>
                                </div>
                                <div className='col-md-2'>
                                    <button className='dfBtn' style={{ marginTop: '30px' }} onClick={() => setLedgerMaster(true)}>New</button>
                                </div>
                                <div className='col-sm-2'>
                                    <div className='inputBox'>
                                        <label>Amount </label>
                                        <input type="number" className='' style={{ width: "100%" }}
                                            name='ledgerAmount'
                                            value={ledger.ledgerAmount}
                                            onChange={handleLedgerChange}
                                        />
                                        {errors.ledgerAmount && <p style={{ color: 'red' }}>{errors.ledgerAmount}</p>}
                                    </div>
                                    <div className='vertical-space-10'></div>
                                </div>
                                <div className='col-sm-1 text-right'>
                                    <button className='dfBtn' onClick={addLedgers} >ADD</button>
                                </div>
                                <div className='col-sm-3 text-right'>
                                    <div className='inputBox'>
                                        <h4>Net Amount : <span>&#8377;</span> {netAmount}</h4>
                                    </div>
                                </div>


                                <div className='tableBox'>
                                    <table className="table table-striped">
                                        <thead>
                                            <tr>
                                                <th scope="col">Id</th>
                                                <th scope="col">Ledger</th>
                                                <th scope="col">Amount</th>
                                                <th></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {ledgers.map((val, key) => {
                                                return (
                                                    <tr key={key}>
                                                        <td>{val.Id = key + 1}</td>
                                                        <td>{val.ledgerName}</td>
                                                        <td>{val.ledgerAmount}</td>
                                                        <td><button className='delete'><FontAwesomeIcon icon={faTrashAlt}
                                                            onClick={() => deleteLedger(val?.Id)} /></button></td>
                                                    </tr>
                                                )
                                            })}
                                        </tbody>
                                    </table>

                                </div>
                            </div>
                        </div>
                        <div className='btnBox'>
                            <button className='secondaryBtn' onClick={handleCancel}>List</button>
                            <LightTooltip placement="top" arrow title="Data transferred to warehouse stock & Accounts Module">
                                <button className='dfBtn' type='submit' onClick={saveInwardDetails} disabled={!submitbtn}>Submit</button>
                            </LightTooltip>
                        </div>
                    </div>

                    {/* Modals */}
                    <Modal show={isSuccessModal} onHide={handleSuccessModalClose} className='SubmitModal'>
                        <Modal.Body>
                            <div className='Details Success'>
                                <div className='imgBox'>
                                    <Image src={require('../../../gsmecom/images/checked.png')} />
                                </div>

                                <h4>Succesfully Submitted</h4>
                            </div>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={handleSuccessModalClose}>
                                Ok
                            </Button>
                        </Modal.Footer>
                    </Modal>
                    <Modal show={isFailureModal} onHide={handleFailureModalClose} className='SubmitModal'>
                        <Modal.Body>
                            <div className='Details Success'>

                                <div className='imgBox'>
                                    <Image src={require('../../../gsmecom/images/warning.png')} />
                                </div>
                                <h4>Failed</h4>
                            </div>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={handleFailureModalClose}>
                                Ok
                            </Button>
                        </Modal.Footer>
                    </Modal>
                    <Modal show={isledgerMaster} onHide={() => setLedgerMaster(false)} className='PriceHistoryModel'>
                        <Modal.Header closeButton>
                            <h4>Ledger Creation</h4>
                        </Modal.Header>
                        <Modal.Body>
                            <LedgerMaster fromPaymentVoucher paymentVouCallBack={handleLedgerClose} />
                        </Modal.Body>
                    </Modal>
                    <Modal show={isPoSupplierModel} onHide={() => setIsPosSupplierModel(false)} className='PriceHistoryModel' style={{ marginTop: '20%' }}>
                        <Modal.Header closeButton>
                            <Modal.Title>PO Bill Details</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <div className='tableBox'>
                                <table className="table table-striped">
                                    <thead>
                                        <tr>
                                            <th scope="col"></th>
                                            <th scope="col">PO. No</th>
                                            <th scope="col">Po Date</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {removeDuplicates(poSupplierDetail, 'Purchase_Order_No')?.map((poDetail, index) => {
                                            return (
                                                <tr key={index}>
                                                    <td><input type='checkbox' className='' value={`${poDetail?.Purchase_Order_No}`} name='option'
                                                        onChange={(event) => handleFilterItems(event, poDetail?.Supplier_id,)}></input></td>
                                                    <td>{poDetail?.Purchase_Order_No}</td>
                                                    <td>{moment(poDetail?.Purchase_Order_Date).format('DD-MMM-YYYY')}</td>
                                                </tr>
                                            )
                                        })}
                                    </tbody>
                                </table>
                            </div>
                        </Modal.Body>
                    </Modal>
                </div>
            </>


        )
    }
})

export default ItemInWards;