import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import { IStockAnalysis } from './model';
import { Autocomplete, Pagination, Stack, TextField } from '@mui/material';
import useStores from '../../hooks';
import ProgressBar from '../../common/shared/progressbar';
import { observer } from 'mobx-react';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import OutletStockSummaryValidation from '../OutletStockSummary/validation';
import StockAnalysisValidation from './validation';
import { currentPageDatas, sortedData } from '../../common/shared/utils';
import ExcelJS from 'exceljs';
import { saveAs } from 'file-saver';

interface ExcelSheetData {
  ttype?: string;
  outletId?: string;
  vocno?: string;
  vocdate?: string;
  Memberid?: string;
  membername?: string;
  itemid?: string;
  gsmcode?: string;
  itemname?: string;
  Hsncode?: string;
  Category?: string;
  subcategoryname?: string;
  brandname?: string;
  Unitname?: string;
  barcode?: string;
  batchno?: string;
  rackNo?: string;
  BrandType?: string;
  ReceQty?: string;
  Recerate?: string;
  Recevalue?: string;
  issqty?: string;
  issrate?: string;
  Amount?: string;

}

const StockAnalysis = observer((): JSX.Element => {
  const navigate = useNavigate();
  const { commonStore, stockAnalysisStore } = useStores();
  const [stockAnalysis, setStockAnalysis] = useState<IStockAnalysis>(new IStockAnalysis());
  const [isLoading, setLoading] = useState(true);
  const [isItemName, setItemName] = useState(false);
  const [isCategory, setCategory] = useState(false);
  const [isSubCategory, setSubCategory] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [errors, setErrors] = useState<any>({})

  const { loadWarehouse, loadWarehouses, loadSubcategory,
    loadSubcategories, loadItemConversion, loadItemConversions } = commonStore;
  const { getStockAnalysis, stockReportDetails } = stockAnalysisStore


  const filteredData = useMemo(() => {
    if (searchValue != '' && searchValue?.length > 0 && stockReportDetails?.length > 0) {
      return sortedData(stockReportDetails, 'Inwarddate')?.filter((stock) => {
        if (stock?.itemname?.toLowerCase().includes(searchValue?.toLowerCase())) {
          return stock;
        }
      })
    } else {
      return [];
    }
  }, [searchValue])

  const handleSubcategoryValue = (name: any, event: object, val: any) => {
    if (val != null) {
      setStockAnalysis({ ...stockAnalysis, subCategoryId: val.subCategoryId });
    }
  }
  const handleWareHouseValue = (name: any, event: object, val: any) => {
    if (val != null) {
      setStockAnalysis({ ...stockAnalysis, wareHouseId: val.warehouseId });
    }
  }
  const handleItemValue = (name: any, event: object, val: any) => {
    if (val != null) {
      setStockAnalysis({ ...stockAnalysis, itemId: val.itemId });
    }
  }

  const searchStockAnalysis = async (e) => {
    setLoading(true);
    let error: any = {}
    error = StockAnalysisValidation(stockAnalysis)
    if (Object.keys(error).length === 0) {
      const stockAnalysisList = await getStockAnalysis(stockAnalysis);
      setErrors({})
    } else {
      setErrors(error)
    }
    setLoading(false);
  }


  const exportExcelDataDetails = async (excelDataDetails) => {
    console.log(excelDataDetails, 'excelDataDetails>>');

    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet('Results');

    worksheet.pageSetup = {
      fitToPage: true,
      fitToHeight: 1,
      fitToWidth: 1,
      orientation: 'landscape',
      paperSize: 9,
    };

    // Create subtitle strings

    const subtitleStart = `Date From : ${moment(stockAnalysis.fromDate).format('DD-MM-YYYY')} `;
    const subtitleEnd = `  To : ${moment(stockAnalysis.toDate).format('DD-MM-YYYY')}`;
    const subtitleCombined = `Report Generated on: ${moment(new Date().toLocaleDateString()).format('DD-MM-YYYY')}`; // Static string with current date



    worksheet.mergeCells('A1:Y1');
    const titleCell = worksheet.getCell('A1');
    titleCell.value = "GANGA SUPER MARKET";
    titleCell.font = { size: 17, bold: true };
    titleCell.alignment = { horizontal: 'center', vertical: 'middle' };

    worksheet.mergeCells('A2:Y2');
    const subtitleCell = worksheet.getCell('A2');
    subtitleCell.value = "WAREHOUSE STOCK LEDGER " + subtitleStart + subtitleEnd;
    subtitleCell.font = { size: 13, bold: true };
    subtitleCell.alignment = { horizontal: 'center', vertical: 'middle' };


    worksheet.mergeCells('B5:E5');
    const subtitleRCell = worksheet.getCell('B5');
    subtitleRCell.value = subtitleCombined;
    subtitleRCell.font = { size: 13, bold: true };
    subtitleRCell.alignment = { horizontal: 'left', vertical: 'middle' };

    worksheet.addRow([]);

    const addBorders = (cell) => {
      cell.border = {
        top: { style: 'thin' },
        left: { style: 'thin' },
        bottom: { style: 'thin' },
        right: { style: 'thin' },
      };
    };

    const addBordersToMergedCells = (worksheet, startCell, endCell) => {
      const startRow = parseInt(startCell[1]);
      const endRow = parseInt(endCell[1]);
      const startCol = startCell.charCodeAt(0) - 64;
      const endCol = endCell.charCodeAt(0) - 64;

      for (let row = startRow; row <= endRow; row++) {
        for (let col = startCol; col <= endCol; col++) {
          addBorders(worksheet.getCell(row, col));
        }
      }
    };

    //worksheet.mergeCells('B7:C7');
    const SubHeadCell0 = worksheet.getCell('B7');
    SubHeadCell0.value = "SNo";
    SubHeadCell0.font = { bold: true };
    SubHeadCell0.alignment = { horizontal: 'center', vertical: 'middle' };
    addBordersToMergedCells(worksheet, 'B7', 'B8');


    // worksheet.mergeCells('H7:I7');
    const SubHeadCell4 = worksheet.getCell('C7');
    SubHeadCell4.value = "vocdate";
    SubHeadCell4.font = { bold: true };
    SubHeadCell4.alignment = { horizontal: 'center', vertical: 'middle' };
    addBordersToMergedCells(worksheet, 'C7', 'C7');


    //worksheet.mergeCells('L7:M7');
    const SubHeadCell6 = worksheet.getCell('D7');
    SubHeadCell6.value = "membername";
    SubHeadCell6.font = { bold: true };
    SubHeadCell6.alignment = { horizontal: 'center', vertical: 'middle' };
    addBordersToMergedCells(worksheet, 'D7', 'D7');

    //worksheet.mergeCells('N7:O7');
    const SubHeadCell7 = worksheet.getCell('E7');
    SubHeadCell7.value = "itemid";
    SubHeadCell7.font = { bold: true };
    SubHeadCell7.alignment = { horizontal: 'center', vertical: 'middle' };
    addBordersToMergedCells(worksheet, 'E7', 'E7');

    // worksheet.mergeCells('I7:I7');
    const SubHeadCell8 = worksheet.getCell('F7');
    SubHeadCell8.value = "gsmcode";
    SubHeadCell8.font = { bold: true };
    SubHeadCell8.alignment = { horizontal: 'center', vertical: 'middle' };
    addBordersToMergedCells(worksheet, 'F7', 'F7');

    //worksheet.mergeCells('I7:I7');
    const SubHeadCell9 = worksheet.getCell('G7');
    SubHeadCell9.value = "itemname";
    SubHeadCell9.font = { bold: true };
    SubHeadCell9.alignment = { horizontal: 'center', vertical: 'middle' };
    addBordersToMergedCells(worksheet, 'G7', 'G7');

    //worksheet.mergeCells('I7:I7');
    const SubHeadCell10 = worksheet.getCell('H7');
    SubHeadCell10.value = "Hsncode";
    SubHeadCell10.font = { bold: true };
    SubHeadCell10.alignment = { horizontal: 'center', vertical: 'middle' };
    addBordersToMergedCells(worksheet, 'H7', 'H7');

    //worksheet.mergeCells('I7:I7');
    const SubHeadCell11 = worksheet.getCell('I7');
    SubHeadCell11.value = "Category";
    SubHeadCell11.font = { bold: true };
    SubHeadCell11.alignment = { horizontal: 'center', vertical: 'middle' };
    addBordersToMergedCells(worksheet, 'I7', 'I7');

    //worksheet.mergeCells('I7:I7');
    const SubHeadCell12 = worksheet.getCell('J7');
    SubHeadCell12.value = "subcategoryname";
    SubHeadCell12.font = { bold: true };
    SubHeadCell12.alignment = { horizontal: 'center', vertical: 'middle' };
    addBordersToMergedCells(worksheet, 'J7', 'J7');

    //worksheet.mergeCells('I7:I7');
    const SubHeadCell13 = worksheet.getCell('K7');
    SubHeadCell13.value = "brandname";
    SubHeadCell13.font = { bold: true };
    SubHeadCell13.alignment = { horizontal: 'center', vertical: 'middle' };
    addBordersToMergedCells(worksheet, 'K7', 'K7');


    const SubHeadCell15 = worksheet.getCell('L7');
    SubHeadCell15.value = "Barcode";
    SubHeadCell15.font = { bold: true };
    SubHeadCell15.alignment = { horizontal: 'center', vertical: 'middle' };
    addBordersToMergedCells(worksheet, 'L7', 'L7');

    const SubHeadCell16 = worksheet.getCell('M7');
    SubHeadCell16.value = " Batch No";
    SubHeadCell16.font = { bold: true };
    SubHeadCell16.alignment = { horizontal: 'center', vertical: 'middle' };
    addBordersToMergedCells(worksheet, 'M7', 'M7');


    const SubHeadCell171 = worksheet.getCell('N7');
    SubHeadCell171.value = " Voc No";
    SubHeadCell171.font = { bold: true };
    SubHeadCell171.alignment = { horizontal: 'center', vertical: 'middle' };
    addBordersToMergedCells(worksheet, 'N7', 'N7');

    const SubHeadCell18 = worksheet.getCell('O7');
    SubHeadCell18.value = "Voc Type";
    SubHeadCell18.font = { bold: true };
    SubHeadCell18.alignment = { horizontal: 'center', vertical: 'middle' };
    addBordersToMergedCells(worksheet, 'O7', 'O7');

    const SubHeadCell14 = worksheet.getCell('P7');
    SubHeadCell14.value = "Unit Name";
    SubHeadCell14.font = { bold: true };
    SubHeadCell14.alignment = { horizontal: 'center', vertical: 'middle' };
    addBordersToMergedCells(worksheet, 'P7', 'P7');

    const SubHeadCell191 = worksheet.getCell('Q7');
    SubHeadCell191.value = " MRP";
    SubHeadCell191.font = { bold: true };
    SubHeadCell191.alignment = { horizontal: 'center', vertical: 'middle' };
    addBordersToMergedCells(worksheet, 'Q7', 'Q7');

    const SubHeadCell19 = worksheet.getCell('R7');
    SubHeadCell19.value = " ReceQty";
    SubHeadCell19.font = { bold: true };
    SubHeadCell19.alignment = { horizontal: 'center', vertical: 'middle' };
    addBordersToMergedCells(worksheet, 'R7', 'R7');

    const SubHeadCell20 = worksheet.getCell('S7');
    SubHeadCell20.value = "Recerate";
    SubHeadCell20.font = { bold: true };
    SubHeadCell20.alignment = { horizontal: 'center', vertical: 'middle' };
    addBordersToMergedCells(worksheet, 'S7', 'S7');

    const SubHeadCell21 = worksheet.getCell('T7');
    SubHeadCell21.value = " Recevalue";
    SubHeadCell21.font = { bold: true };
    SubHeadCell21.alignment = { horizontal: 'center', vertical: 'middle' };
    addBordersToMergedCells(worksheet, 'T7', 'T7');

    const SubHeadCell22 = worksheet.getCell('U7');
    SubHeadCell22.value = " issqty";
    SubHeadCell22.font = { bold: true };
    SubHeadCell22.alignment = { horizontal: 'center', vertical: 'middle' };
    addBordersToMergedCells(worksheet, 'U7', 'U7');

    const SubHeadCell23 = worksheet.getCell('V7');
    SubHeadCell23.value = " issrate";
    SubHeadCell23.font = { bold: true };
    SubHeadCell23.alignment = { horizontal: 'center', vertical: 'middle' };
    addBordersToMergedCells(worksheet, 'V7', 'V7');

    const SubHeadCell24 = worksheet.getCell('W7');
    SubHeadCell24.value = " Amount";
    SubHeadCell24.font = { bold: true };
    SubHeadCell24.alignment = { horizontal: 'center', vertical: 'middle' };
    addBordersToMergedCells(worksheet, 'W7', 'W7');

    const SubHeadCell25 = worksheet.getCell('X7');
    SubHeadCell25.value = " Closing Qty";
    SubHeadCell25.font = { bold: true };
    SubHeadCell25.alignment = { horizontal: 'center', vertical: 'middle' };
    addBordersToMergedCells(worksheet, 'X7', 'X7');



    const testColumnIndex = 5;
    const weightColumnIndex = 6;
    const widthInInches = 3;
    const widthInChars = widthInInches * 5.1;

    const Rsno = 1;
    let Ritemid: any;
    let rowIndex: any;
    rowIndex = 7;

    excelDataDetails.forEach((datadet, index) => {

      if (Rsno === 1) {
        Ritemid = datadet.itemid;
        const Rsno = 2;
      }

      rowIndex = rowIndex + 1;

      const seqNoCell = worksheet.getCell(rowIndex, 2);
      seqNoCell.value = index + 1;
      seqNoCell.alignment = { horizontal: 'right', vertical: 'middle' };
      addBorders(seqNoCell);

      const BillnoNumberCell = worksheet.getCell(rowIndex, 3);
      BillnoNumberCell.value = datadet.vocdate;
      BillnoNumberCell.alignment = { horizontal: 'center', vertical: 'middle' };
      worksheet.getColumn(3).width = 15;
      addBorders(BillnoNumberCell);


      const BilltypeNumberCell = worksheet.getCell(rowIndex, 4);
      BilltypeNumberCell.value = datadet.membername;
      BilltypeNumberCell.alignment = { horizontal: 'center', vertical: 'middle' };
      worksheet.getColumn(4).width = 15;
      addBorders(BilltypeNumberCell);

      const Customer_TypeCell = worksheet.getCell(rowIndex, 5);
      Customer_TypeCell.value = datadet.itemid;
      Customer_TypeCell.alignment = { horizontal: 'center', vertical: 'middle' };
      worksheet.getColumn(5).width = 15;
      addBorders(Customer_TypeCell);

      const midCell = worksheet.getCell(rowIndex, 6);
      midCell.value = datadet.gsmcode;
      midCell.alignment = { horizontal: 'right', vertical: 'middle' };
      worksheet.getColumn(6).width = 12;
      addBorders(midCell);

      const membernameCell = worksheet.getCell(rowIndex, 7);
      membernameCell.value = datadet.itemname;
      membernameCell.alignment = { horizontal: 'left', vertical: 'middle' };
      worksheet.getColumn(7).width = 60;
      addBorders(membernameCell);

      const MobilenoCell = worksheet.getCell(rowIndex, 8);
      MobilenoCell.value = datadet.Hsncode;
      MobilenoCell.alignment = { horizontal: 'center', vertical: 'middle' };
      worksheet.getColumn(8).width = 15;
      addBorders(MobilenoCell);

      const itemidCell = worksheet.getCell(rowIndex, 9);
      itemidCell.value = datadet.Category;
      itemidCell.alignment = { horizontal: 'center', vertical: 'middle' };
      worksheet.getColumn(9).width = 30;
      addBorders(itemidCell);

      const itemnameCell = worksheet.getCell(rowIndex, 10);
      itemnameCell.value = datadet.subcategoryname;
      itemnameCell.alignment = { horizontal: 'left', vertical: 'middle' };
      worksheet.getColumn(10).width = 30;
      addBorders(itemnameCell);

      const CategoryCell = worksheet.getCell(rowIndex, 11);
      CategoryCell.value = datadet.brandname;
      CategoryCell.alignment = { horizontal: 'left', vertical: 'middle' };
      worksheet.getColumn(11).width = 20;
      addBorders(CategoryCell);


      const BarcodeCell = worksheet.getCell(rowIndex, 12);
      BarcodeCell.value = datadet.barcode;
      BarcodeCell.alignment = { horizontal: 'center', vertical: 'middle' };
      worksheet.getColumn(12).width = 20;
      addBorders(BarcodeCell);

      const batch_noCell = worksheet.getCell(rowIndex, 13);
      batch_noCell.value = datadet.batchno;
      batch_noCell.alignment = { horizontal: 'center', vertical: 'middle' };
      worksheet.getColumn(13).width = 20;
      addBorders(batch_noCell);


      const OutletnameNumberCell = worksheet.getCell(rowIndex, 14);
      OutletnameNumberCell.value = datadet.vocno;
      OutletnameNumberCell.alignment = { horizontal: 'left', vertical: 'middle' };
      worksheet.getColumn(15).width = 14;
      addBorders(OutletnameNumberCell);

      const QtyCell = worksheet.getCell(rowIndex, 15);
      QtyCell.value = datadet.ttype;
      QtyCell.alignment = { horizontal: 'left', vertical: 'middle' };
      worksheet.getColumn(15).width = 20;
      addBorders(QtyCell);

      const HSNCodeCell = worksheet.getCell(rowIndex, 16);
      HSNCodeCell.value = datadet.Unitname;
      HSNCodeCell.alignment = { horizontal: 'left', vertical: 'middle' };
      worksheet.getColumn(16).width = 15;
      addBorders(HSNCodeCell);

      const UnitnameCell1 = worksheet.getCell(rowIndex, 17);
      UnitnameCell1.value = datadet.Mrprate;
      UnitnameCell1.alignment = { horizontal: 'center', vertical: 'middle' };
      worksheet.getColumn(17).width = 15;
      addBorders(UnitnameCell1);

      const UnitnameCell = worksheet.getCell(rowIndex, 18);
      UnitnameCell.value = datadet.ReceQty;
      UnitnameCell.alignment = { horizontal: 'center', vertical: 'middle' };
      worksheet.getColumn(18).width = 15;
      addBorders(UnitnameCell);

      const freeqtyCell = worksheet.getCell(rowIndex, 19);
      freeqtyCell.value = datadet.Recerate;
      freeqtyCell.alignment = { horizontal: 'right', vertical: 'middle' };
      worksheet.getColumn(19).width = 10;
      addBorders(freeqtyCell);

      const rateCell = worksheet.getCell(rowIndex, 20);
      rateCell.value = datadet.Recevalue;
      rateCell.alignment = { horizontal: 'right', vertical: 'middle' };
      worksheet.getColumn(20).width = 10;
      addBorders(rateCell);


      const DiscountPerCell = worksheet.getCell(rowIndex, 21);
      DiscountPerCell.value = datadet.issqty;
      DiscountPerCell.alignment = { horizontal: 'right', vertical: 'middle' };
      worksheet.getColumn(21).width = 15;
      addBorders(DiscountPerCell);

      const DiscountvalueCell = worksheet.getCell(rowIndex, 22);
      DiscountvalueCell.value = datadet.issrate;
      DiscountvalueCell.alignment = { horizontal: 'right', vertical: 'middle' };
      worksheet.getColumn(22).width = 15;
      addBorders(DiscountvalueCell);


      const CGSTPCell = worksheet.getCell(rowIndex, 23);
      CGSTPCell.value = datadet.Amount;
      CGSTPCell.alignment = { horizontal: 'right', vertical: 'middle' };
      worksheet.getColumn(23).width = 15;
      addBorders(CGSTPCell);

      const CGSTPCell1 = worksheet.getCell(rowIndex, 24);
      CGSTPCell1.value = datadet.ClosingQty;
      CGSTPCell1.alignment = { horizontal: 'right', vertical: 'middle' };
      worksheet.getColumn(24).width = 15;
      addBorders(CGSTPCell1);

      const lastRow = worksheet.lastRow?.number;

      console.log(Ritemid, datadet.itemid, 'Ritemid');
      if (Ritemid === datadet.itemid) {
      } else {
        console.log(Ritemid, datadet.itemid, 'Ritemid');

        rowIndex = rowIndex + 2;
        Ritemid = datadet.itemid;


      }

      // const sumamount1 = `SUM(V7:V${lastRow})`
      // const sumdiscount2 = `SUM(X7:X${lastRow})`
      // const sumcgst3 = `SUM(Z7:Z${lastRow})`
      // const sumsgst4 = `SUM(AB7:AB${lastRow})`
      // const sumtotal5 = `SUM(AE7:AE${lastRow})`
      // const suminvoice6 = `SUM(AF7:AF${lastRow})`
      // const sumcash7 = `SUM(AG7:AG${lastRow})`
      // const sumreadm8 = `SUM(AH7:AH${lastRow})`
      // const sumreturn9 = `SUM(AI7:AI${lastRow})`
      // const sumcard10 = `SUM(AJ7:AJ${lastRow})`
      // const sumqrcode11 = `SUM(AK7:AK${lastRow})`
      // const sumonline12 = `SUM(AL7:AL${lastRow})`
      // const sumbanktr13 = `SUM(AM7:AM${lastRow})`

      //  worksheet.getCell(`Q${Number(lastRow) + 1}`).value = 'Total';

      //   worksheet.getCell(`V${Number(lastRow) + 1}`).value = { formula: sumcash7 };
      //   worksheet.getCell(`X${Number(lastRow) + 1}`).value = { formula: sumdiscount2 };
      //   worksheet.getCell(`Z${Number(lastRow) + 1}`).value = { formula: sumcgst3 };
      //   worksheet.getCell(`AB${Number(lastRow) + 1}`).value = { formula: sumsgst4 };
      //   worksheet.getCell(`AE${Number(lastRow) + 1}`).value = { formula: sumtotal5 };
      //   worksheet.getCell(`AF${Number(lastRow) + 1}`).value = { formula: suminvoice6 };
      //   worksheet.getCell(`AG${Number(lastRow) + 1}`).value = { formula: sumcash7 };
      //   worksheet.getCell(`AH${Number(lastRow) + 1}`).value = { formula: sumreadm8 };
      //   worksheet.getCell(`AI${Number(lastRow) + 1}`).value = { formula: sumreturn9 };
      //   worksheet.getCell(`AJ${Number(lastRow) + 1}`).value = { formula: sumcard10 };
      //   worksheet.getCell(`AK${Number(lastRow) + 1}`).value = { formula: sumqrcode11 };
      //   worksheet.getCell(`AL${Number(lastRow) + 1}`).value = { formula: sumonline12 };
      //   worksheet.getCell(`AM${Number(lastRow) + 1}`).value = { formula: sumbanktr13 };

      // let Ritemid =   datadet.itemid;   


    });

    const buffer = await workbook.xlsx.writeBuffer();
    const blob = new Blob([buffer], { type: 'application/octet-stream' });
    saveAs(blob, `Warehouse_Ledger${new Date().toISOString().split('T')[0]}.xlsx`);

  }

  async function createExportExcelObj() {
    const excelDataDetails = await getStockAnalysis(stockAnalysis);
    console.log(excelDataDetails, 'excelDataDetails>>');

    exportExcelDataDetails(excelDataDetails)

  }


  function exportToExcel(excelArr: ExcelSheetData[]) {
    const csvContent = ["Warehouse Stock Report", Object.keys(excelArr[0]).join(','), ...excelArr.map(obj => Object.values(obj).join(','))].join('\n');

    const blob = new Blob([csvContent], { type: "data:text/csv;charset=utf-8;" });

    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    const today = moment();
    link.download = `Warehouse Stock Report ${today.format('DD-MM-YYYY')}.csv`;

    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  const handleInputchnage = (e) => {
    const { name, value } = e.target;
    setStockAnalysis({ ...stockAnalysis, [name]: value });
  };

  function handleChangeInput(event: any) {
    const { name, value } = event.target;
    if (name === 'offerType' && value === '') {
      setItemName(false);
      setCategory(false);
      setSubCategory(false);
    }
    if (value === 'ItemName') {
      setItemName(true);
      setCategory(false);
      setSubCategory(false);
    }
    if (value === 'Category') {
      setItemName(false);
      setCategory(true);
      setSubCategory(false);
    }
    if (value === 'SubCategory') {
      setItemName(false);
      setCategory(false);
      setSubCategory(true);
    }
    setStockAnalysis({ ...stockAnalysis, [name]: value })
  }

  function handleSearchInput(e) {
    setSearchValue(e.target.value);
  }

  async function fetchPreLoadngData() {
    await loadItemConversion();
    await loadWarehouse();
    await loadSubcategory();
    setLoading(false);
  }

  const isCurrent = useRef(true)
  useEffect(() => {
    if (isCurrent.current) {
      fetchPreLoadngData();
      isCurrent.current = false
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const [totalPages, setTotalPage] = useState(1);
  const [currentPageData, setCurrentPageData] = useState<any[]>([])


  console.log(currentPageData, 'currentPageData>>')
  useEffect(() => {
    if (stockReportDetails?.length) {
      goToPage(1)
    } else {
      setCurrentPageData(stockReportDetails)
    }
  }, [stockReportDetails])

  const goToPage = (value: number) => {
    const currentPageList = currentPageDatas(sortedData(stockReportDetails, 'Inwarddate'), value, 10)
    setTotalPage(currentPageList?.totalPages)
    setCurrentPageData(currentPageList?.currentPageData)
  };

  return (
    <div className='' style={{ display: 'flex', alignItems: "center", justifyContent: 'center', width: '100%' }}>
      <div className="" style={{ width: '100%' }}>
        <div className='outletInputField inputFormBox LgInputField'>
          <div className='hrBox'>
            <h3>Warehouse Stock Report</h3>
          </div>
          {isLoading ? <ProgressBar /> : <></>}
          <div className='inputBoxLists'>
            <div className='ItemInwardInputBox'>
              <div className='row'>
                <div className='col-sm-1'>
                  <div className='inputBox'>
                    <label style={{ fontSize: "small" }}>From Date <span>*</span></label>
                    <input type="date" style={{ width: "100%" }}
                      onChange={handleInputchnage}
                      name="fromDate"
                      value={stockAnalysis?.fromDate}
                      placeholder="DD-MMM-YYYY" data-date="" data-date-format="DD MM YYYY"></input>
                  </div>
                  {errors.fromDate && <p style={{ color: 'red' }}>{errors.fromDate}</p>}
                  <div className='vertical-space-10'></div>
                </div>
                <div className='col-sm-1'>
                  <div className='inputBox'>
                    <label style={{ fontSize: "small" }}>To Date <span>*</span></label>
                    <input type="date" style={{ width: "100%" }}
                      onChange={handleInputchnage}
                      name="toDate"
                      value={stockAnalysis?.toDate}
                      placeholder="DD-MMM-YYYY" data-date="" data-date-format="DD MM YYYY"></input>
                  </div>
                  {errors.toDate && <p style={{ color: 'red' }}>{errors.toDate}</p>}
                  <div className='vertical-space-20'></div>
                </div>
                <div className="col-sm-2" style={{ marginTop: '21px' }}>
                  <div className='inputBox'>
                    <Autocomplete size="small"
                      disablePortal
                      id="combo-box-demo"
                      options={loadWarehouses}
                      getOptionLabel={(option: any) => option.warehouseName}
                      onChange={(event, val) => handleWareHouseValue('wareHouseId', event, val)}
                      renderInput={(params: any) =>
                        <TextField  {...params}
                          id="outlined-size-small"
                          color='info'
                          size="small"
                          type="text"
                          placeholder='Select WareHouse Name..'
                          name='warehouseName'
                        />}
                    />

                  </div>
                  {errors.outletId && <p style={{ color: 'red' }}>Select Warehouse</p>}
                </div>
                <div className="col-sm-2" style={{ marginTop: '18px' }}>
                  <div className="input-group mb-3">
                    <input type="text"
                      style={{ height: "47px" }}
                      name='searchValue'
                      value={searchValue}
                      onChange={handleSearchInput}
                      className="form-control" placeholder="Search Here.."
                      aria-label="Search Outlet..."
                      aria-describedby="search-button"
                    ></input>
                  </div>
                </div>
                <div className='col-sm-2' style={{ marginTop: '4px' }}>
                  <div className='inputBox' style={{ marginTop: '17px' }}>
                    <select style={{ height: "47px" }} className="form-selected" id="Mode" name="searchfield" onChange={handleChangeInput} >
                      <option value="">Select Search Criteria</option>
                      <option value="ItemName">Item Name</option>
                      <option value="Category">CateGory </option>
                      <option value="SubCategory">Sub Category </option>
                    </select>
                  </div>

                </div>
                <div className='col-sm-2' style={{ marginTop: '20px', }}>
                  {
                    isItemName ?
                      <>
                        <div className='inputBox'>
                          <Autocomplete size="small"
                            disablePortal
                            id="combo-box-demo"
                            options={loadItemConversions}
                            getOptionLabel={(option: any) => option.itemName}
                            onChange={(event, val) => handleItemValue('itemId', event, val)}
                            renderInput={(params: any) =>
                              <TextField  {...params}
                                id="outlined-size-small"
                                color='info'
                                size="small"
                                type="text"
                                placeholder='Select Item..'
                                name='itemId'
                              />}
                          />
                        </div>
                        <div className='vertical-space-10'></div>
                      </> : null

                  }
                  {
                    isCategory ?
                      <>
                        <div className='inputBox'>
                          <select style={{ height: "47px" }} className="form-selected" onChange={handleInputchnage} id="Category" name="category">
                            <option value="" selected>Not Select</option>
                            <option value="Home&amp;Kitchen">Home &amp; Kitchen</option>
                            <option value="Pets">Pets</option>
                            <option value="Beauty&amp;Health">Beauty &amp; Health</option>
                            <option value="Grocery">Grocery</option>
                          </select>
                        </div>
                        <div className='vertical-space-10'></div>
                      </> : null
                  }
                  {
                    isSubCategory ?
                      <>
                        <div className='inputBox'>
                          <Autocomplete size="small"
                            disablePortal
                            id="combo-box-demo"
                            options={loadSubcategories}
                            getOptionLabel={(option: any) => option.subCategoryName}
                            onChange={(event, val) => handleSubcategoryValue('subCategoryId', event, val)}
                            renderInput={(params: any) =>
                              <TextField  {...params}
                                id="outlined-size-small"
                                color='info'
                                size="small"
                                type="text"
                                placeholder='Select Subcategory..'
                                name='subcategory'
                              />}
                          />

                        </div>
                        <div className='vertical-space-10'></div>
                      </> : null
                  }
                </div>
                <div className="col-sm-1" style={{ marginTop: '24px' }}>
                  <button className='secondaryBtn' type='submit' onClick={searchStockAnalysis}>Generate</button>
                </div>
                <div className="col-sm-1" style={{ marginTop: '24px' }}>
                  <button className='secondaryBtn' type='submit' onClick={() => createExportExcelObj()}>Excel</button>
                </div>
              </div>
            </div>

            <div className='row'>
              <div className='col-sm-5'>
                <div className='btnBox'>
                  <button className='totalrecordBtn' type='submit' style={{ width: '20ch' }}>{(searchValue?.length > 0 ? filteredData : currentPageData)?.length} Records</button>
                </div>
              </div>
              <div className=' col-sm-7' >
                <Stack spacing={2}>
                  <Pagination count={totalPages} onChange={(ev, value) => goToPage(value)} color='primary' />
                </Stack>
              </div>
            </div>

            <div className='tableListDetails'>
              <table className="table table-striped">
                <thead>
                  <tr>
                    <th scope="col">SNo</th>
                    <th scope="col">Voucher Date</th>
                    <th scope="col">Member Name</th>
                    <th scope="col">Item Id</th>
                    <th scope="col">Gsmcode</th>
                    <th scope="col">Item Name</th>
                    <th scope="col">Hsncode</th>
                    <th scope="col">Category</th>
                    <th scope="col">Sub Category Name</th>
                    <th scope="col">Brand Name</th>
                    <th scope="col">Barcode</th>
                    <th scope='col'>Batch No</th>
                    <th scope='col'>Voc No</th>
                    <th scope='col'>Voc Type</th>
                    <th scope="col">Unit Name</th>
                    <th scope="col">MRP</th>
                    <th scope="col">Opening Qty</th>
                    <th scope="col">Received Qty</th>
                    <th scope="col">Receive Value</th>
                    <th scope="col">Issued Qty</th>
                    <th scope="col">Issued Value</th>
                    <th scope="col">Closing Qty</th>
                  </tr>
                </thead>
                <tbody>

                  {(searchValue?.length > 0 ? filteredData : currentPageData)?.length > 0 ?
                    (searchValue?.length > 0 ? filteredData : currentPageData)?.map((val, key) => {
                      return (
                        <tr key={key}>
                          <td>{val.id} </td>
                          <td>{moment(val.vocdate).format('DD-MMM-YYYY')}</td>
                          <td>{val.membername} </td>
                          <td>{val.itemid} </td>
                          <td>{val.gsmcode} </td>
                          <td>{val.itemname} </td>
                          <td>{val.Hsncode} </td>
                          <td>{val.Category} </td>
                          <td>{val.subcategoryname} </td>
                          <td>{val.brandname} </td>
                          <td>{val.barcode}</td>
                          <td>{val.batchno}</td>
                          <td>{val.vocno}</td>
                          <td>{val.ttype}</td>
                          <td>{val.Unitname} </td>
                          <td>{val.Mrprate} </td>
                          <td>{val.OpenQty}</td>
                          <td>{val.ReceQty}</td>
                          <td>{val.Recevalue} </td>
                          <td>{val.issqty} </td>
                          <td>{val.Amount}</td>
                          <td>{val.ClosingQty}</td>
                        </tr>
                      )
                    }) : <h6> No Data Available</h6>
                  }
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
});

export default StockAnalysis;
