import React, { useState, useEffect, ChangeEvent, useRef } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import { observer } from 'mobx-react-lite';
import { ICombopackmaster, ICombopackdetails } from './model';
import useStores from '../../hooks';
import { Autocomplete, TextField } from '@mui/material';
import itemConversionValidation from './validation';
import { Modal, Spinner, Image, Button } from 'react-bootstrap';
import AutoComplete from '../../common/shared/autoComplete';
import _ from 'lodash';
import { useParams } from 'react-router-dom';
import Cookies from 'js-cookie';
import CombopackStore from './store';
import { removeDuplicates } from '../../common/shared/utils';

const ComboPack = observer((): JSX.Element => {
  const { supplierMasterStore, commonStore, itemConversionStore, comboPackMasterStore, inwardStore } = useStores();
  // const { getItemConversion } = CombopackStore;
  const [combopackmaster, setcombopackmaster] = useState<ICombopackmaster>({});
  const [combopackdetails, setcombopackdetails] = useState<ICombopackdetails>({});
  const [combopackdetailss, setcombopackdetailss] = useState<ICombopackdetails>({});
  const [submitArr, setSubmitArr] = useState<any[]>([]);
  const navigate = useNavigate();
  const [submitBtn, setSubmitbtn] = useState(false);
  const [errors, setErrors] = useState<any>({});
  const [isLoading, setLoading] = useState(true);
  const [isSuccessModal, setSuccessModal] = useState(false);
  const [isFailureModal, setFailureModal] = useState(false);
  const [isAccessModal, setAccessModal] = useState(false);
  const { itemconvertId } = useParams<{ itemconvertId: string }>();
  const [isLoadModal, setLoadModal] = useState(false);
  const { loadUnitOfMeasure, loadUnitOfMeasures, } = commonStore;

  const [itemMasterJSON, setItemMasterJSON] = useState<any[]>([])

  console.log(itemMasterJSON, "dsdjosonvalue");
  const [canAdd, setCanAdd] = useState(false);
  const [canEdit, setCanEdit] = useState(false);
  const [canView, setCanView] = useState(false);

  const { comboId } = useParams();
  console.log(comboId, 'fffdcomboId')

  const handleItemValue = (name: any, val: any) => {
    console.log(name, val, 'nameandvalue');
    if (val != null) {
      setcombopackmaster({ ...combopackmaster, [name]: val.itemid, ["itemName"]: val.itemname, ["itemUOM"]: val?.uom });
    }
    else {
      combopackmaster.itemName = '';
      combopackmaster.itemUOM = '';
    }
  }

  const handleConItemValue = (name: any, val: any) => {

    if (val != null) {
      setcombopackdetails({ ...combopackdetails, [name]: val.itemid, ["itemid"]: val.itemid, ["itemNamedtl"]: val.itemname, ["itemUOMdtl"]: val.uom });
    }
    else {
      combopackdetails.itemNamedtl = '';
      combopackdetails.itemUOMdtl = '';
    }
  }

  // function hanldeItemConversion(event: any) {
  //   const { name, value } = event.target;
  //   setItemOfConversion({ ...itemOfConversion, [name]: value })
  // }

  // const handleUnitOfMeasureValue = (name: any, event: object, val: any) => {
  //   if (val != null) {
  //     setItemOfConversion({ ...itemOfConversion, [name]: val.unitId, ["conversionUOM"]: val.unitName });
  //   }
  //   else {
  //     itemOfConversion.conversionUOM = '';
  //   }
  // }

  function addItemConversion() {
    let error: any = {};
    // error = itemConversionValidation(itemOfConversion, itemConversion);
    setErrors(error);
    if (Object.keys(error).length === 0) {
      setSubmitArr([...submitArr, combopackdetails]);
      setcombopackdetails({ ...combopackdetails, itemNamedtl: '', itemUOMdtl: '', itemid: 0, qtydtl: 0 })
      setSubmitbtn(true);
    }
  }

  function handleRemove(index) {
    submitArr?.splice(index, 1)
    setSubmitArr([...submitArr])
  }
   


  async function fetchPreLoadngData() {
    setLoading(false)
    const [itemMasterJSON] = await Promise.all([inwardStore.loadInwardItemMasterJSON(), loadUnitOfMeasure()]);
    setItemMasterJSON([...itemMasterJSON])
  }

  async function getComboPackMasterDet() {
    if (comboId) {
      const data = await comboPackMasterStore?.getComboPackMasterList(1, comboId);
      console.log(data, "datavaluee");
      const formattedData = Array.from(data);
      // const arrangedData = JSON.stringify(formattedData, null, 2)
      // console.log(arrangedData, "fffffffff");
      // console.log(JSON.stringify(formattedData, null, 2), 'combopackdata');
      const filterData = formattedData?.filter((item: any) => Number(item?.ComboId) === Number(comboId));
      console.log(filterData, "dddfdfdfdata");
      const dataDet = await comboPackMasterStore?.getComboPackMasterDetailsList(2, comboId);
      console.log(dataDet, 'dataDet');

      filterData?.map((val: any) => {
        console.log(val, "ddddvalueaaa");
        setcombopackmaster({ ...combopackmaster,["comboId"]: Number(comboId), ["mainItemId"]: val.MainItemId, ["itemName"]: val.itemname, ["itemUOM"]: val?.Unitname, ["qty"]: val?.Qty });
      })

      dataDet?.map((val: any) => {
        console.log(val, "setttrtval");
        // setcombopackdetails({ ...combopackdetails, ["itemid"]: val?.itemid, ["itemNamedtl"]: val?.itemname, ["itemUOMdtl"]: val.Unitname, ['qtydtl']:val.Qty});
        console.log(combopackdetails, "combopackdetails--->");
        setSubmitArr(dataDet);
      })

      // const updatedData = dataDet.map((item: any) => ({
      //   itemConversionId: item.itemConversionId,
      //   conversionItemId: item.conversionItemId,
      //   itemName: item.conversionItemName,
      //   conversionUOM: item.conversionUOM,
      //   conversionQty: item.conversionQty,
      //   conversionItemUOM: item.conversionItemUOM,
      //   unitName: item.conversionUOM,
      //   itemconversiondtlid: item.itemConversionDetailId
      // }));

      // setItemOfConversions(updatedData);
      // console.log(updatedData, 'updatedData');

      // const matchedUser = formattedData.find(team => Number(team.itemConversionId) === Number(itemconvertId));
      // if (matchedUser) {
      //   setItemConversion(matchedUser);
      // }
    }
  }

  // useEffect(() => {
  //   console.log(itemConversion, 'itemConversion');
  // }, [itemConversion]);

  const isCurrentPage = useRef(true)

  useEffect(() => {
    if (isCurrentPage.current) {
      fetchPreLoadngData();
      getComboPackMasterDet();
      isCurrentPage.current = false;
    }
    return () => { }
  }, []);

  const handleCreate = () => {
    navigate('/Admin/Combopackdetails');
  }

  const handleFailureModalClose = () => setFailureModal(false);

  const handleSuccessModalClose = () => {
    navigate('/Admin/Combopackdetails');
  }

  const handleAccessModalClose = () => {
    navigate('/Admin/Home');
  }

  const isCurrenPage = useRef(true)
  const empid = Number(Cookies.get('userId'));
  const formName = 'Item_conversion';

  useEffect(() => {
    const fetchRights = async () => {
      try {
        const data = await supplierMasterStore.fetchRights(empid, formName);
        if (Array.isArray(data) && data.length > 0) {
          const specificFieldValue = data[0].sts;
          const rightsValue = data[0].rights;
          const lettersArray = rightsValue.split('');
          if (specificFieldValue === 1) {
            if (isCurrenPage.current) {
              fetchPreLoadngData();
              isCurrenPage.current = false
              if (lettersArray.includes('a')) {
                setCanAdd(true);
              } else {
                navigate('/Admin/ComboPack/CombopackDetails');
              }
              if (lettersArray.includes('e')) {
                setCanEdit(true);
              }
              // if (lettersArray.includes('v')) {
              //   setCanView(true); 
              // }     
              // if (lettersArray.includes('p')) {
              //   setCanPrint(true); 
              // }       
              // if (lettersArray.includes('u')) {
              //   setCanUpdate(true); 
              // }                     
            }
          } else if (specificFieldValue === 0) {
            setLoadModal(true)
          }
        }

      } catch (error) {
        console.error("Error fetching rights:", error);
      }
    };
    fetchRights();
  }, [empid, formName]);


  async function handleSubmit() {
    console.log(submitArr, "dddddahgfjsgdf");

    combopackmaster.userid = empid
    combopackmaster.combopackdetails = submitArr;

    if (submitArr.length >= 1) {
      const status = await comboPackMasterStore.savecombopackMaster(combopackmaster);
      if (status === "Success") {
        setSuccessModal(true);
        setLoading(false)
      } else {
        setFailureModal(true)
        setLoading(false)
      }
    }
  }


  if (isLoadModal) {
    return (
      <div className="container">
        {isLoadModal && <h1>Access Denied</h1>}
        <Modal show={isLoadModal} onHide={handleAccessModalClose} className='SubmitModal'>
          <Modal.Body>
            <div className='Details Success'>
              <div className='imgBox'>
                <Image src={require('../../../gsmecom/images/warning.png')} />
              </div>
              <h4>Access Denied</h4>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleAccessModalClose}>Ok</Button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
  else {

    return (
      <>
        {
          // isLoading ?
          //   <div className='SpinnerBox'>
          //     <Spinner animation="border" role="status">
          //       <span className="visually-hidden">Loading...</span>
          //     </Spinner>
          //   </div> :
          <>
            <div className='container'>
              <div className='vertical-space-20'></div>
              <div className='outletInputField inputFormBox'>
                <div className='hrBox'>
                  <h3>Combo Pack Items</h3>
                </div>
                <div className='inputBoxLists pd-b-0'>
                  <div className='LgInputListsBox mr-left-0'>
                    <div className='row'>

                      <div className='col-md-12'>
                        <div className='inputBox'>
                          <label>Item Name <span>*</span></label>
                          <AutoComplete
                            options={itemMasterJSON}
                            value={combopackmaster?.itemName}
                            // options={removeDuplicates(itemMasterJSON, 'itemName')}
                            // emitOption={(option) => setcombopackdetails({ ...combopackdetails, supplier_ledger_accout_id: option?.Supid })}
                            emitOption={(val) => handleItemValue('mainItemId', val)}
                            placeholder={'Select Item..'}
                            clsName='full-width'
                            getOptionLabel='itemname'
                          />
                          {errors.itemId && <p style={{ color: 'red' }}>{errors.itemId}</p>}
                        </div>
                        <div className='vertical-space-10'></div>
                      </div>
                      <div className='col-md-5'>
                        <div className='inputBox'>
                          <label> UOM<span>*</span> </label>
                          <input
                            // onChange={handleInputchnage}
                            value={combopackmaster?.itemUOM}
                            name='itemUOM'
                            className='fullInput' style={{ width: "100%" }} placeholder='UOM..'></input>
                          {errors.itemUOM && <p style={{ color: 'red' }}>{errors.itemUOM}</p>}
                        </div>
                        <div className='vertical-space-10'></div>
                      </div>
                      <div className='col-md-4'>
                        <div className='inputBox'>
                          <label> Qty<span>*</span></label>
                          <input
                            type="number"
                            onChange={(e) => setcombopackmaster({ ...combopackmaster, 'qty': Number(e?.target?.value) || 0 })}
                            value={combopackmaster?.qty}
                            name='Qty'
                            className='' style={{ width: "100%" }} placeholder='Qty..'></input>
                          {errors.conversionQty && <p style={{ color: 'red' }}>{errors.conversionQty}</p>}
                        </div>
                        <div className='vertical-space-10'></div>
                      </div>

                      <div className='col-md-12'>
                        <div className='inputBox'>
                          <label>ComboPack Items<span>*</span></label>
                          <AutoComplete
                            options={itemMasterJSON}
                            value={combopackdetails?.itemNamedtl}
                            // onChange={handleInputchnage}
                            emitOption={(val) => handleConItemValue('itemId', val)}
                            placeholder={'Select Item..'}
                            clsName='full-width'
                            getOptionLabel='itemname'
                          />
                          {errors.itemid && <p style={{ color: 'red' }}>{errors.itemId}</p>}
                        </div>
                        <div className='vertical-space-10'></div>
                      </div>
                      <div className='col-md-5'>
                        <div className='inputBox'>
                          <label>UOM<span>*</span> </label>
                          <input
                            type="text"
                            name='conversionQty'
                            value={combopackdetails.itemUOMdtl}
                            // onChange={handleInputchnage}
                            className='' style={{ width: "100%" }} placeholder='UOM..'></input>
                        </div>
                        <div className='vertical-space-10'></div>
                      </div>
                      <div className='col-md-4'>
                        <div className='inputBox'>
                          <label>ComboPack Qty<span>*</span></label>
                          <input
                           type="number"
                            value={combopackdetails.qtydtl}
                            name='Qtydtl'
                            // onChange={handleInputchnage}
                            onChange={(e) => setcombopackdetails({ ...combopackdetails, 'qtydtl': Number(e?.target?.value) || 0 })}
                            className='' style={{ width: "100%" }} placeholder=' Qty..'></input>
                          {errors.conversionQty && <p style={{ color: 'red' }}>{errors.conversionQty}</p>}
                        </div>
                        <div className='vertical-space-10'></div>
                      </div>

                      <div className='col-sm-2'>
                        <button className='dfBtn' onClick={addItemConversion}>Add</button>
                      </div>
                      <div className='col-md-12'>
                        <div className='tableBox' style={{ maxHeight: '300px', overflow: 'scroll' }}>
                          <table className="table table-striped">
                            <thead>
                              <tr>
                                <th scope="col">Item Name</th>
                                <th scope="col">Conversion Qty </th>
                                <th scope="col">UOM</th>
                                {/* <th scope="col">Item Con. UOM</th> */}
                                <th scope="col"></th>
                              </tr>
                            </thead>
                            <tbody>
                              {submitArr?.map((val, key) => {
                                return (
                                  <tr key={key}>
                                    <td>{val?.itemNamedtl}</td>
                                    <td>{val?.qtydtl ? Number(val?.qtydtl).toFixed(6) : '0.000000'}</td>
                                    <td>{val?.itemUOMdtl}</td>
                                    <td><button style={{ border: '2px solid #dc3545', background: '#dc3545', color: 'white', fontWeight: '600', borderRadius: '6px' }}
                                      type='submit' onClick={() => handleRemove(key)}>Remove</button></td>
                                  </tr>
                                )
                              })
                              }
                            </tbody>
                          </table>
                        </div>
                        <div className='vertical-space-10'></div>
                      </div>

                    </div>
                  </div>
                  <div className='vertical-space-10'></div>
                </div>
                <div className='btnBox'>
                  <button className='secondaryBtn' onClick={handleCreate}>List</button>
                  <button className='dfBtn' type='submit' onClick={handleSubmit}>Submit</button>
                </div>
              </div>
              <Modal show={isSuccessModal} onHide={handleSuccessModalClose} className='SubmitModal'>
                <Modal.Body>
                  <div className='Details Success'>
                    <div className='imgBox'>
                      <Image src={require('../../../gsmecom/images/checked.png')} />
                    </div>

                    <h4>Succesfully Submitted</h4>
                  </div>
                </Modal.Body>
                <Modal.Footer>
                  <Button variant="secondary" onClick={handleSuccessModalClose}>
                    Ok
                  </Button>
                </Modal.Footer>
              </Modal>
              <Modal show={isFailureModal} onHide={handleFailureModalClose} className='SubmitModal'>
                <Modal.Body>
                  <div className='Details Success'>

                    <div className='imgBox'>
                      <Image src={require('../../../gsmecom/images/warning.png')} />
                    </div>
                    <h4>Failed</h4>
                  </div>
                </Modal.Body>
                <Modal.Footer>
                  <Button variant="secondary" onClick={handleFailureModalClose}>
                    Ok
                  </Button>
                </Modal.Footer>
              </Modal>
            </div>
          </>
        }
      </>
    );
  }

});

export default ComboPack;