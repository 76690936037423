import { observable } from "mobx";
export class IPackingDistribution {
    packingDistributionId?: number = 0;
    packingDistributionDate?: string = "";
    teamId?: number = 0;
    teamName?: string = "";
    batchId?: number = 0;
    ItemId?: number = 0;
    batchQty?: string = "";
    batchNo?: string = "";
    barcode?: string = "";
    packingQty?: number = 0;
    temppackingQty?: number = 0;
    assignQty?: number = 0;
    itemUOM?: string = "";
    itemName?: string = "";
    warehouseQty?: string = "";
    WareHouseName?: string = "";
    existingQty?: number = 0;
    packingDistributionDetails?: PackingDistributionDetails[];
    warehouseId?: number = 0
}
export class PackingDistributionDetails {
    packingDistributionId?: number = 0;
    packingDistributionteamId?: number = 0;
    packingDistributionteamName?: string = "";
    packingDistributionItemId?: number = 0;
    packingDistributionbatchNo?: string = "";
    packingDistributionbarcode?: string = "";
    packingDistributionbatchId?: number = 0;
    packingDistributionpackingQty?: number = 0;
    packingDistributionpackingAssignQty?: number = 0;
    packingDistributionitemUOM?: string = "";
    packingDistributionitemName?: string = "";
    packingDistributionwarehouseQty?: string = "";
}

export class IPackingDistributionSearchoptions {
    searchOption?: string = "";
    searchValue?: string = "";
}


const packingDistributionDetails = observable<IPackingDistribution>({
    packingDistributionId: 0,
    packingDistributionDate: '',
    teamId: 0,
    teamName: '',
    ItemId: 0,
    batchQty: '',
    batchNo: '',
    packingQty: 0,
    assignQty: 0,
    itemUOM: '',
    itemName: '',
    warehouseQty: '',
    WareHouseName: '',
    existingQty: 0,
    packingDistributionDetails: [],

});

export default packingDistributionDetails;