import { observable } from "mobx";
import ComboPack from "../Combopack";

export interface ICombopackmaster{
    comboId?:number;
    mainItemId?:number;
    qty?:any;    
    itemName?:string;
    itemUOM?:string;
    combopackdetails?:ICombopackdetails[];
    userid?:number;

}
export interface ICombopackdetails{
    combomasterid?:number;
    itemNamedtl?:string;
    itemUOMdtl?:string;
    itemid?:number;
    qtydtl?:any;
    
}
 
export default ComboPack;
 