import { action, set, makeObservable, observable, runInAction } from 'mobx';
import newmemberDetails, { IMember } from '../model/index';
import apiAgent from '../../../APIAgent';

class MemberStore {
    newmemberDetls = newmemberDetails;
    @observable newmemberList = new Map();
    @observable memberPlanList = new Map()
    @observable memberdtls = new Map()
    isLoading = true;
    @observable newmemberLists = new Map<any, any>();
    entryNo = new Map();
    constructor() {
        makeObservable(this)
    }

    saveMember = async (newmember: IMember) => {
        try {

            return await apiAgent.newmemberMasters.saveNewmember(newmember);

        }
        catch (error) {
            console.log(error);
        }
    }
    UpdateMember = async (newmember: IMember) => {
        try {
           console.log(newmember,'newmember66');
           
            return await apiAgent.newmemberMasters.UpdateNewmember(newmember);

        }
        catch (error) {
            console.log(error);
        }
    }
    getNewmember = async () => {
        try {
            const newMemberData = await apiAgent.newmemberMasters.getNewmember();

            runInAction(() => {
                this.newmemberList = newMemberData;
                this.isLoading = false;
            })
            return newMemberData;
        }
        catch (error) {
            console.log(error);
        }
    }
    MemberDetails = async (memberId: Number) => {
        try {
            const newMemberData = await apiAgent.newmemberMasters.MemberDetails(memberId);
                   
            runInAction(() => {
                this.memberdtls = JSON.parse(newMemberData); 
               
            });
            return this.memberdtls;
        }
        catch (error) {
            console.log(error);
        }
    }

    memberShipSave = async (memberShip) => {
        try {
            const newMemberData = await apiAgent.newmemberMasters.memberShipSave(memberShip);
            return newMemberData;
        }
        catch (error) {
            console.log(error);
        }
    }
    membershipEdit = async (memberShip) => {
        try {
            const status = await apiAgent.newmemberMasters.membershipEdit(memberShip);
            return status;
        }
        catch (error) {
            console.log(error);
        }
    }
    deleteMembershipPlan = async (memberplanId) => {
        try {
            const status = await apiAgent.newmemberMasters.deleteMembershipPlan(memberplanId);
            return status;
        }
        catch (error) {
            console.log(error);
        }
    }
    getMembershipPlan = async (id) => {
        try {
            const membershipPlanList = await apiAgent.newmemberMasters.getMembershipPlan(id);
            runInAction(() => {
                this.memberPlanList = JSON.parse(membershipPlanList);
            })
            return JSON.parse(membershipPlanList);
        }
        catch (error) {
            console.log(error);
        }
    }
    assignMembershipSave = async (memberShip) => {
        try {
            const status = await apiAgent.newmemberMasters.assignMembershipSave(memberShip);
            return status;
        }
        catch (error) {
            console.log(error);
        }
    }
    assignMembershipEdit = async (memberShip) => {
        debugger
        try {
            const status = await apiAgent.newmemberMasters.assignMembershipEdit(memberShip);
            return status;
        }
        catch (error) {
            console.log(error);
        }
    }
    deleteassignMembership = async (assignId) => {
        try {
            const status = await apiAgent.newmemberMasters.deleteassignMembership(assignId);
            return status;
        }
        catch (error) {
            console.log(error);
        }
    }
    getAssignMebership = async () => {
        try {
            const membershipPlanList = await apiAgent.newmemberMasters.getAssignMebership();
            return JSON.parse(membershipPlanList);
        }
        catch (error) {
            console.log(error);
        }
    }

    getEntryNo = async (flag) => {
        try {

            const entryNo = await apiAgent.newmemberMasters.getEntryNo(flag);
            runInAction(() => {
                this.entryNo = entryNo;
            })
            return this.entryNo;
        }
        catch (error) {
            console.log(error);
        }
    }
    getNewmemberSearch = async (SearchOptions) => {
        try {
            const NewmemberData = await apiAgent.newmemberMasters.getNewmemberSearch(SearchOptions);
            this.newmemberList = NewmemberData;

            runInAction(() => {
                this.newmemberList = NewmemberData;

            })
            return this.newmemberList;

        }
        catch (error) {
            console.log(error);
        }
    }

    saveCustomerFeedBack = async (feedback) => {
        try {
            const status = await apiAgent.newmemberMasters.saveCustomerFeedBack(feedback);
            return status

        }
        catch (error) {
            console.log(error);
        }
    }

    getCustomerFeedBack = async (mode) => {
        try {
            const NewmemberData = await apiAgent.newmemberMasters.getCustomerFeedBack(mode);
            return JSON.parse(NewmemberData);
        }
        catch (error) {
            console.log(error);
        }
    }

    deleteNewmember = async (memberId) => {
        try {
            const status = await apiAgent.newmemberMasters.deleteNewmember(memberId);
            return status;
        }
        catch (error) {
            console.log(error);
        }
    }
    

    importExportFormSave = async (supplierMasters) => {
        try {
            const status = await apiAgent.newmemberMasters.importExportFormSave(supplierMasters);
            return status
        }
        catch (error) {
            console.log(error);
        }
    }

  
    @action updateNewmemberDetails = async (newmemberMasters: IMember) => {
        try {
            const status = await apiAgent.newmemberMasters.updateNewmemberDetails(newmemberMasters);

        }
        catch (error) {
            console.log(error);
        }
    }


    @action setNewmember = (state: IMember) => {
        this.newmemberList.set(0, state);
    }
    @action setNewmemberList = (state: IMember[]) => {
        set(state, this.newmemberList);
        return this.newmemberList;
    }
}
export default MemberStore;