
import {IStockAdjustmentModel } from '../model/index';
import apiAgent from '../../../APIAgent';
import { runInAction, observable } from 'mobx';

class StockAdjustmentOutletStore {
    @observable stockAdjustmentOutletDetails = new Map();
    @observable stockAdjustmentOutlet = new Map();

saveStockAdjustmentOutlet=async (stockAdjustmentOutlet:IStockAdjustmentModel)=> {
    try {
      const status= await apiAgent.stockAdjustmentOutletData.saveStockAdjustmentOutlet(stockAdjustmentOutlet);
        return status;
    }
    catch (error) {
        console.log(error);
    }
}

getStockAdjustmentOutlet = async (wid,id) => {
    try {
        const stockAdjustmentoutletData = await apiAgent.stockAdjustmentOutletData.getStockAdjustmentOutlet(wid,id);
        runInAction(() => {
            this.stockAdjustmentOutlet = JSON.parse(stockAdjustmentoutletData);
        })
        return JSON.parse(stockAdjustmentoutletData);
    }
    catch (error) {
        console.log(error);
    }
}

deleteStockAdjustmentOutlet = async (wid,id) => {
    try {      
    const status=  await apiAgent.stockAdjustmentOutletData.deleteStockAdjustmentOutlet(wid,id);     
   return status;
    }
    catch (error) {
        console.log(error);
    }
}

}
export default StockAdjustmentOutletStore;