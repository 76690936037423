import { action, set, makeObservable, observable, runInAction } from 'mobx';
import warehouseDetails, { IWarehouse } from '../model/index';
import apiAgent from '../../../APIAgent';



class warehouseStore {
    warehouseDetls = warehouseDetails;
    @observable warehouseList = new Map();
    isLoading = true;
    @observable warehouseLists = new Map<any, any>();
    wareHouseStatus = new Map();
    constructor() {
        makeObservable(this)
    }

    saveWarehouse = async (warehouse: IWarehouse) => {
        try {
            const status = await apiAgent.warehouse.saveWarehouse(warehouse);

            return status;
        }
        catch (error) {
            console.log(error);
        }
    }
    saveLoosedConversion = async (loosedConversion) => {
        try {
            const status = await apiAgent.warehouse.saveLoosedConversion(loosedConversion);
            return status;
        }
        catch (error) {
            console.log(error);
        }
    }
    loosedConversionDetailJSON = async (id?: number) => {
        try {
            const warehouseData = await apiAgent.warehouse.loosedConversionDetailJSON(id);
            return JSON.parse(warehouseData)
        }
        catch (error) {
            console.log(error);
        }
    }
    loosedConversionDelete = async (id?: number) => {
        try {
            const warehouseData = await apiAgent.warehouse.loosedConversionDelete(id);
            return JSON.parse(warehouseData)
        }
        catch (error) {
            console.log(error);
        }
    }
    

    loadDamageEntryApprovalDetails = async (id?: number) => {
        try {
            const loadDamageEntryApprovalDetail = await apiAgent.warehouse.loadDamageEntryApprovalDetails(id);
            return JSON.parse(loadDamageEntryApprovalDetail);
        }
        catch (error) {
            console.log(error);
        }
    }
    getWarehouse = async () => {
        try {
            const warehouseData = await apiAgent.warehouse.getWarehouse();
            this.warehouseList = warehouseData;

            runInAction(() => {
                this.warehouseList = warehouseData;
                this.isLoading = false;
            })
            return warehouseData;
        }
        catch (error) {
            console.log(error);
        }
    }
    getWarehouseSearch = async (SearchOptions) => {
        try {
            const warehouseData = await apiAgent.warehouse.getWarehouseSearch(SearchOptions);
            this.warehouseList = warehouseData;

            runInAction(() => {
                this.warehouseList = warehouseData;

            })
            return this.warehouseList;

        }
        catch (error) {
            console.log(error);
        }
    }
    deleteWarehouse = async (warehouseId) => {
        try {
            const status = await apiAgent.warehouse.deleteWarehouse(warehouseId);
            return status;
        }
        catch (error) {
            console.log(error);
        }
    }
    rejectionConversionSave = async (rejectionConversion) => {
        try {
            const status = await apiAgent.warehouse.rejectionConversionSave(rejectionConversion);
            return status;
        }
        catch (error) {
            console.log(error);
        }
    }
    getRejectionItems = async () => {
        try {
            const rejectionItems = await apiAgent.warehouse.getRejectionItems();
            return JSON.parse(rejectionItems)
        }
        catch (error) {
            console.log(error);
        }
    }
    loadRejectionconversionjson = async () => {
        try {
            const rejectionConversionJson = await apiAgent.warehouse.loadRejectionconversionjson();
            return JSON.parse(rejectionConversionJson)
        }
        catch (error) {
            console.log(error);
        }
    }
    rejectionStockAsonJson = async (rejectionParams) => {
        try {
            const rejectionStockJSON = await apiAgent.warehouse.rejectionStockAsonJson(rejectionParams);
            return JSON.parse(rejectionStockJSON)
        }
        catch (error) {
            console.log(error);
        }
    }
    damageOutletRejectionDetails = async (fromdate, todate) => {
        try {
            const rejectionStockJSONOutlet = await apiAgent.warehouse.damageOutletRejectionDetails(fromdate, todate);
            return JSON.parse(rejectionStockJSONOutlet)
        }
        catch (error) {
            console.log(error);
        }
    }
    @action updateWarehouseDetails = async (warehouse: IWarehouse) => {
        try {
            const status = await apiAgent.warehouse.updateWarehouseDetails(warehouse);

        }
        catch (error) {
            console.log(error);
        }
    }


    @action setWarehouse = (state: IWarehouse) => {
        this.warehouseList.set(0, state);
    }
    @action setWarehouseList = (state: IWarehouse[]) => {
        set(state, this.warehouseList);
        return this.warehouseList;
    }
}
export default warehouseStore;