import { runInAction, set, makeObservable, observable, action } from 'mobx';
import apiAgent from '../../../APIAgent';
import combopackdetails, { ICombopackdetails } from '../model/index';

class CombopackStore {
    @observable itemConversionDetls = combopackdetails;
    @observable itemConversionList = new Map<number, ICombopackdetails>();
    @observable comboPackMasterList = new Map();
    @observable comboPackMasterDetailsList = new Map();
    getItemConversions = new Map();
    itemConversionDetails = new Map();
    isInwardLoding = true;
    isLoading = true;
    constructor() {
        makeObservable(this);
    }
    saveItemConversion = async (combopackdetail: ICombopackdetails) => {
        // try {
        //   const status=  await apiAgent.com.itemConversion.saveItemConversion(combopackdetail);
        //   if(status!==undefined){
        //     return status;
        //    }
        // }
        // catch (error) {
        //     console.log(error);
        // }
    }
    getItemConversion = async () => {
        try {
            const getConversion = await apiAgent.itemConversion.getItemConversion();

            runInAction(() => {

                this.getItemConversions = getConversion;
            });
            return this.getItemConversions;
        }
        catch (error) {
            console.log(error);
        }
    }
    getItemConversionDetails = async (itemConversionId) => {

        try {
            const getConversion = await apiAgent.itemConversion.getItemConversionDetails(itemConversionId);

            runInAction(() => {

                this.itemConversionDetails = getConversion;
            });
            return this.itemConversionDetails;
        }
        catch (error) {
            console.log(error);
        }
    }
    getItemConversionSearch = async (SearchOptions) => {
        try {
            const Data = await apiAgent.itemConversion.getItemConversionSearch(SearchOptions);
            this.getItemConversions = Data;

            runInAction(() => {
                this.getItemConversions = Data;

            })
            return this.getItemConversions;


        }
        catch (error) {
            console.log(error);
        }
    }
    deleteItemConversionDetail = async (itemConversionDetailId) => {
        try {
            const status = await apiAgent.itemConversion.deleteItemConversionDetail(itemConversionDetailId);
            return status;
        }
        catch (error) {
            console.log(error);
        }
    }

    deleteComboPackMaster = async (comboId) => {
        try {
            const status = await apiAgent.combopack.deleteCombopackMaster(comboId);
            return status;
        }
        catch (error) {
            console.log(error);
        }
    }

    savecombopackMaster = async (body) => {
        try {
            const status = await apiAgent.combopack.savecombopackMaster(body);
            return status;
        }
        catch (error) {
            console.log(error);
        }
    }

    // getComboPackMasterList = async (mode: number, cmid: number) => {
    //     try {
    //         const stocks = await apiAgent.combopack.getComboPackMasterList(mode, cmid);
    //         runInAction(() => {
    //             this.comboPackMasterList = JSON.parse(stocks);
    //         });
    //         return this.comboPackMasterList;
    //     }
    //     catch (error) {
    //         console.log(error);
    //     }
    // }

    getComboPackMasterList = async (mode: number, cmid: number) => {
        try {
            const stocks = await apiAgent.combopack.getComboPackMasterList(mode, cmid);
            if (stocks !== undefined && stocks !== null) {
                try {
                    const parsedStocks = JSON.parse(stocks);
                    runInAction(() => {
                        this.comboPackMasterList = parsedStocks;
                    });
                    return this.comboPackMasterList;
                } catch (jsonError) {
                    console.error("Error parsing JSON response:", jsonError);
                }
            } else {
                console.warn("Received empty or invalid response from the API");
            }
        } catch (error) {
            console.error("API call failed:", error);
        }
    }

    getComboPackMasterDetailsList = async (mode: number, cmid: number) => {
        try {
            const stocks = await apiAgent.combopack.getComboPackMasterList(mode, cmid);
            if (stocks !== undefined && stocks !== null) {
                try {
                    const parsedStocks = JSON.parse(stocks);
                    runInAction(() => {
                        this.comboPackMasterDetailsList = parsedStocks;
                    });
                    return this.comboPackMasterDetailsList;
                } catch (jsonError) {
                    console.error("Error parsing JSON response:", jsonError);
                }
            } else {
                console.warn("Received empty or invalid response from the API");
            }
        } catch (error) {
            console.error("API call failed:", error);
        }
    }


    // @action setItemConversion = (state:IItemConversion) =>{
    //     this.itemConversionList.set(0,state);
    // }
    // @action setItemConversionList = (state: IItemConversion[]) =>{
    //     set(state,this.itemConversionList);
    //     return this.itemConversionList;
    // }
}
export default CombopackStore;

