import { observer } from 'mobx-react'
import React, { useState, useEffect, ChangeEvent, useRef } from 'react';
import { Button, Image, Modal, Spinner } from 'react-bootstrap'
import { useNavigate, useParams } from 'react-router-dom';
import PackingPlanValidation from './validation';
import AutoComplete from '../../common/shared/autoComplete';
import { useTranslation } from 'react-i18next';
import { PackingPlanSave, Barcodelist } from './model';
import useStores from '../../hooks';
import { QRCode } from 'react-qrcode-logo';
import moment from 'moment';
import Cookies from 'js-cookie';
import { removeDuplicates } from "../../common/shared/utils";
import { Barcode } from 'react-thermal-printer';

const PackingPlan = observer((): JSX.Element => {

    const { t } = useTranslation()
    const sideNavAccordian: any = t("sideNavAccordian");

    const navigate = useNavigate();
    const { supplierMasterStore, commonStore, outletManagerStore, machineMasterStore, inwardStore } = useStores()
    const { loadTeamDetail, loadTeamDetails, loadItemMaster, loadItemMasters, loadOwnItemMaster, loadOwnItemMasters } = commonStore;
    const { getBarcodeInward } = inwardStore
    const { Packingid } = useParams()

    const [localFields, setLocalFiedls] = useState<PackingPlanSave>(new PackingPlanSave());
    // const [itemInward, setItemInward] = useState<Barcodelist>(new Barcodelist())
    const [submitArr, setSubmitArr] = useState<any[]>([]);
    const [isLoadModal, setLoadModal] = useState(false);
    const [isLoading, setLoading] = useState(true);
    const [isBarcode, setIsBarcode] = useState('')
    const [isSuccessModal, setSuccessModal] = useState(false);
    const [isFailureModal, setFailureModal] = useState(false);
    const [errors, setError] = useState<any>({})
    const [loadBarcode, setLoadBarcode] = useState<any>([])

    const [canAdd, setCanAdd] = useState(false);
    const [canEdit, setCanEdit] = useState(false);
    const [canView, setCanView] = useState(false);
    // const [canPrint, setCanPrint] = useState(false);  
    // const [canUpdate, setCanUpdate] = useState(false); 

    console.log(localFields, 'localFields');

    function handleInputChange(e) {
        const { name, value } = e.target;
        setLocalFiedls({ ...localFields, [name]: name === "qty" ? Number(value) : value })
    }

    function handleInputChangeMRP(e) {
        const { name, value } = e.target;
        setLocalFiedls({ ...localFields, [name]: name === "mrp" ? Number(value) : value })
    }

    function handleInputChangebarcode (val:any) {
              
        setLocalFiedls({ ...localFields, barcode: val?.barcode,mrp: val?.mrp })
        console.log(val,'valvalue2');
        
        }

    function handleMenuDropDowns(name: string, val: any) {
        console.log(val, 'valValues');
        if (val !== null && name == "productid") {

            handleGetBarcodeInward(val);
            setLocalFiedls({ ...localFields, productid: val?.itemid, unitid: val?.uid?.toString(), productName: val?.itemname, itemUOM: val?.uom, expiryDays: val?.expiryDays })
        } else if (val !== null) {
            if (name == "teamid" && Packingid) {

                setSubmitArr((prevArr) =>
                    prevArr.map(item =>
                        item.packingid === localFields.packingid
                            ? {
                                ...item,
                                teamid: val?.teamMasterId,
                            }
                            : item
                    )
                );


            }
            if (name == "machineid" && Packingid) {
                setSubmitArr((prevArr) =>
                    prevArr.map(item =>
                        item.packingid === localFields.packingid
                            ? {
                                ...item,
                                machineid: val?.Mcode
                            }
                            : item
                    )
                );
                console.log(submitArr, 'submitArr');

            }

            setLocalFiedls({ ...localFields, [name]: name == "teamid" ? val?.teamMasterId : name === "machineid" ? val?.Mcode : val?.id })
        } else {
            setLocalFiedls({ ...localFields, [name]: "" })
        }
    }

    const handleGetBarcodeInward = async (val: any) => {

        try {
            const itemInwardList = await inwardStore.getBarcodeInward(val?.itemid);
            console.log(itemInwardList, 'itemInwardList');
            setLoadBarcode([...itemInwardList]);
           
            console.log(loadBarcode, 'loadBarcode');
        } catch (error) {
            console.error('Error fetching barcode data:', error);
        }
    };

    function handleAddFunc() {
        console.log(localFields, 'localFields');

        let error: any = {};
        error = PackingPlanValidation(localFields);
        setError(error)

        if (Object.keys(error)?.length === 0) {

            const newDate = new Date();
            newDate.setDate(newDate.getDate() + Number(localFields?.expiryDays));

            localFields.batchNo = localFields.teamid!.toString() + '/'
                + localFields!.packingdate!.replace(/[: -]/g, '') + '/'
                + localFields.productid?.toString() + '/'
                + moment(newDate).format('YYYY-MM-DD').replace(/[: -]/g, '') + '/'
                + localFields?.qty


            setSubmitArr([...submitArr, localFields])
            setLocalFiedls({ ...localFields, productName: '', itemUOM: '', qty: 0, barcode: '', mrp: 0, })
        }
    }

    function handleRemove(index) {
        submitArr?.splice(index, 1)
        setSubmitArr([...submitArr])
    }

    async function handleSubmit() {
        setLoading(true)
        if (submitArr?.length) {
            if (Packingid) {
                await machineMasterStore?.deletePackingPlan(Packingid);
            }
            console.log(submitArr, 'submitArr2');
            const status = await machineMasterStore.packingPlanSave(submitArr)
            console.log(status, 'sucessstatus');

            if (status === "Success") {
                setSuccessModal(true);
                setLoading(false)
            } else {
                setFailureModal(true)
                setLoading(false)
            }
        } else {
            let error: any = {};
            error.length = "Add Table Data"
            setError(error)
            setLoading(false)
        }
    }

    function handleSuccessClose() {
        navigate('/Admin/PackingPlan/PackingPlanList')
    }

    const handleAccessModalClose = () => {
        navigate('/Admin/Home');
    }

    async function initialFetchApi() {
        setLoading(false)
        const [entryNos] = await Promise.all([outletManagerStore.getEntryNo('Packing'), loadTeamDetail(), loadOwnItemMaster(), machineMasterStore.getMachineMasterList()]);
        if (!Packingid) {
            setLocalFiedls({ ...localFields, packingid: (entryNos?.entryNo + 1) })
        }
    }
    const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.checked) {
            setIsBarcode("someValue");
        } else {
            setIsBarcode("");
        }
    };

    async function getPakingPlanDet() {
        if (Packingid) {
            const dataDet = await machineMasterStore?.getPackingPlanList();
            console.log(dataDet, 'dataDet');
            const matchMst = dataDet.find(pack => Number(pack.Packingid) === Number(Packingid));
            const matchDet = dataDet.filter(pack => Number(pack.Packingid) === Number(Packingid));
            if (matchMst) {
                const updatedMaster = {
                    ...localFields,
                    packingid: matchMst.Packingid,
                    packingdate: matchMst.Packingdate,
                    teamid: matchMst.Teamid,
                    machineid: matchMst.Machineid,
                    // productName: matchMst.itemname,
                    // productid: matchMst.Productid,
                    //   expiryDays: matchedUser.teamMasterName,
                    // qty: matchMst.qty,
                    // unitid: matchMst.Unitid ? matchMst.Unitid.toString() : '',
                    // itemUOM: matchMst.Unitname,
                    // batchNo: matchMst.Batchno,
                    teamName: matchMst.TeamName,
                    machineName: matchMst.MachineName,
                };
                console.log(matchMst, 'matchMst');

                setLocalFiedls(updatedMaster);
                console.log(updatedMaster, 'updatedMaster');


                if (matchDet.length > 0) {
                    matchDet.forEach((user) => {
                        const updatedDetails = {
                            ...localFields,
                            packingid: user.Packingid,
                            packingdate: user.Packingdate,
                            teamid: user.Teamid,
                            machineid: user.Machineid,
                            productName: user.itemname,
                            productid: user.Productid,
                            expiryDays: user.teamMasterName,
                            qty: user.qty,
                            unitid: user.Unitid ? user.Unitid.toString() : '',
                            itemUOM: user.Unitname,
                            batchNo: user.Batchno,
                            barcode: user.Barcode,
                            mrp:user.mrp,
                        };

                        setSubmitArr((prevArr) => [...prevArr, updatedDetails]);
                        console.log(submitArr, 'submitArr');
                    });
                }

            } else {
                console.error(`No user found with teamMasterId: ${Packingid}`);
            }
        }
    }

    const isInitialRender = useRef(true);

    // useEffect(() => {
    //     if (isInitialRender.current) {
    //         initialFetchApi();
    //         getPakingPlanDet();
    //         isInitialRender.current = false;
    //     }
    //     return () => { };
    // }, [])
    const isCurrenPage = useRef(true)
    const empid = Number(Cookies.get('userId'));
    const formName = 'Packing_plan';

    useEffect(() => {

        const fetchRights = async () => {

            try {
                const data = await supplierMasterStore.fetchRights(empid, formName);

                if (Array.isArray(data) && data.length > 0) {
                    const specificFieldValue = data[0].sts;
                    const rightsValue = data[0].rights;
                    const lettersArray = rightsValue.split('');
                    if (specificFieldValue === 1) {
                        if (isCurrenPage.current) {
                            initialFetchApi();
                            getPakingPlanDet();
                            isCurrenPage.current = false
                            if (lettersArray.includes('a')) {
                                setCanAdd(true);
                            } else {
                                navigate('/Admin/PackingPlan/PackingPlanList')
                            }
                            if (lettersArray.includes('e')) {
                                setCanEdit(true);
                            }
                            // if (lettersArray.includes('v')) {
                            //   setCanView(true); 
                            // }     
                            // if (lettersArray.includes('p')) {
                            //   setCanPrint(true); 
                            // }       
                            // if (lettersArray.includes('u')) {
                            //   setCanUpdate(true); 
                            // }                     
                        }
                    } else if (specificFieldValue === 0) {
                        setLoadModal(true)
                    }
                }

            } catch (error) {
                console.error("Error fetching rights:", error);
            }
        };
        fetchRights();
    }, [empid, formName]);

    const handleFailureModalClose = () => setFailureModal(false);

    if (isLoadModal) {
        return (
            <div className="container">
                {isLoadModal && <h1>Access Denied</h1>}
                <Modal show={isLoadModal} onHide={handleAccessModalClose} className='SubmitModal'>
                    <Modal.Body>
                        <div className='Details Success'>
                            <div className='imgBox'>
                                <Image src={require('../../../gsmecom/images/warning.png')} />
                            </div>
                            <h4>Access Denied</h4>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleAccessModalClose}>Ok</Button>
                    </Modal.Footer>
                </Modal>
            </div>
        );
    }
    else {


        return (
            <>
                {/* { 
                <div className='SpinnerBox'>
                    <Spinner animation="border" role="status">
                        <span className="visually-hidden">Loading...</span>
                    </Spinner>
                </div> : */}
                <div>
                    <div className='vertical-space-40'></div>
                    <div className='outletInputField inputFormBox'>
                        <div className='hrBox' >
                            <h3>{sideNavAccordian?.packingPlan}</h3>
                        </div>
                        <div className='inputBoxLists pd-b-0'>
                            <div className='LgInputListsBox mr-left-0'>
                                <div className="row">
                                    <div className='col-md-4'>
                                        <div className='inputBox'>
                                            <label>No <span>*</span></label>
                                            <input type="text" className=''
                                                name='packingid'
                                                value={localFields?.packingid}
                                                disabled
                                                style={{ width: "100%" }} placeholder='Code...'></input>
                                            {errors.packingid && <p style={{ color: 'red' }}>{errors.packingid}</p>}
                                        </div>
                                    </div>
                                    <div className='col-md-6'>
                                        <div className='inputBox'>
                                            <label>Date <span>*</span></label>
                                            <input
                                                value={localFields.packingdate = localFields?.packingdate ?? new Date().toISOString().substr(0, 10)}
                                                defaultValue={new Date().toISOString().substr(0, 10)}
                                                type="date" style={{ width: '100%' }}
                                                name="packingdate"
                                                onChange={handleInputChange}
                                                placeholder="DD-MMM-YYYY" data-date="DD MM YYYY" data-date-format="DD MM YYYY"
                                            ></input>
                                            {errors.packingdate && <p style={{ color: 'red' }}>{errors.packingdate}</p>}
                                        </div>
                                    </div>
                                    <div className='vertical-space-10'></div>
                                    <div className='col-md-6'>
                                        <div className='inputBox'>
                                            <label>Team <span>*</span></label>
                                            <AutoComplete
                                                clsName='autoComplete full-width'
                                                placeholder="Select Team.."
                                                value={localFields?.teamName}
                                                emitOption={(val) => handleMenuDropDowns('teamid', val)}
                                                options={loadTeamDetails}
                                                getOptionLabel={'teamMasterName'}
                                            />
                                            {errors.teamid && <p style={{ color: 'red' }}>{errors.teamid}</p>}
                                        </div>
                                    </div>
                                    <div className='col-md-6'>
                                        {/* {machineMasterStore?.machineMasterList?.length > 0 && */}
                                        <div className='inputBox'>
                                            <label>Machine/Manual <span>*</span></label>
                                            <AutoComplete
                                                clsName='autoComplete full-width'
                                                placeholder="Select Machine/Manual.."
                                                value={localFields?.machineName}
                                                emitOption={(val) => handleMenuDropDowns('machineid', val)}
                                                options={machineMasterStore?.machineMasterList}
                                                getOptionLabel='MachineName'
                                            />
                                            {errors.machineid && <p style={{ color: 'red' }}>{errors.machineid}</p>}
                                        </div>
                                        {/* } */}
                                    </div>
                                    <div className='vertical-space-10'></div>
                                    <div className='col-md-6'>
                                        <div className='inputBox'>
                                            <label>Product <span>*</span></label>
                                            <AutoComplete
                                                clsName='autoComplete full-width'
                                                placeholder="Select Product.."
                                                emitOption={(val) => handleMenuDropDowns('productid', val)}
                                                options={loadOwnItemMasters}
                                                value={localFields?.productName}
                                                getOptionLabel='itemname'
                                            />
                                            {errors.productid && <p style={{ color: 'red' }}>{errors.productid}</p>}
                                        </div>
                                    </div>
                                    <div className='col-md-6'>
                                        <div className='inputBox'>
                                            <label>Qty <span>*</span></label>
                                            <input type="text" className=''
                                                name='qty'
                                                value={localFields?.qty}
                                                onChange={handleInputChange}
                                                style={{ width: "100%" }} placeholder='Qty..'></input>
                                            {errors.qty && <p style={{ color: 'red' }}>{errors.qty}</p>}
                                        </div>
                                    </div>

                                    <div className='col-md-4'>
                                        <div className='inputBox'>

                                            <label>Barcode </label>

                                            {isBarcode !== "" ? (
                                                <input type="text" className='' style={{ width: "100%" }}
                                                    name='barcode'
                                                    value={localFields?.barcode}
                                                     onChange={(e) => setLocalFiedls({ ...localFields, barcode: e.target.value })}
                                                    // onChange={handleInputChangebarcode}
                                                    placeholder='Enter Barcode..' />
                                            ) : (
                                                <AutoComplete
                                                    clsName='full-width'
                                                    value={localFields?.barcode}
                                                    placeholder='Select Barcode..'
                                                    options={removeDuplicates(loadBarcode, 'barcode')}
                                                    emitOption={(option) => setLocalFiedls({ ...localFields, barcode: option.barcode, mrp: Number(option.mrp) })} // Correct emitOption function
                                                    getOptionLabel='barcode'
                                                    freeSolo={true}
                                                />
                                            )}
                                            {errors.barcode && <p style={{ color: 'red' }}>{errors.barcode}</p>}
                                            {/* <input type="text" className='' style={{ width: "100%" }}
                                                    name='barcode'
                                                    value={itemInward?.barcode!}
                                                    onChange={(e) => setItemInward({ ...itemInward, barcode: e.target.value })}
                                                    placeholder='Enter Barcode..' /> */}
                                            {/* {errors.barcode && <p style={{ color: 'red' }}>{errors.barcode}</p>} */}
                                        </div>
                                        <div className='vertical-space-10'></div>
                                    </div>
                                    <div className='col-md-4'>
                                        <div className='inputBox'>
                                            <label>MRP <span>*</span></label>
                                            <input type="number" className=''
                                                name='mrp'
                                                value={localFields?.mrp}
                                                onChange={handleInputChangeMRP}
                                                style={{ width: "100%" }} placeholder='Mrp..'></input>
                                            {errors.mrp && <p style={{ color: 'red' }}>{errors.mrp}</p>}
                                        </div>
                                    </div>

                                    {/* <div className='col-md-6'>
                                        <div className='inputBox'>
                                            <label>Barcode <span>*</span></label>
                                            <input type="text" className=''
                                                name='Barcode'
                                                value={localFields?.qty}
                                                onChange={handleInputChange}
                                                style={{ width: "100%" }} placeholder='Barcode'></input>
                                            {errors.Barcode && <p style={{ color: 'red' }}>{errors.Barcode}</p>}
                                        </div>
                                    </div> */}
                                    <div className="col-sm-4">
                                        <div className='inputradio mt-3'>
                                            <input type='checkbox' checked={isBarcode != ""} onChange={handleCheckboxChange} className='mt-4 justifyContentStart' name='bundleQty' ></input>
                                            <label className="mx-2">New Barcode
                                            </label>
                                        </div>
                                    </div>
                                    <div className='vertical-space-10'></div>
                                    <div className='col-md-4'>
                                        <div className='inputBox'>
                                            <label>UOM <span>*</span></label>
                                            <input type="text" className=''
                                                name='unitid'
                                                value={localFields?.itemUOM}
                                                disabled
                                                style={{ width: "100%" }} placeholder='UOM..'></input>
                                            {errors.unitid && <p style={{ color: 'red' }}>{errors.unitid}</p>}
                                        </div>
                                    </div>

                                    <div className="col-md-2"></div>
                                    <div className="col-md-4">
                                        <button className='dfBtn' type='submit' onClick={handleAddFunc}>Add</button>
                                    </div>
                                </div>

                                <div className='vertical-space-30'></div>
                                <div className='vertical-space-30'></div>
                                <div className="tableListDetails">
                                    <div className='tableBox'>
                                        <table className="table table-striped">
                                            <thead>
                                                <tr>
                                                    <th scope="col">Product</th>
                                                    <th scope="col">Qty</th>
                                                    <th scope="col">UOM</th>
                                                    <th scope="col">MRP</th>
                                                    <th scope="col">Barcode</th>
                                                    <th scope="col">Batch No</th>
                                                    <th></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {submitArr?.length ? submitArr?.map((submitObj, index) => {
                                                    return (
                                                        <tr>
                                                            <td>{submitObj?.productName}</td>
                                                            <td>{submitObj?.qty}</td>
                                                            <td>{submitObj?.itemUOM}</td>
                                                            <td>{submitObj?.mrp}</td>
                                                            <td>{submitObj?.barcode}</td>
                                                            <td>{submitObj?.batchNo}</td>
                                                            <td><button style={{
                                                                border: '2px solid #dc3545', background: '#dc3545', color: 'white', fontWeight: '400',
                                                                borderRadius: '6px', fontSize: '12px'
                                                            }} type='submit'
                                                                onClick={() => handleRemove(index)}>Remove</button></td>
                                                        </tr>
                                                    )
                                                }) :
                                                    <div>
                                                        No data
                                                    </div>
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <div className='vertical-space-20'></div>

                                <div className='btnBox'>
                                    <button className='secondaryBtn' onClick={handleSuccessClose}>List</button>
                                    <button className='dfBtn' type='submit' onClick={handleSubmit}>Submit</button>
                                </div>
                            </div>
                        </div>

                        <Modal show={isSuccessModal} onHide={handleSuccessClose} className='SubmitModal'>
                            <Modal.Body>
                                <div className='Details Success'>
                                    <div className='imgBox'>
                                        <Image src={require('../../../gsmecom/images/checked.png')} />
                                    </div>
                                    <h4>Succesfully Submitted</h4>
                                </div>
                            </Modal.Body>
                            <Modal.Footer>
                                <Button variant="secondary" onClick={handleSuccessClose}>
                                    Ok
                                </Button>
                            </Modal.Footer>
                        </Modal>
                        <Modal show={isFailureModal} onHide={() => setFailureModal(false)} className='SubmitModal'>
                            <Modal.Body>
                                <div className='Details Success'>
                                    <div className='imgBox'>
                                        <Image src={require('../../../gsmecom/images/warning.png')} />
                                    </div>
                                    <h4>Failed</h4>
                                </div>
                            </Modal.Body>
                            <Modal.Footer>
                                <Button variant="secondary" onClick={() => setFailureModal(false)}>
                                    Ok
                                </Button>
                            </Modal.Footer>
                        </Modal>

                    </div>
                </div>
            </>
        )
    }
})

export default PackingPlan
