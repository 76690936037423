import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Autocomplete, Pagination, Stack, TextField } from '@mui/material';
import { observer } from 'mobx-react';
import React, { useEffect, useMemo, useRef, useState } from 'react'
import { Spinner } from 'react-bootstrap';
import useStores from '../../hooks';
import moment from 'moment';
import { currentPageDatas } from '../../common/shared/utils';
import { IOutletStockAnalysis, ISalesRegisterRequest } from '../OutetStockAnalysis/model';

import ExcelJS from 'exceljs';
import { saveAs } from 'file-saver';

interface ExcelSheetData {
    Outlet_Name?: string;
    POS_Id?: number,
    Bill_No?: string;
    Bill_Date?: string;
    Bdate?: string;
    Bill_Type?: string,
    Customer_Type?: string,
    ctype?: string,
    M_Id?: number,
    Member_Name?: string,
    Mobile_No?: string;
    Item_Id?: number,
    Item_Name?: string,
    Categry?: string,
    HSN_Code?: string,
    Barcode?: string,
    Batch_No?: string,
    Remarks?: string,
    Qty?: number,
    Unit_Name?: string,
    Free_Qty?: number,
    Rate?: number,
    Amount?: number,
    Discount_Value?: number,
    Discount_Per?: number,
    CGSTP?: number,
    CGST_Value?: number,
    SGSTP?: number,
    SGST_Value?: number,
    Total_Value?: number,
    posroundoff?: number,
    Invoiceamount?: number,
    Employee_Name?: string,
    Create_DateTime?: string
}

const PurchaseBillRegister = observer((): JSX.Element => {

    const { purchaseBillRegStore } = useStores();

    const [outletSummaryFields, setoutletSummaryFields] = useState<IOutletStockAnalysis>(new IOutletStockAnalysis())
    const [salesRegisterRequest, setSalesRegisterRequest] = useState<ISalesRegisterRequest>(new ISalesRegisterRequest())
    const [loading, setLoading] = useState(true);

    const [outletStockSummaries, setOutStockSummaryies] = useState<any[]>([]);
    const [errors, setErrors] = useState<any>({})

    const filteredData = useMemo(() => {
        if (outletStockSummaries?.length > 0) {
            let filterFroMArry = outletStockSummaries?.map((stock) => {
                if (handleFindDate(stock?.bill_date)) {
                    return stock
                } else {
                    return undefined
                }
            })?.filter((each) => each != undefined);
            return filterFroMArry;
        } else {
            return [];
        }
    }, [outletSummaryFields, outletStockSummaries])

    function handleInputChange(e) {
        const { name, value } = e.target;
        setSalesRegisterRequest({ ...salesRegisterRequest, [name]: value })
        // setoutletSummaryFields({ ...outletSummaryFields, [name]: value })
    }

    async function handleGenerateStock() {
        let error: any = {}

        setLoading(true)
        const stockSummaryDetails = await purchaseBillRegStore.getPurchaseBillRegDetails(salesRegisterRequest);
        console.log(stockSummaryDetails, 'purchaseRegisterData');
        // setOutStockSummaryies([...stockSummaryDetails])


        setLoading(false);
    }
    async function createExportExcelObj() {

        const purchaseRegisterData = await purchaseBillRegStore.getPurchaseBillRegDetails(salesRegisterRequest);
        console.log(purchaseRegisterData, 'purchaseRegisterData');

         exportExcelDataDetails(purchaseRegisterData)


    }


    // function createExportExcelObj(stockSummary: any[]) {
    //     let excelArr: ExcelSheetData[] = stockSummary?.map((stock) => {
    //         const excelObj: ExcelSheetData = {
    //             Supplier_Name: stock?.supplierName,
    //             BillNo: stock?.bill_no,
    //             Bill_Date: moment(stock?.bill_date)?.format('DD-MMM-YYYY'),
    //             Bill_Value: stock?.bill_Amount,
    //             Tax: stock?.tax_per,
    //             Taxable_Value: stock?.taxable_Value,
    //             IGST: stock?.igsT_AMT,
    //             CGST: stock?.cgsT_AMT,
    //             SGST: stock?.sgsT_AMT,
    //             CESS: stock?.cess_AMT
    //         }
    //         return excelObj;
    //     })
    //     exportToExcel(excelArr)
    // }
    const exportExcelDataDetails = async (excelDataDetails) => {
        console.log(excelDataDetails,'excelDataDetails555');
        

        const workbook = new ExcelJS.Workbook();
        const worksheet = workbook.addWorksheet('Results');

        worksheet.pageSetup = {
            fitToPage: true,
            fitToHeight: 1,
            fitToWidth: 1,
            orientation: 'landscape',
            paperSize: 9,
        };

        // Create subtitle strings

        const subtitleStart = `Date From : ${moment(salesRegisterRequest?.startDate).format('DD-MM-YYYY')} `;
        const subtitleEnd = `  To : ${moment(salesRegisterRequest?.endDate).format('DD-MM-YYYY')}`;
        const subtitleCombined = `Report Generated on: ${moment(new Date().toLocaleDateString()).format('DD-MM-YYYY')}`; // Static string with current date



        worksheet.mergeCells('A1:AP1');
        const titleCell = worksheet.getCell('A1');
        titleCell.value = "GANGA SUPER MARKET";
        titleCell.font = { size: 17, bold: true };
        titleCell.alignment = { horizontal: 'center', vertical: 'middle' };

        worksheet.mergeCells('A2:AP2');
        const subtitleCell = worksheet.getCell('A2');
        subtitleCell.value = "SALES REGISTER " + subtitleStart + subtitleEnd;
        subtitleCell.font = { size: 13, bold: true };
        subtitleCell.alignment = { horizontal: 'center', vertical: 'middle' };


        worksheet.mergeCells('B5:E5');
        const subtitleRCell = worksheet.getCell('B5');
        subtitleRCell.value = subtitleCombined;
        subtitleRCell.font = { size: 13, bold: true };
        subtitleRCell.alignment = { horizontal: 'left', vertical: 'middle' };

        worksheet.addRow([]);

        const addBorders = (cell) => {
            cell.border = {
                top: { style: 'thin' },
                left: { style: 'thin' },
                bottom: { style: 'thin' },
                right: { style: 'thin' },
            };
        };

        const addBordersToMergedCells = (worksheet, startCell, endCell) => {
            const startRow = parseInt(startCell[1]);
            const endRow = parseInt(endCell[1]);
            const startCol = startCell.charCodeAt(0) - 64;
            const endCol = endCell.charCodeAt(0) - 64;

            for (let row = startRow; row <= endRow; row++) {
                for (let col = startCol; col <= endCol; col++) {
                    addBorders(worksheet.getCell(row, col));
                }
            }
        };

        //worksheet.mergeCells('B7:C7');
        const SubHeadCell0 = worksheet.getCell('B7');
        SubHeadCell0.value = "SNo";
        SubHeadCell0.font = { bold: true };
        SubHeadCell0.alignment = { horizontal: 'center', vertical: 'middle' };
        addBordersToMergedCells(worksheet, 'B7', 'B8');

        //worksheet.mergeCells('D7:E7');
        const SubHeadCell2 = worksheet.getCell('C7');
        SubHeadCell2.value = "Location Name";
        SubHeadCell2.font = { bold: true };
        SubHeadCell2.alignment = { horizontal: 'center', vertical: 'middle' };
        addBordersToMergedCells(worksheet, 'C7', 'C7');

         //worksheet.mergeCells('F7:G7');
         const SubHeadCell32 = worksheet.getCell('D7');
         SubHeadCell32.value = "Voc No";
         SubHeadCell32.font = { bold: true };
         SubHeadCell32.alignment = { horizontal: 'center', vertical: 'middle' };
         addBordersToMergedCells(worksheet, 'D7', 'D7');
 
         // worksheet.mergeCells('H7:I7');
         const SubHeadCell42 = worksheet.getCell('E7');
         SubHeadCell42.value = "Voc Date";
         SubHeadCell42.font = { bold: true };
         SubHeadCell42.alignment = { horizontal: 'center', vertical: 'middle' };
         addBordersToMergedCells(worksheet, 'E7', 'E7');

        //worksheet.mergeCells('F7:G7');
        const SubHeadCell3 = worksheet.getCell('F7');
        SubHeadCell3.value = "Bill No";
        SubHeadCell3.font = { bold: true };
        SubHeadCell3.alignment = { horizontal: 'center', vertical: 'middle' };
        addBordersToMergedCells(worksheet, 'F7', 'F7');

        // worksheet.mergeCells('H7:I7');
        const SubHeadCell4 = worksheet.getCell('G7');
        SubHeadCell4.value = "Bill Date";
        SubHeadCell4.font = { bold: true };
        SubHeadCell4.alignment = { horizontal: 'center', vertical: 'middle' };
        addBordersToMergedCells(worksheet, 'G7', 'G7');

        // worksheet.mergeCells('J7:K7');
        const SubHeadCell5 = worksheet.getCell('H7');
        SubHeadCell5.value = "Bill Type";
        SubHeadCell5.font = { bold: true };
        SubHeadCell5.alignment = { horizontal: 'center', vertical: 'middle' };
        addBordersToMergedCells(worksheet, 'H7', 'H7');
       

        //worksheet.mergeCells('N7:O7');
        const SubHeadCell7 = worksheet.getCell('I7');
        SubHeadCell7.value = "Supplier Id";
        SubHeadCell7.font = { bold: true };
        SubHeadCell7.alignment = { horizontal: 'center', vertical: 'middle' };
        addBordersToMergedCells(worksheet, 'I7', 'I7');

        // worksheet.mergeCells('I7:I7');
        const SubHeadCell8 = worksheet.getCell('J7');
        SubHeadCell8.value = "Supplier Name";
        SubHeadCell8.font = { bold: true };
        SubHeadCell8.alignment = { horizontal: 'center', vertical: 'middle' };
        addBordersToMergedCells(worksheet, 'J7', 'J7');

        //worksheet.mergeCells('I7:I7');
        const SubHeadCell9 = worksheet.getCell('K7');
        SubHeadCell9.value = "Mobile No";
        SubHeadCell9.font = { bold: true };
        SubHeadCell9.alignment = { horizontal: 'center', vertical: 'middle' };
        addBordersToMergedCells(worksheet, 'K7', 'K7');

        //worksheet.mergeCells('I7:I7');
        const SubHeadCell10 = worksheet.getCell('L7');
        SubHeadCell10.value = "Item Id";
        SubHeadCell10.font = { bold: true };
        SubHeadCell10.alignment = { horizontal: 'center', vertical: 'middle' };
        addBordersToMergedCells(worksheet, 'L7', 'L7');

        //worksheet.mergeCells('I7:I7');
        const SubHeadCell11 = worksheet.getCell('M7');
        SubHeadCell11.value = "Item Name";
        SubHeadCell11.font = { bold: true };
        SubHeadCell11.alignment = { horizontal: 'center', vertical: 'middle' };
        addBordersToMergedCells(worksheet, 'M7', 'M7');

        //worksheet.mergeCells('I7:I7');
        const SubHeadCell12 = worksheet.getCell('N7');
        SubHeadCell12.value = "Categry";
        SubHeadCell12.font = { bold: true };
        SubHeadCell12.alignment = { horizontal: 'center', vertical: 'middle' };
        addBordersToMergedCells(worksheet, 'N7', 'N7');

        //worksheet.mergeCells('I7:I7');
        const SubHeadCell13 = worksheet.getCell('O7');
        SubHeadCell13.value = "HSN Code";
        SubHeadCell13.font = { bold: true };
        SubHeadCell13.alignment = { horizontal: 'center', vertical: 'middle' };
        addBordersToMergedCells(worksheet, 'O7', 'O7');

        const SubHeadCell14 = worksheet.getCell('P7');
        SubHeadCell14.value = "Barcode";
        SubHeadCell14.font = { bold: true };
        SubHeadCell14.alignment = { horizontal: 'center', vertical: 'middle' };
        addBordersToMergedCells(worksheet, 'P7', 'P7');

        const SubHeadCell15 = worksheet.getCell('Q7');
        SubHeadCell15.value = " Batch No";
        SubHeadCell15.font = { bold: true };
        SubHeadCell15.alignment = { horizontal: 'center', vertical: 'middle' };
        addBordersToMergedCells(worksheet, 'Q7', 'Q7');

        const SubHeadCell16 = worksheet.getCell('R7');
        SubHeadCell16.value = "Remarks";
        SubHeadCell16.font = { bold: true };
        SubHeadCell16.alignment = { horizontal: 'center', vertical: 'middle' };
        addBordersToMergedCells(worksheet, 'R7', 'R7');

        const SubHeadCell17 = worksheet.getCell('S7');
        SubHeadCell17.value = " Qty";
        SubHeadCell17.font = { bold: true };
        SubHeadCell17.alignment = { horizontal: 'center', vertical: 'middle' };
        addBordersToMergedCells(worksheet, 'S7', 'S7');

        const SubHeadCell18 = worksheet.getCell('T7');
        SubHeadCell18.value = " Unit Name";
        SubHeadCell18.font = { bold: true };
        SubHeadCell18.alignment = { horizontal: 'center', vertical: 'middle' };
        addBordersToMergedCells(worksheet, 'T7', 'T7');

        const SubHeadCell19 = worksheet.getCell('U7');
        SubHeadCell19.value = "Free Qty";
        SubHeadCell19.font = { bold: true };
        SubHeadCell19.alignment = { horizontal: 'center', vertical: 'middle' };
        addBordersToMergedCells(worksheet, 'U7', 'U7');

        const SubHeadCell20 = worksheet.getCell('V7');
        SubHeadCell20.value = " Rate";
        SubHeadCell20.font = { bold: true };
        SubHeadCell20.alignment = { horizontal: 'center', vertical: 'middle' };
        addBordersToMergedCells(worksheet, 'V7', 'V7');

        const SubHeadCell21 = worksheet.getCell('W7');
        SubHeadCell21.value = " Amount";
        SubHeadCell21.font = { bold: true };
        SubHeadCell21.alignment = { horizontal: 'center', vertical: 'middle' };
        addBordersToMergedCells(worksheet, 'W7', 'W7');

        const SubHeadCell22 = worksheet.getCell('X7');
        SubHeadCell22.value = " Discount Per";
        SubHeadCell22.font = { bold: true };
        SubHeadCell22.alignment = { horizontal: 'center', vertical: 'middle' };
        addBordersToMergedCells(worksheet, 'X7', 'X7');

        const SubHeadCell23 = worksheet.getCell('Y7');
        SubHeadCell23.value = " Discount Value";
        SubHeadCell23.font = { bold: true };
        SubHeadCell23.alignment = { horizontal: 'center', vertical: 'middle' };
        addBordersToMergedCells(worksheet, 'Y7', 'Y7');

        const SubHeadCell24 = worksheet.getCell('Z7');
        SubHeadCell24.value = " CGST Per";
        SubHeadCell24.font = { bold: true };
        SubHeadCell24.alignment = { horizontal: 'center', vertical: 'middle' };
        addBordersToMergedCells(worksheet, 'Z7', 'Z7');

        const SubHeadCell25 = worksheet.getCell('AA7');
        SubHeadCell25.value = " CGST Value";
        SubHeadCell25.font = { bold: true };
        SubHeadCell25.alignment = { horizontal: 'center', vertical: 'middle' };
        addBordersToMergedCells(worksheet, 'AA7', 'AB7');

        const SubHeadCell26 = worksheet.getCell('AB7');
        SubHeadCell26.value = " SGST Per";
        SubHeadCell26.font = { bold: true };
        SubHeadCell26.alignment = { horizontal: 'center', vertical: 'middle' };
        addBorders(worksheet.getCell('AB7'));


        const SubHeadCell27 = worksheet.getCell('AC7');
        SubHeadCell27.value = " SGST Value";
        SubHeadCell27.font = { bold: true };
        SubHeadCell27.alignment = { horizontal: 'center', vertical: 'middle' };
        addBorders(worksheet.getCell('AC7'));

        const SubHeadCell28 = worksheet.getCell('AD7');
        SubHeadCell28.value = " IGST Per";
        SubHeadCell28.font = { bold: true };
        SubHeadCell28.alignment = { horizontal: 'center', vertical: 'middle' };
        addBorders(worksheet.getCell('AD7'));

        const SubHeadCell29 = worksheet.getCell('AE7');
        SubHeadCell29.value = " IGST Value";
        SubHeadCell29.font = { bold: true };
        SubHeadCell29.alignment = { horizontal: 'center', vertical: 'middle' };
        addBorders(worksheet.getCell('AE7'));

        const SubHeadCell30 = worksheet.getCell('AF7');
        SubHeadCell30.value = " Total Value";
        SubHeadCell30.font = { bold: true };
        SubHeadCell30.alignment = { horizontal: 'center', vertical: 'middle' };
        addBorders(worksheet.getCell('AF7'));

        const SubHeadCell311 = worksheet.getCell('AG7');
        SubHeadCell311.value = "  Roundoff";
        SubHeadCell311.font = { bold: true };
        SubHeadCell311.alignment = { horizontal: 'center', vertical: 'middle' };
        addBorders(worksheet.getCell('AG7'));

        const SubHeadCell31 = worksheet.getCell('AH7');
        SubHeadCell31.value = "  Invoice Amount";
        SubHeadCell31.font = { bold: true };
        SubHeadCell31.alignment = { horizontal: 'center', vertical: 'middle' };
        addBorders(worksheet.getCell('AH7'));

        const SubHeadCell40 = worksheet.getCell('AI7');
        SubHeadCell40.value = "Employee_Name";
        SubHeadCell40.font = { bold: true };
        SubHeadCell40.alignment = { horizontal: 'center', vertical: 'middle' };
        addBorders(worksheet.getCell('AI7'));

        const SubHeadCell41 = worksheet.getCell('AJ7');
        SubHeadCell41.value = "Create_DateTime";
        SubHeadCell41.font = { bold: true };
        SubHeadCell41.alignment = { horizontal: 'center', vertical: 'middle' };
        addBorders(worksheet.getCell('AJ7'));

      

        const testColumnIndex = 5;
        const weightColumnIndex = 6;
        const widthInInches = 3;
        const widthInChars = widthInInches * 5.1;

        
        excelDataDetails.forEach((datadet, index) => {
            console.log(datadet, 'datadet');

            const rowIndex = index + 8;

            const seqNoCell = worksheet.getCell(rowIndex, 2);
            seqNoCell.value = index + 1;
            seqNoCell.alignment = { horizontal: 'right', vertical: 'middle' };
            addBorders(seqNoCell);

            const OutletnameNumberCell = worksheet.getCell(rowIndex, 3);
            OutletnameNumberCell.value = datadet.Outletname;
            OutletnameNumberCell.alignment = { horizontal: 'left', vertical: 'middle' };
            worksheet.getColumn(3).width = 30;
            addBorders(OutletnameNumberCell);

            const BillnoNumberCell1 = worksheet.getCell(rowIndex, 4);
            BillnoNumberCell1.value = datadet.record_no;
            BillnoNumberCell1.alignment = { horizontal: 'center', vertical: 'middle' };
            worksheet.getColumn(4).width = 15;
            addBorders(BillnoNumberCell1);

            const BilldateNumberCell2 = worksheet.getCell(rowIndex, 5);
            BilldateNumberCell2.value = datadet.record_date;
            BilldateNumberCell2.alignment = { horizontal: 'center', vertical: 'middle' };
            worksheet.getColumn(5).width = 15;
            addBorders(BilldateNumberCell2);

            const BillnoNumberCell = worksheet.getCell(rowIndex, 6);
            BillnoNumberCell.value = datadet.Billno;
            BillnoNumberCell.alignment = { horizontal: 'center', vertical: 'middle' };
            worksheet.getColumn(6).width = 15;
            addBorders(BillnoNumberCell);

            const BilldateNumberCell = worksheet.getCell(rowIndex, 7);
            BilldateNumberCell.value = datadet.billdate;
            BilldateNumberCell.alignment = { horizontal: 'center', vertical: 'middle' };
            worksheet.getColumn(7).width = 15;
            addBorders(BilldateNumberCell);

            const BilltypeNumberCell = worksheet.getCell(rowIndex, 8);
            BilltypeNumberCell.value = datadet.BillType;
            BilltypeNumberCell.alignment = { horizontal: 'center', vertical: 'middle' };
            worksheet.getColumn(8).width = 15;
            addBorders(BilltypeNumberCell);
 
            const midCell = worksheet.getCell(rowIndex, 9);
            midCell.value = datadet.mid;
            midCell.alignment = { horizontal: 'right', vertical: 'middle' };
            worksheet.getColumn(9).width = 12;
            addBorders(midCell);

            const membernameCell = worksheet.getCell(rowIndex, 10);
            membernameCell.value = datadet.membername;
            membernameCell.alignment = { horizontal: 'left', vertical: 'middle' };
            worksheet.getColumn(10).width = 40;
            addBorders(membernameCell);

            const MobilenoCell = worksheet.getCell(rowIndex, 11);
            MobilenoCell.value = datadet.Mobileno;
            MobilenoCell.alignment = { horizontal: 'center', vertical: 'middle' };
            worksheet.getColumn(11).width = 15;
            addBorders(MobilenoCell);

            const itemidCell = worksheet.getCell(rowIndex, 12);
            itemidCell.value = datadet.itemid;
            itemidCell.alignment = { horizontal: 'center', vertical: 'middle' };
            worksheet.getColumn(12).width = 12;
            addBorders(itemidCell);

            const itemnameCell = worksheet.getCell(rowIndex, 13);
            itemnameCell.value = datadet.itemname;
            itemnameCell.alignment = { horizontal: 'left', vertical: 'middle' };
            worksheet.getColumn(13).width = 60;
            addBorders(itemnameCell);

            const CategoryCell = worksheet.getCell(rowIndex, 14);
            CategoryCell.value = datadet.Category;
            CategoryCell.alignment = { horizontal: 'left', vertical: 'middle' };
            worksheet.getColumn(14).width = 20;
            addBorders(CategoryCell);

            const HSNCodeCell = worksheet.getCell(rowIndex, 15);
            HSNCodeCell.value = datadet.Hsncode;
            HSNCodeCell.alignment = { horizontal: 'left', vertical: 'middle' };
            worksheet.getColumn(15).width = 15;
            addBorders(HSNCodeCell);

            const BarcodeCell = worksheet.getCell(rowIndex, 16);
            BarcodeCell.value = datadet.Barcode;
            BarcodeCell.alignment = { horizontal: 'center', vertical: 'middle' };
            worksheet.getColumn(16).width = 20;
            addBorders(BarcodeCell);

            const batch_noCell = worksheet.getCell(rowIndex, 17);
            batch_noCell.value = datadet.batch_no;
            batch_noCell.alignment = { horizontal: 'center', vertical: 'middle' };
            worksheet.getColumn(17).width = 20;
            addBorders(batch_noCell);

            const RemarksCell = worksheet.getCell(rowIndex, 18);
            RemarksCell.value = datadet.Remarks;
            RemarksCell.alignment = { horizontal: 'left', vertical: 'middle' };
            worksheet.getColumn(18).width = 20;
            addBorders(RemarksCell);

            const QtyCell = worksheet.getCell(rowIndex, 19);
            QtyCell.value = datadet.Qty;
            QtyCell.alignment = { horizontal: 'right', vertical: 'middle' };
            worksheet.getColumn(19).width = 10;
            addBorders(QtyCell);

            const UnitnameCell = worksheet.getCell(rowIndex, 20);
            UnitnameCell.value = datadet.Unitname;
            UnitnameCell.alignment = { horizontal: 'center', vertical: 'middle' };
            worksheet.getColumn(20).width = 15;
            addBorders(UnitnameCell);

            const freeqtyCell = worksheet.getCell(rowIndex, 21);
            freeqtyCell.value = datadet.freeqty;
            freeqtyCell.alignment = { horizontal: 'right', vertical: 'middle' };
            worksheet.getColumn(21).width = 10;
            addBorders(freeqtyCell);

            const rateCell = worksheet.getCell(rowIndex, 22);
            rateCell.value = datadet.rate;
            rateCell.alignment = { horizontal: 'right', vertical: 'middle' };
            worksheet.getColumn(22).width = 10;
            addBorders(rateCell);

            const AmountCell = worksheet.getCell(rowIndex, 23);
            AmountCell.value = datadet.Amount;
            AmountCell.alignment = { horizontal: 'right', vertical: 'middle' };
            worksheet.getColumn(23).width = 15;
            addBorders(AmountCell);


            const DiscountPerCell = worksheet.getCell(rowIndex, 24);
            DiscountPerCell.value = datadet.DiscountPer;
            DiscountPerCell.alignment = { horizontal: 'right', vertical: 'middle' };
            worksheet.getColumn(24).width = 15;
            addBorders(DiscountPerCell);

            const DiscountvalueCell = worksheet.getCell(rowIndex, 25);
            DiscountvalueCell.value = datadet.Discountvalue;
            DiscountvalueCell.alignment = { horizontal: 'right', vertical: 'middle' };
            worksheet.getColumn(25).width = 15;
            addBorders(DiscountvalueCell);


            const CGSTPCell = worksheet.getCell(rowIndex, 26);
            CGSTPCell.value = datadet.CGSTP;
            CGSTPCell.alignment = { horizontal: 'right', vertical: 'middle' };
            worksheet.getColumn(26).width = 15;
            addBorders(CGSTPCell);

            const CGSTValueCell = worksheet.getCell(rowIndex, 27);
            CGSTValueCell.value = datadet.CGSTValue;
            CGSTValueCell.alignment = { horizontal: 'right', vertical: 'middle' };
            worksheet.getColumn(27).width = 15;
            addBorders(CGSTValueCell);

            const SGSTPCell = worksheet.getCell(rowIndex, 28);
            SGSTPCell.value = datadet.SGSTP;
            SGSTPCell.alignment = { horizontal: 'right', vertical: 'middle' };
            worksheet.getColumn(28).width = 15;
            addBorders(SGSTPCell);

            const SGSTValueCell = worksheet.getCell(rowIndex, 29);
            SGSTValueCell.value = datadet.SGSTValue;
            SGSTValueCell.alignment = { horizontal: 'right', vertical: 'middle' };
            worksheet.getColumn(29).width = 15;
            addBorders(SGSTValueCell);

            const IGSTPCell = worksheet.getCell(rowIndex, 30);
            IGSTPCell.value = 0;
            IGSTPCell.alignment = { horizontal: 'right', vertical: 'middle' };
            worksheet.getColumn(30).width = 15;
            addBorders(IGSTPCell);

            const IGSTValueCell = worksheet.getCell(rowIndex, 31);
            IGSTValueCell.value = 0;
            IGSTValueCell.alignment = { horizontal: 'right', vertical: 'middle' };
            worksheet.getColumn(31).width = 15;
            addBorders(IGSTValueCell);

            const TotalvalueCell = worksheet.getCell(rowIndex, 32);
            TotalvalueCell.value = datadet.Totalvalue;
            TotalvalueCell.alignment = { horizontal: 'right', vertical: 'middle' };
            worksheet.getColumn(32).width = 15;
            addBorders(TotalvalueCell);

            const InvoiceamountCell1 = worksheet.getCell(rowIndex, 33);
            InvoiceamountCell1.value = datadet.posroundoff;
            InvoiceamountCell1.alignment = { horizontal: 'right', vertical: 'middle' };
            worksheet.getColumn(33).width = 15;
            addBorders(InvoiceamountCell1);

            const InvoiceamountCell = worksheet.getCell(rowIndex, 34);
            InvoiceamountCell.value = datadet.Invoiceamount;
            InvoiceamountCell.alignment = { horizontal: 'right', vertical: 'middle' };
            worksheet.getColumn(34).width = 15;
            addBorders(InvoiceamountCell);

            
            const Employee_NameCell = worksheet.getCell(rowIndex, 35);
            Employee_NameCell.value = datadet.Employee_Name;
            Employee_NameCell.alignment = { horizontal: 'left', vertical: 'middle' };
            worksheet.getColumn(35).width = 20;
            addBorders(Employee_NameCell);

            const CrdateCell = worksheet.getCell(rowIndex, 36);
            CrdateCell.value = datadet.createdatetime;
            CrdateCell.alignment = { horizontal: 'left', vertical: 'middle' };
            worksheet.getColumn(36).width = 20;
            addBorders(CrdateCell);

            const lastRow = worksheet.lastRow?.number;
            // const sumamount1 = `SUM(V7:V${lastRow})`
            // const sumdiscount2 = `SUM(X7:X${lastRow})`
            // const sumcgst3 = `SUM(Z7:Z${lastRow})`
            // const sumsgst4 = `SUM(AB7:AB${lastRow})`
            // const sumtotal5 = `SUM(AE7:AE${lastRow})`
            // const suminvoice6 = `SUM(AF7:AF${lastRow})`
            // const sumcash7 = `SUM(AG7:AG${lastRow})`
            // const sumreadm8 = `SUM(AH7:AH${lastRow})`
            // const sumreturn9 = `SUM(AI7:AI${lastRow})`
            // const sumcard10 = `SUM(AJ7:AJ${lastRow})`
            // const sumqrcode11 = `SUM(AK7:AK${lastRow})`
            // const sumonline12 = `SUM(AL7:AL${lastRow})`
            // const sumbanktr13 = `SUM(AM7:AM${lastRow})`

            // worksheet.getCell(`Q${Number(lastRow) + 1}`).value = 'Total';

            // worksheet.getCell(`V${Number(lastRow) + 1}`).value = { formula: sumcash7 };
            // worksheet.getCell(`X${Number(lastRow) + 1}`).value = { formula: sumdiscount2 };
            // worksheet.getCell(`Z${Number(lastRow) + 1}`).value = { formula: sumcgst3 };
            // worksheet.getCell(`AB${Number(lastRow) + 1}`).value = { formula: sumsgst4 };
            // worksheet.getCell(`AE${Number(lastRow) + 1}`).value = { formula: sumtotal5 };
            // worksheet.getCell(`AF${Number(lastRow) + 1}`).value = { formula: suminvoice6 };
            // worksheet.getCell(`AG${Number(lastRow) + 1}`).value = { formula: sumcash7 };
            // worksheet.getCell(`AH${Number(lastRow) + 1}`).value = { formula: sumreadm8 };
            // worksheet.getCell(`AI${Number(lastRow) + 1}`).value = { formula: sumreturn9 };
            // worksheet.getCell(`AJ${Number(lastRow) + 1}`).value = { formula: sumcard10 };
            // worksheet.getCell(`AK${Number(lastRow) + 1}`).value = { formula: sumqrcode11 };
            // worksheet.getCell(`AL${Number(lastRow) + 1}`).value = { formula: sumonline12 };
            // worksheet.getCell(`AM${Number(lastRow) + 1}`).value = { formula: sumbanktr13 };




            // const finalScoreCell = worksheet.getCell(rowIndex, 41);
            // finalScoreCell.alignment = { horizontal: 'center', vertical: 'middle' };
            // addBorders(finalScoreCell);
            // worksheet.getColumn(41).width = 15;
        });

        const buffer = await workbook.xlsx.writeBuffer();
        const blob = new Blob([buffer], { type: 'application/octet-stream' });
        saveAs(blob, `Purchase_Register_Report${new Date().toISOString().split('T')[0]}.xlsx`);

    }

    function findGrandTotal(array: Array<any>, key: string) {
        let totalValues = array?.reduce((a, v) => a = a + v[key]!, 0)
        return totalValues ?? 0
    }

    function exportToExcel(excelArr: ExcelSheetData[]) {

        let lastRow = ['Grand Totals', '', '',
            findGrandTotal(excelArr, 'Bill_Value'), '', findGrandTotal(excelArr, 'Taxable_Value'),
            findGrandTotal(excelArr, 'IGST'), findGrandTotal(excelArr, 'CGST'),
            findGrandTotal(excelArr, 'SGST'), findGrandTotal(excelArr, 'CESS')]
        const csvContent = ["Purchase Bill Register", Object.keys(excelArr[0]).join(','), ...excelArr.map(obj => Object.values(obj).join(',')), lastRow].join('\n');

        const blob = new Blob([csvContent], { type: "data:text/csv;charset=utf-8;" });

        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        const today = moment();
        link.download = `Purchase Bill Register ${today.format('DD-MM-YYYY')}.csv`;

        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }

    function calcSalesTotal(purchaseBillReg: any[], type: string) {
        if (type == 'bill_Amount') {
            const totalSaleQty = purchaseBillReg?.reduce((acc, obj: any) => acc + (obj.bill_Amount ?? 0), 0)
            return totalSaleQty;
        } else if (type == 'taxable_Value') {
            const totalSaleValue = purchaseBillReg?.reduce((acc, obj: any) => acc + (obj.taxable_Value ?? 0), 0)
            return totalSaleValue;
        } else if (type == 'igsT_AMT') {
            const totalSaleValue = purchaseBillReg?.reduce((acc, obj: any) => acc + (obj.igsT_AMT ?? 0), 0)
            return totalSaleValue;
        } else if (type == 'cgsT_AMT') {
            const totalSaleValue = purchaseBillReg?.reduce((acc, obj: any) => acc + (obj.cgsT_AMT ?? 0), 0)
            return totalSaleValue;
        } else if (type == 'sgsT_AMT') {
            const totalSaleValue = purchaseBillReg?.reduce((acc, obj: any) => acc + (obj.sgsT_AMT ?? 0), 0)
            return totalSaleValue;
        } else if (type == 'cess_AMT') {
            const totalSaleValue = purchaseBillReg?.reduce((acc, obj: any) => acc + (obj.cess_AMT ?? 0), 0)
            return totalSaleValue;
        }
    }

    async function fetchOutletStockSummary() {
        handleGenerateStock()
        setLoading(false)
    }

    const isCCurrenPage = useRef(true)

    useEffect(() => {
        if (isCCurrenPage.current) {
            fetchOutletStockSummary()
            isCCurrenPage.current = false
        }
        return () => { }
    }, []);

    const [totalPages, setTotalPage] = useState(1);
    const [currentPageData, setCurrentPageData] = useState<any[]>([])

    useEffect(() => {
        if (outletStockSummaries?.length) {
            goToPage(1)
        } else {
            setCurrentPageData(outletStockSummaries)
        }
    }, [outletStockSummaries])

    const goToPage = (value: number) => {
        const currentPageList = currentPageDatas(outletStockSummaries, value, 10)
        setTotalPage(currentPageList?.totalPages)
        setCurrentPageData(currentPageList?.currentPageData)
    };

    function handleFindDate(date) {
        if (outletSummaryFields?.fromDate && outletSummaryFields?.toDate) {
            const yesterday = new Date(outletSummaryFields?.fromDate);
            yesterday.setDate(yesterday.getDate() - 1)
            if (new Date(date) >= yesterday && new Date(date) < new Date(outletSummaryFields?.toDate)) {
                return true;
            } else {
                return false
            }
        } else if (outletSummaryFields?.fromDate) {
            if (new Date(date) > new Date(outletSummaryFields?.fromDate)) {
                return true
            } else {
                return false
            }
        } else if (outletSummaryFields?.toDate) {
            if (new Date(date) < new Date(outletSummaryFields?.toDate)) {
                return true
            } else {
                return false
            }
        }
    }


    return (
        <>
            {loading ?
                <div className='SpinnerBox'>
                    <Spinner animation="border" role="status"></Spinner>
                </div> :
                <div className='' style={{ display: 'flex', alignItems: "center", justifyContent: 'center', width: '100%' }}>
                    <div className='' style={{ width: '80%' }}>
                        <div className='vertical-space-20'></div>
                        <div className='outletInputField inputFormBox LgInputField'>

                            <div className='hrBox'>
                                <h3>Purchase Bill Register</h3>
                            </div>

                            <div className='inputBoxLists'>
                                <div className='ItemInwardInputBox'>
                                    <div className="row">
                                        <div className="col-sm-2">
                                            <div className='inputBox'>
                                                <label>From Date <span>*</span></label>
                                                <input type="date" style={{ width: "80%" }}
                                                    name="startDate" onChange={handleInputChange} value={salesRegisterRequest?.startDate}
                                                    placeholder="DD-MMM-YYYY" data-date="" data-date-format="DD MM YYYY"></input>
                                            </div>
                                            {errors.fromDate && <p style={{ color: 'red' }}>{errors.fromDate}</p>}
                                        </div>
                                        <div className="col-sm-2">
                                            <div className='inputBox'>
                                                <label>To Date <span>*</span></label>
                                                <input type="date" style={{ width: "80%" }}
                                                    name="endDate" onChange={handleInputChange} value={salesRegisterRequest?.endDate}
                                                    placeholder="DD-MMM-YYYY" data-date="" data-date-format="DD MM YYYY"></input>
                                            </div>
                                            {errors.toDate && <p style={{ color: 'red' }}>{errors.toDate}</p>}
                                        </div>
                                        <div className="col-sm-4"></div>
                                        <div className="col-sm-2" style={{ marginTop: '24px', paddingLeft: "65px" }}>
                                            <button className='secondaryBtn' type='submit' onClick={handleGenerateStock}>Generate</button>
                                        </div>
                                        <div className="col-sm-2" style={{ marginTop: '24px' }}>
                                            <button className='secondaryBtn' type='submit' onClick={() => createExportExcelObj()}>Excel</button>
                                        </div>
                                    </div>
                                </div>
                                <div className='vertical-space-20'></div>
                                <div className='row'>
                                    <div className='col-sm-5'>
                                        <div className='btnBox'>
                                            <button className='totalrecordBtn' type='submit' style={{ width: '20ch' }}>{outletStockSummaries?.length} Records</button>
                                        </div>
                                    </div>
                                    <div className=' col-sm-7' >
                                        <Stack spacing={2}>
                                            <Pagination count={totalPages} onChange={(ev, value) => goToPage(value)} color='primary' />
                                        </Stack>
                                    </div>
                                </div>
                                <div className='vertical-space-20'></div>
                                <div className='tableListDetails'>
                                    <table className="table table-striped">
                                        <thead>
                                            <tr>
                                                <th scope='col'>Supplier Name</th>
                                                <th scope="col">Bill No</th>
                                                <th scope="col">Bill Date</th>
                                                <th scope="col">Bill Value</th>
                                                <th scope='col'>Tax %</th>
                                                <th scope="col">Taxable Value</th>
                                                <th scope="col">IGST</th>
                                                <th scope="col">CGST</th>
                                                <th scope="col">SGST</th>
                                                <th scope="col">CESS</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {((outletSummaryFields?.fromDate && outletSummaryFields?.toDate) ? filteredData : currentPageData)?.length > 0 &&
                                                ((outletSummaryFields?.fromDate && outletSummaryFields?.toDate) ? filteredData : currentPageData)?.map((stock, index) => {
                                                    return (
                                                        <tr key={index}>
                                                            <td scope="col" className='capitalize'>{stock?.supplierName}</td>
                                                            <td scope="col">{stock?.bill_no}</td>
                                                            <td scope='col'>{moment(stock?.bill_date)?.format('DD-MMM-YY')}</td>
                                                            <td scope="col">{stock?.bill_Amount}</td>
                                                            <td scope='col'>{stock?.tax_per} %</td>
                                                            <td scope="col">{stock?.taxable_Value}</td>
                                                            <td scope="col">{stock?.igsT_AMT}</td>
                                                            <td scope="col">{stock?.cgsT_AMT}</td>
                                                            <td scope="col">{stock?.sgsT_AMT}</td>
                                                            <td scope="col">{stock?.cess_AMT}</td>
                                                        </tr>
                                                    )
                                                })}
                                            <tr>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td>{calcSalesTotal((outletSummaryFields?.fromDate && outletSummaryFields?.toDate) ? filteredData : outletStockSummaries, 'bill_Amount')}</td>
                                                <td></td>
                                                <td>{calcSalesTotal((outletSummaryFields?.fromDate && outletSummaryFields?.toDate) ? filteredData : outletStockSummaries, 'taxable_Value')}</td>
                                                <td>{calcSalesTotal((outletSummaryFields?.fromDate && outletSummaryFields?.toDate) ? filteredData : outletStockSummaries, 'igsT_AMT')}</td>
                                                <td>{calcSalesTotal((outletSummaryFields?.fromDate && outletSummaryFields?.toDate) ? filteredData : outletStockSummaries, 'cgsT_AMT')}</td>
                                                <td>{calcSalesTotal((outletSummaryFields?.fromDate && outletSummaryFields?.toDate) ? filteredData : outletStockSummaries, 'sgsT_AMT')}</td>
                                                <td>{calcSalesTotal((outletSummaryFields?.fromDate && outletSummaryFields?.toDate) ? filteredData : outletStockSummaries, 'cess_AMT')}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </>
    )
})

export default PurchaseBillRegister;