import React, { useState, useEffect, ChangeEvent, useRef } from "react";
import { observer } from "mobx-react";
import { TextField, Autocomplete } from "@mui/material";
import "bootstrap/dist/css/bootstrap.min.css";
import supplierMasterDetails, { IsupplierMaster } from "./model/index";
import useStores from "../../hooks";
import { useNavigate, useParams } from 'react-router-dom';
import supplierValidation from "./validation";
import { Modal, Spinner, Image, Button } from 'react-bootstrap';
import { faL } from "@fortawesome/free-solid-svg-icons";
import ProgressBar from "../../common/shared/progressbar";
import { Country, State, City } from 'country-state-city';
import { error } from "console";
import Cookies from 'js-cookie';


const supplierMaster = observer((): JSX.Element => {
  const [supplierMasterDetails, setSupplier] = useState<IsupplierMaster>({});
  const { supplierMasterStore, commonStore, brandStore } = useStores();
  const [errors, setErrors] = useState<any>({});
  const navigate = useNavigate();
  const [isSuccessModal, setSuccessModal] = useState(false);
  const [isFailureModal, setFailureModal] = useState(false);
  const [isLoading, setLoading] = useState(false);

  const [canAdd, setCanAdd] = useState(false);
  const [canEdit, setCanEdit] = useState(false);
  // const [canView, setCanView] = useState(false);
  // const [canPrint, setCanPrint] = useState(false);  
  // const [canUpdate, setCanUpdate] = useState(false); 
  const [isLoadModal, setLoadModal] = useState(false); 
  const [filterCites, setFilterCites] = useState<any[]>([])

  const handleAccessModalClose = () => {
    navigate('/Admin/Home');
  }

  const handleChangeview = () => {
    navigate('/Admin/SupplierMaster/list');
  }

  const { supplierId } = useParams();

  const handleFailureModalClose = () => setFailureModal(false);
  const saveSupplierMaster = async (e) => {

    e.preventDefault();
    let error: any = {};
    error = supplierValidation(supplierMasterDetails);
    setErrors(error);     
    

    if (Object.keys(error).length === 0) {
      setLoading(true);

      let status: string = '';
      if (supplierId !== undefined) {
        status = await supplierMasterStore.updateSupplier(supplierMasterDetails);

        if (status === "Success") {
          setLoading(false);
          setSuccessModal(true);
        }
        else {
          setLoading(false);
          setFailureModal(true);
        }
      }
      else {
        const status = await supplierMasterStore.saveSupplierMaster(supplierMasterDetails);
        if (status === "Success") {
          setLoading(false);
          setSuccessModal(true);
        }
        else {
          setLoading(false);
          setFailureModal(true);
        }
      }
    }
  };


  const handleSuccessModalClose = () => {
    navigate('/Admin/SupplierMaster/list');
  }

  const handleInputchnage = (e) => {
    const { name, value } = e.target;
    if (name == "creditdays") {
      setSupplier({ ...supplierMasterDetails, [name]: Number(value) })
    } else {
      setSupplier({ ...supplierMasterDetails, [name]: value });
    }
  };

  async function mapItemData(SupplierDels) {

    supplierMasterDetails.supplierId = SupplierDels.supplierId
    supplierMasterDetails.area = SupplierDels.area
    supplierMasterDetails.city = SupplierDels.city
    supplierMasterDetails.doorNo = SupplierDels.doorNo
    supplierMasterDetails.gstIn = SupplierDels.gstIn
    supplierMasterDetails.mobileNo = SupplierDels.mobileNo
    supplierMasterDetails.phoneNo = SupplierDels.phoneNo
    supplierMasterDetails.pinCode = SupplierDels.pinCode
    supplierMasterDetails.supplierGroup = SupplierDels.supplierGroup
    supplierMasterDetails.supplierName = SupplierDels.supplierName
    supplierMasterDetails.supplierType = SupplierDels.supplierType
    supplierMasterDetails.state = SupplierDels.state
    supplierMasterDetails.fssaino = SupplierDels.fssaino
    supplierMasterDetails.creditdays = SupplierDels.creditdays
    setSupplier(supplierMasterDetails);
  }

  async function fetchPreLoadingData() {
    setLoading(false);
    if (supplierId !== undefined) {
      await supplierMasterStore.SupplierDetails(parseInt(supplierId!));
      mapItemData(supplierMasterStore.supplierDetls);
    
    } else {
      const data = await brandStore.getEntryNo('suppliermaster')
      if (data !== undefined) {
        supplierMasterDetails.supplierId = data.entryNo + 1;
        setSupplier(supplierMasterDetails);
      }
    }
    await commonStore.loadCities();
    await commonStore.loadStates();
    const sateData = await commonStore.loadStates();
   
  }

  useEffect(() => {
   
  }, [filterCites]);

const getCityCountry = (e: any, value: any) => {
  if (value != null) {
    const countries = Country.getAllCountries().filter(x => x.isoCode === value.countryCode);
    supplierMasterDetails.country = countries[0].name;
    setSupplier({ ...supplierMasterDetails, ['country']: countries[0].name });

    setSupplier({ ...supplierMasterDetails, ['state']: value.name });
    setSupplier({ ...supplierMasterDetails, ['city']: value.name });
  }
  else {
    supplierMasterDetails.country = '';
    supplierMasterDetails.state = '';
  }
}

  const isCurrenPage = useRef(true)

  // useEffect(() => {
  //   if (isCurrenPage.current) {
  //     fetchPreLoadingData();
  //     isCurrenPage.current = false;
  //   }
  //   return () => { }
  // }, [])

  const empid = Number(Cookies.get('userId'));      
  const formName = 'Supplier_master';

  useEffect(() => {   
    const fetchRights = async () => {     
      try {          
            const data = await supplierMasterStore.fetchRights(empid,formName);
            
            if (Array.isArray(data) && data.length > 0) {
              const specificFieldValue = data[0].sts; 
              const rightsValue = data[0].rights;                     
              const lettersArray  = rightsValue.split('');
              if (specificFieldValue === 1) {  
                if (isCurrenPage.current) {
                  fetchPreLoadingData();
                  isCurrenPage.current = false    
                  if (lettersArray.includes('a')) {
                    setCanAdd(true);  
                  } else {
                    navigate('/Admin/SupplierMaster/list');
                  }               
                  if (lettersArray.includes('e')) {
                    setCanEdit(true); 
                  }
                  // if (lettersArray.includes('v')) {
                  //   setCanView(true); 
                  // }     
                  // if (lettersArray.includes('p')) {
                  //   setCanPrint(true); 
                  // }       
                  // if (lettersArray.includes('u')) {
                  //   setCanUpdate(true); 
                  // }                     
                }         
            }else if(specificFieldValue === 0){   
              setLoadModal(true)         
            }     
          }
    } catch (error) {
      console.error("Error fetching rights:", error);
  }
};
  fetchRights(); 
}, [empid, formName]);

  const getStateCountry = (e: any, value: any) => {
debugger
    if (value != null) {
      const countries = Country.getAllCountries().filter(x => x.isoCode === value.countryCode);
      supplierMasterDetails.country = countries[0].name;
      setSupplier({ ...supplierMasterDetails, ['country']: countries[0].name });
      const states = State.getAllStates().filter(x => x.countryCode === value.countryCode && x.isoCode === value.stateCode);
      supplierMasterDetails.state = states[0]?.name;

      const cites = City.getAllCities().filter(x => x.countryCode === value.countryCode && x.stateCode === value.isoCode);
      setFilterCites([...cites]);
      console.log(cites,'cites'); 
      console.log(filterCites,'filterCites');

      setSupplier({ ...supplierMasterDetails, ['state']: states[0]?.name });
      setSupplier({ ...supplierMasterDetails, ['city']: value.name });
    }
    else {
      supplierMasterDetails.country = '';
      supplierMasterDetails.state = '';
    }
  }


  if (isLoadModal) {
    return (
      <div className="container">
        {isLoadModal && <h1>Access Denied</h1>}
        <Modal show={isLoadModal} onHide={handleAccessModalClose} className='SubmitModal'>
          <Modal.Body>
            <div className='Details Success'>
              <div className='imgBox'>
                <Image src={require('../../../gsmecom/images/warning.png')} />
              </div>
              <h4>Access Denied</h4>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleAccessModalClose}>Ok</Button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
  else
  {
    return (
      <>
        {
          isLoading ? <ProgressBar /> :
            <>
              <div className="vertical-space-20"></div>
              <div className="outletInputField inputFormBox">
                <div className="hrBox">
                  <h3>Supplier Master</h3>
                </div>
                <div className="inputBoxLists">
                  <div className="row">
                    <div className="col-sm-6">
                      <div className='inputBox'>
                        <label>Entry No <span>*</span></label>
                        <input type="text" className=''
                          name='supplierId' disabled
                          value={supplierMasterDetails.supplierId}
                          onChange={handleInputchnage} placeholder='Entry No..'></input>
                      </div>
                      <div className='vertical-space-10'></div>
                    </div>
                    <div className="col-sm-12">
                      <div className="inputBox">
                        <label>
                          Supplier Name <span>*</span>
                        </label>
                        <input type="text" className="full-width" name="supplierName" placeholder="Supplier Name.."
                          onChange={handleInputchnage} value={supplierMasterDetails.supplierName}
                        ></input>
                        {errors.supplierName && <p style={{ color: 'red' }}>{errors.supplierName}</p>}
                      </div>
                      <div className="vertical-space-10"></div>
                    </div>
                    <div className="col-sm-6">
                      <div className="inputBox">
                        <label>
                          Supplier Type <span>*</span>
                        </label>
                        <select
                          className="form-select"
                          onChange={handleInputchnage}
                          name="supplierType"
                          value={supplierMasterDetails.supplierType}
                        >
                          <option value="" selected>
                            None
                          </option>
                          <option value="Individual">Individual</option>
                          <option value="Company">Company</option>
                        </select>
                        {errors.supplierType && <p style={{ color: 'red' }}>{errors.supplierType}</p>}
                      </div>
                      <div className="vertical-space-10"></div>
                    </div>
                    <div className="col-sm-6">
                      <div className="inputBox">
                        <label>
                          Supplier Group <span>*</span>
                        </label>
                        <select
                          className="form-select"
                          onChange={handleInputchnage}
                          name="supplierGroup"
                          value={supplierMasterDetails.supplierGroup}
                        >
                          <option value="" selected>
                            None
                          </option>
                          <option value="Vegetables">Vegetables</option>
                          <option value="Cosmetics">Cosmetics</option>
                          <option value="Grocery">Grocery</option>
                          <option value="General">General</option>
                        </select>
                        {errors.supplierGroup && <p style={{ color: 'red' }}>{errors.supplierGroup}</p>}
                      </div>
                      <div className="vertical-space-10"></div>
                    </div>
                    <div className="col-sm-6">
                      <div className="inputBox">
                        <label>
                          GSTIN
                        </label>
                        <input
                          type="text"
                          className="full-width"
                          onChange={handleInputchnage}
                          name="gstIn"
                          value={supplierMasterDetails.gstIn = supplierMasterDetails.gstIn ?? ""}
                          placeholder="Enter GSTIN.."
                        ></input>
                        {errors.gstIn && <p style={{ color: 'red' }}>{errors.gstIn}</p>}
                      </div>
                      <div className="vertical-space-10"></div>
                    </div>
                    <div className="col-sm-6">
                      <div className="inputBox">
                        <label>
                          FSSAI No
                        </label>
                        <input
                          type="number"
                          className="full-width"
                          onChange={handleInputchnage}
                          name="fssaino"
                          value={supplierMasterDetails.fssaino}
                          placeholder="Enter FSSAI No.."
                        ></input>
                      </div>
                      <div className="vertical-space-10"></div>
                    </div>
                    <div className="col-sm-6">
                      <div className="inputBox">
                        <label>
                          Phone No
                        </label>
                        <input
                          type="number"
                          className="full-width"
                          onChange={handleInputchnage}
                          name="phoneNo"
                          maxLength={15}
                          value={supplierMasterDetails.phoneNo = supplierMasterDetails.phoneNo ?? ""}
                          placeholder="Enter Phone No.."
                        ></input>
                        {errors.phoneNo && <p style={{ color: 'red' }}>{errors.phoneNo}</p>}
                      </div>
                      <div className="vertical-space-10"></div>
                    </div>
                    <div className="col-sm-6">
                      <div className="inputBox">
                        <label>
                          Mobile No <span>*</span>
                        </label>
                        <input
                          type="number"
                          className="full-width"
                          onChange={handleInputchnage}
                          name="mobileNo"
                          value={supplierMasterDetails.mobileNo}
                          placeholder="Enter Mobile No.."
                          maxLength={10}
                        ></input>
                        {errors.mobileNo && <p style={{ color: 'red' }}>{errors.mobileNo}</p>}
                      </div>
                      <div className="vertical-space-10"></div>
                    </div>
                    <div className='col-md-6'>
               <div className='inputBox'>
                  <label>State </label>
                  <Autocomplete size="small"
                    disablePortal
                    id="combo-box-demo"
                    options={commonStore?.loadState}
                    getOptionLabel={(option: any) => option?.name}
                     onChange={getStateCountry}
                    renderInput={(params: any) =>
                      <TextField  {...params} style={{ width: '21ch' }}
                        id="outlined-size-small"
                        color='info'
                        size="small"
                        type="text"
                        placeholder='Select State'
                        name='State'
                      />}
                  />
                  {errors.city && <p style={{ color: 'red' }}>{errors.city}</p>}
                </div>
                
              </div>
              <div className='col-md-6'>
                <div className='inputBox'>
                  <label>Country </label>
                  <input type="text" className='fullInput' style={{ width: "100%" }}
                    value={supplierMasterDetails.country} disabled
                     onChange={handleInputchnage}
                    name="Country"
                    placeholder='Enter Country..'></input>
                  <div className='vertical-space-10'></div>
                </div>
              </div>
                    {/* <div className="col-md-6">
                      <div className="inputBox">
                        <label>State </label>
                        <input
                          style={{ width: "100%" }}
                          onChange={handleInputchnage}
                          name="State"
                          value={supplierMasterDetails.state} disabled
                          placeholder="Enter State.."
                        ></input>
                      </div>
                      <div className="vertical-space-10"></div>
                    </div>
                    <div className="col-md-6">
                      <div className="inputBox">
                        <label>City <span>*</span></label>
                        <Autocomplete size="small"
                          disablePortal
                          id="combo-box-demo"
                          options={commonStore.loadCity}
                          getOptionLabel={(option: any) => option.name}
                          onChange={getStateCountry}
                          value={commonStore.loadCity.find((option) => option.name === supplierMasterDetails.city)}
                          renderInput={(params: any) =>
                            <TextField  {...params} style={{ width: '26ch' }}
                              id="outlined-size-small"
                              color='info'
                              size="small"
                              type="text"
                              placeholder='Select City'
                              name='city'
                            />}
                        />
                        {errors.city && <p style={{ color: 'red' }}>{errors.city}</p>}
                      </div>
                      <div className="vertical-space-10"></div>
                    </div> */}
                   
                    <div className="col-sm-12">
                      <div className="inputBox">
                        <label>Door No / Street </label>
                        <input
                          type="text"
                          className="fullInput"
                          style={{ width: "100%" }}
                          onChange={handleInputchnage}
                          name="doorNo"
                          value={supplierMasterDetails.doorNo}
                          placeholder="Enter Address.."
                        ></input>
                      </div>
                      <div className="vertical-space-10"></div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6 col-sm-8">
                      <div className="inputBox">
                        <label>Area</label>
                        <input
                          type="text"
                          className="fullInput"
                          style={{ width: "100%" }}
                          onChange={handleInputchnage}
                          name="area"
                          value={supplierMasterDetails.area}
                          placeholder="Enter Area.."
                        ></input>
                      </div>
                      <div className="vertical-space-10"></div>
                    </div>
                 
                    <div className='col-md-6'>
                <div className='inputBox'>
                  <label>City </label>
                  <Autocomplete size="small"
                    disablePortal
                    id="combo-box-demo"
                    options={filterCites ? filterCites : commonStore.loadCity}
                    getOptionLabel={(option: any) => option.name}
                    onChange={getCityCountry}
                    renderInput={(params: any) =>
                      <TextField  {...params} style={{ width: '21ch' }}
                        id="outlined-size-small"
                        color='info'
                        size="small"
                        type="text"
                        placeholder='Select City'
                        name='city'
                      />}
                  />
                  {errors.city && <p style={{ color: 'red' }}>{errors.city}</p>}
                </div>
                <div className='vertical-space-10'></div>
              </div>

                    <div className="col-md-6">
                      <div className="inputBox">
                        <label>PIN Code <span>*</span> </label>
                        <input
                          style={{ width: "100%" }}
                          onChange={handleInputchnage}
                          name="pinCode"
                          type="number"
                          value={supplierMasterDetails.pinCode}
                          placeholder="Enter Pincode.."
                        ></input>
                        {errors.pinCode && <p style={{ color: 'red' }}>{errors.pinCode}</p>}
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="inputBox">
                        <label>Credit Days </label>
                        <input
                          style={{ width: "100%" }}
                          onChange={handleInputchnage}
                          name="creditdays"
                          type="number"
                          value={supplierMasterDetails.creditdays}
                          placeholder="Enter Credit Days.."
                        ></input>
                        {errors.creditdays && <p style={{ color: 'red' }}>{errors.creditdays}</p>}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="btnBox">
                  <button className="secondaryBtn" onClick={handleChangeview}>List</button>
                  {/* <button className="dfBtn" type="submit" onClick={saveSupplierMaster}>
                    Submit
                  </button> */}
                   {supplierId === undefined && (
                    <button className="dfBtn" type="submit" onClick={saveSupplierMaster} disabled={!canAdd} 
                    title={!canAdd ? 'You do not have permission to Add.' : 'Click to Add'} 
                    >
                      Submit
                  </button>
                 )}        
                  {supplierId !== undefined && (     
                    <button className="dfBtn" type="submit" onClick={saveSupplierMaster} disabled={!canEdit} 
                    title={!canEdit ? 'You do not have permission to Update.' : 'Click to Update'} 
                    >
                      Update
                  </button>  
                  )}            
                </div>
                <Modal show={isSuccessModal} onHide={handleSuccessModalClose} className='SubmitModal'>
                  <Modal.Body>
                    <div className='Details Success'>
                      <div className='imgBox'>
                        <Image src={require('../../../../image/checked.png')} />
                      </div>
  
                      <h4>Succesfully Submitted</h4>
                    </div>
                  </Modal.Body>
                  <Modal.Footer>
                    <Button variant="secondary" onClick={handleSuccessModalClose}>
                      Ok
                    </Button>
                  </Modal.Footer>
                </Modal>
                <Modal show={isFailureModal} onHide={handleFailureModalClose} className='SubmitModal'>
                  <Modal.Body>
                    <div className='Details Success'>
  
                      <div className='imgBox'>
                        <Image src={require('../../../../image/warning.png')} />
                      </div>
                      <h4>Failed</h4>
                    </div>
                  </Modal.Body>
                  <Modal.Footer>
                    <Button variant="secondary" onClick={handleFailureModalClose}>
                      Ok
                    </Button>
                  </Modal.Footer>
                </Modal>
              </div>
            </>
        }
  
      </>
    );
  }
});

export default supplierMaster;
