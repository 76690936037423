import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAdd, faEdit, faFileEdit, faL, faPenToSquare, faSearch, faTrashAlt, faUpload } from '@fortawesome/free-solid-svg-icons'
import 'bootstrap/dist/css/bootstrap.min.css';
import { observer } from 'mobx-react';
import React, { useEffect, useRef, useState } from 'react';
import useStores from '../../hooks';
import Spinner from 'react-bootstrap/Spinner';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import { excelKeyupdate, IExcelSheetData, IIExceluploadModal, IMemberSearchoptions } from './model';
import { Button, Image, Modal } from 'react-bootstrap';
import SelectSearchCriteria from '../../common/shared/selectSearchCriteria';
import { Pagination, Stack } from '@mui/material';
import { currentPageDatas } from '../../common/shared/utils';
import { convertExcelToArrayOfObjects } from '../../common/shared/convertExcToarrObj/covertExctoArrObj';

export interface ExcelSheetData {
  Member_Name?: string;
  Mobile_No?: number;
  PAN_no?: number;
  Address?: string;
  Cust_Type?: string;
  Date_of_Regn?: string;
  DOB?: string;
  Cust_Category?: string;
  Gender?: string;
  Anniversary_Date?: string;
  Aadhar?: number;
  Email?: string;
}


interface ExcelObject {
  [key: string]: any;
}

const NewMemberList = observer((): JSX.Element => {
  const { memberStore, userCreationStore } = useStores();
  const [userRights, setRightsObj] = useState<any>({})
  const { getNewmember, newmemberList, importExportFormSave } = memberStore;
  const [isLoading, setLoading] = useState(true);
  const navigate = useNavigate();
  const [isSuccessModal, setSuccessModal] = useState(false);
  const [isFailureModal, setFailureModal] = useState(false);

  const [isSearch, setSearch] = useState(false);
  const [filteredData, setFilteredData] = useState<any[]>([])
  console.log(filteredData,'filteredData');
  
  const [isExcel, setExcel] = useState(false)
  const [data, setData] = useState<ExcelObject[]>([]);


  const handleFileChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      try {
        const result = await convertExcelToArrayOfObjects(file);
        setData(result);
      } catch (error) {
        console.error('Error reading Excel file:', error);
      }
    }
  };

  const editNewMember = (memberId: string) => {
    console.log(memberId, "jhfjjfjfjmemberId");
   // memberStore.getNewmemberDetails(memberId);
   navigate('/Admin/Member/' + memberId);
  // navigate('/Admin/Member');
  }

  const deleteMemberDetail = async (memberId) => {
    setLoading(true);
    const status = await memberStore.deleteNewmember(memberId);
    if (status === 'Success') {
      fetchPreLoadngData();
      setLoading(false);
      setSuccessModal(true);
    }
    else {
      setLoading(false);
      setFailureModal(true);
    }
  }

  const handleFailureModalClose = () => setFailureModal(false);
  const handleSuccessModalClose = () => {
    setSuccessModal(false);
    //fetchPreLoadngData();
  }

  const handleCreate = () => {
    navigate('/Admin/Member');
  }

  async function fetchPreLoadngData() {
    // 
    setLoading(false);
    const [userRights] = await Promise.all([userCreationStore?.getUserRight('New_member_registration'), getNewmember()])
    console.log(userRights, 'userRights>>')
    if (userRights) {
      setRightsObj({ ...userRights })
    };
  }

  function excelNewSheet() {
    let excelArr = new Array<IExcelSheetData>();
    let intitalObj = new IExcelSheetData();
    excelArr.push(intitalObj)
    const csvContent = [Object.keys(excelArr[0]).join(',')].join('\n');

    const blob = new Blob([csvContent], { type: "data:text/csv;charset=utf-8;" });

    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    const today = moment();
    link.download = `Member List ${today.format('DD-MM-YYYY')}.csv`;

    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  function createExportExcelObj(membersDetails: any[]) {
    let excelArr: ExcelSheetData[] = membersDetails?.map((member) => {
      let address = (member?.doorNo + " " + member.area + " " + member.city + " " + member.state)
      const excelObj: ExcelSheetData = {
        Member_Name: member?.memberName,
        Mobile_No: member?.mobileNo,
        PAN_no: member?.panNo,
        Address: address,
        Cust_Type: member?.customerType,
        Date_of_Regn: member?.dateofRegn,
        DOB: moment(member?.doB).format("DD-MMM-YYYY"),
        Cust_Category: member?.customerCategory,
        Gender: member?.gender,
        Anniversary_Date: member?.anniversaryDate,
        Aadhar: member?.aadharNumber,
        Email: member?.emailId,
      }
      return excelObj;
    })
    exportToExcel(excelArr)
  }

  function exportToExcel(excelArr: ExcelSheetData[]) {
    const csvContent = ["Member List", Object.keys(excelArr[0]).join(','), ...excelArr.map(obj => Object.values(obj).join(','))].join('\n');

    const blob = new Blob([csvContent], { type: "data:text/csv;charset=utf-8;" });

    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    const today = moment();
    link.download = `Member List ${today.format('DD-MM-YYYY')}.csv`;

    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  async function handleUploadExcel() {
    let excleMaster: IIExceluploadModal = {};
    let updatedArr = excelKeyupdate(data);
    excleMaster.impsno = 1;
    excleMaster.member_import = updatedArr;
    const status = await importExportFormSave(excleMaster)
    if (status === "Success") {
      setLoading(false);
      setSuccessModal(true)
    } else {
      setLoading(false);
      setFailureModal(true);
    }
  }

  const isCurrenPage = useRef(true)

  useEffect(() => {
    if (isCurrenPage.current) {
      fetchPreLoadngData();
      isCurrenPage.current = false
    }
    return () => { }
  }, []);

  const [totalPages, setTotalPage] = useState(1);
  const [currentPageData, setCurrentPageData] = useState<any[]>([])

  useEffect(() => {
    if (newmemberList && newmemberList?.length) {
      goToPage(1)
    } else {
      setCurrentPageData(newmemberList)
    }
  }, [newmemberList])

  const goToPage = (value: number) => {
    const currentPageList = currentPageDatas(newmemberList, value, 10)
    setTotalPage(currentPageList?.totalPages)
    setCurrentPageData(currentPageList?.currentPageData)
  };
  return (
    <>
      {
        isLoading ?
          <div className='SpinnerBox'>
            <Spinner animation="border" role="status">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          </div> :
          <>
            <div className='vertical-space-20'></div>
            <SelectSearchCriteria searchEnable={(bool) => setSearch(bool)} searchArray={newmemberList}
              searchTitles={[{ key: "memberName", value: "Member Name/Business Name" }, { key: "city", value: "City" }, { key: "mobileNo", value: "Mobile No" }]}
              emitSearchArray={(filterArray: any) => setFilteredData(filterArray)} buttonChildren={<>
                <button className="searchclearBtn" onClick={() => { createExportExcelObj(newmemberList) }}>Excel</button>
                <button className='searchclearBtn' onClick={excelNewSheet}>Export</button>
                <button className='searchclearBtn' onClick={() => setExcel(true)}>Import</button>
              </>} />
            <div className='vertical-space-20'></div>
            <div className='ItemInwardInputBox' style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
              <div className='btnBox'>
                <button className='totalrecordBtn' type='submit' style={{ width: '20ch' }}>{newmemberList?.length} Records</button>
              </div>
              <Stack spacing={2} style={{ padding: '0px 24px 20px' }}>
                <Pagination count={totalPages} onChange={(ev, value) => goToPage(value)} color='primary' />
              </Stack>
            </div>
            <div className='vertical-space-20'></div>
            <div className='tableListDetails'>
              <table className="table table-striped">
                <thead>
                  <tr>
                    <th scope="col">MemberName</th>
                    <th scope="col">MobileNo</th>
                    <th scope="col">PAN-No</th>
                    <th scope="col">Address</th>
                    {/* <th scope="col">Cust Type</th> */}
                    <th scope="col">DateOfRegn</th>
                    <th scope="col">DOB</th>
                    <th scope="col">Membership Plan</th>
                    <th scope="col">Gender</th>
                    <th scope="col">Anniversary Date</th>
                    <th scope="col">Aadhar</th>
                    <th scope="col">Email</th>
                    <th scope="col">Create</th>
                    <th scope="col">Update</th>
                    <th scope="col">Delete</th>

                  </tr>
                </thead>
                <tbody>
                  {(isSearch ? filteredData : currentPageData)?.length > 0 ?
                    (isSearch ? filteredData : currentPageData)?.map((val, key) => {
                      return (
                        <tr key={key}>
                          <td>{val.memberName}</td>
                          <td>{val.mobileNo}</td>
                          <td>{val.panNo}</td>
                          <td>{val.doorNo + ',' + val.area + ',' + val.city + ',' + val.state}</td>
                          {/* <td>{val.customerType}</td> */}
                          <td>{moment(val?.dateofRegn).format('DD-MMM-YYYY')}</td>
                          <td>{moment(val.dob).format('DD-MMM-YYYY')}</td>
                          <td>{val.customerType}</td>
                          <td>{val.gender}</td>
                          <td>{val.anniversaryDate && moment(val.anniversaryDate).format('DD-MMM-YYYY')}</td>
                          <td>{val.aadharNumber}</td>
                          <td>{val.emailid}</td>
                          <td><button disabled={!userRights?.add} className='Add'><FontAwesomeIcon icon={faAdd} onClick={handleCreate} /></button></td>
                          <td><button disabled={!userRights?.edit} className='Edit'><FontAwesomeIcon icon={faPenToSquare} onClick={() => editNewMember(val?.memberId)} /></button></td>
                          <td><button disabled={!userRights?.delete} className='delete'><FontAwesomeIcon icon={faTrashAlt} onClick={() => deleteMemberDetail(val.memberId)} /></button></td>
                        </tr>
                      )
                    }
                    ) : <></>}
                </tbody>
              </table>

              <Modal show={isExcel} onHide={() => { setExcel(false); setData([]) }} className='PriceHistoryModel'>
                <Modal.Header closeButton>
                  <Modal.Title>Excel Import </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <div className='imgBox'>
                    <div className='col-sm-8'>
                      <div className='inputBox'>
                        <label>Upload Excel <span>*</span></label>
                        <input type="file" accept=".xlsx, .xls, .csv" onChange={handleFileChange} />
                      </div>
                    </div>
                  </div>
                </Modal.Body>
                <Modal.Footer>
                  <Button variant="secondary" onClick={handleUploadExcel}>
                    Upload
                  </Button>
                </Modal.Footer>
              </Modal>
              <Modal show={isSuccessModal} onHide={handleSuccessModalClose} className='SubmitModal'>
                <Modal.Body>
                  <div className='Details Success'>
                    <div className='imgBox'>
                      <Image src={require('../../../gsmecom/images/checked.png')} />
                    </div>

                    <h4>Succesfully Submitted</h4>
                  </div>
                </Modal.Body>
                <Modal.Footer>
                  <Button variant="secondary" onClick={handleSuccessModalClose}>
                    Ok
                  </Button>
                </Modal.Footer>
              </Modal>
              <Modal show={isFailureModal} onHide={handleFailureModalClose} className='SubmitModal'>
                <Modal.Body>
                  <div className='Details Success'>

                    <div className='imgBox'>
                      <Image src={require('../../../gsmecom/images/warning.png')} />
                    </div>
                    <h4>Failed</h4>
                  </div>
                </Modal.Body>
                <Modal.Footer>
                  <Button variant="secondary" onClick={handleFailureModalClose}>
                    Ok
                  </Button>
                </Modal.Footer>
              </Modal>
            </div>
          </>
      }
    </>
  )
})

export default NewMemberList