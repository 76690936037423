import React, { useState, useEffect, useRef, } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Form, Spinner, Modal, Image, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { observer } from 'mobx-react';
import useStores from '../../hooks';
import { useNavigate, } from 'react-router-dom';
import { faAdd, faPenToSquare, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { QRCode } from 'react-qrcode-logo';
import SelectSearchCriteria from '../../common/shared/selectSearchCriteria';

const SalesReturnOrReplacementList = observer((): JSX.Element => {
  const [isLoading, setLoading] = useState(true);
  const { salesReplaceRetrunStore, userCreationStore } = useStores();
  const [userRights, setRightsObj] = useState<any>({})
  const [Returndata, setReturndata] = useState<any>({})
  const [isSuccessModal, setSuccessModal] = useState(false);
  const [isFailureModal, setFailureModal] = useState(false);
  const [isSalesReturn, setSalesReturn] = useState(false)
  const navigate = useNavigate();

  const [isSearch, setSearch] = useState(false);
  const [filteredData, setFilteredData] = useState<any[]>([])
  const [salesReturnDetails, setSalesReturnDetails] = useState<any[]>([]);

  const handleFailureModalClose = () => setFailureModal(false);
  const handleSuccessModalClose = () => {
    setSuccessModal(false);
    preLoadingData();
  }
  const handleCreate = () => {
    navigate('/Admin/SalesReturnOrReplacement');
  }
  const handleOutwardDetails = async (outwardId) => {
    const data = await salesReplaceRetrunStore.getSalesRetrunDetails(outwardId);
    console.log(data, "datavalue");
    if (data !== undefined) {
      setSalesReturnDetails(data);
      setSalesReturn(true)
    }
  }

  const handleClose = () => {
    setSalesReturn(false)
  }

  async function preLoadingData() {
    setLoading(false);
    const [userRights] = await ([userCreationStore?.getUserRight('Sales_return_or_replacement')])
    const data = await salesReplaceRetrunStore.getSalesRetrunDetails(0);
    setReturndata(data);
    setFilteredData(data);

    console.log(data, 'data5551');
    if (userRights) {
      setRightsObj({ ...userRights })
    };
  }
  const isCurrentPage = useRef(true);

  useEffect(() => {
    if (isCurrentPage.current) {
      preLoadingData();
      isCurrentPage.current = false
    }
    return () => { }
  }, []);

  return (

    <div className='container-fluid'>
      {
        isLoading ? <div className='SpinnerBox'>
          <Spinner animation="border" role="status">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </div> : <>
          <div className='vertical-space-20'></div>
          <SelectSearchCriteria searchEnable={(bool) => setSearch(bool)} searchArray={salesReplaceRetrunStore?.returnreplaceDetails}
            searchTitles={[{ key: "posId", value: "POS Voucher" }]}
            emitSearchArray={(filterArray: any) => setFilteredData(filterArray)} />

          <div className='tableListDetails'>
            <table className="table table-striped">
              <thead>
                <tr>
                  <th scope='col'>Entry No </th>
                  <th scope='col'>Date  </th>
                  <th scope='col'>Member Name</th>
                  <th scope='col'>Mobile No</th>
                  <th scope='col'>Bill No</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {filteredData?.map((val, key) => {
                  return (
                    <tr key={key}>
                      <td>{val.salesreturnreplacemententryid}</td>
                      <td>{val.salesreturnreplacementdate}</td>
                      <td>{val.membername}</td>
                      <td>{val.Mobileno}</td>
                      <td>{val.Billno}</td>
                      <td><button className='changeBtn' onClick={() => handleOutwardDetails(val.salesreturnreplacemententryid)}> Details </button></td>
                      {/* <td><button disabled={!userRights?.add} className='Add'><FontAwesomeIcon icon={faAdd} onClick={handleCreate} /></button></td>
                        <td><button disabled={!userRights?.edit} className='Edit'><FontAwesomeIcon icon={faPenToSquare} /></button></td>
                        <td><button disabled={!userRights?.delete} className='delete'><FontAwesomeIcon icon={faTrashAlt} /></button></td> */}
                    </tr>
                  )
                }
                )}
              </tbody>
            </table>
          </div>
        </>
      }
      {
        isSalesReturn ?
          <Modal show={isSalesReturn} onHide={handleClose} className='PriceHistoryModel'>
            <Modal.Header closeButton>
              <Modal.Title>Return Details</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className='tableBox'>
                <table className="table table-striped">
                  <thead>
                    <tr>
                      <th scope="col">POS Id</th>
                      <th scope="col">Item Name</th>
                      <th scope="col">Unit Name</th>
                      <th scope="col">Barcode</th>
                      <th scope="col">Batch No</th>
                      <th scope="col">Qty</th>
                      <th scope="col">Amount</th>
                      <th scope='col'>Reason</th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      salesReturnDetails?.map((val, key) => {
                        return (
                          <tr key={key}>
                            <td>{val.posid}</td>
                            <td>{val.itemname}</td>
                            <td>{val.Unitname}</td>
                            <td>{val.Barcode}</td>
                            <td>{val.Batchno}</td>
                            <td>{val.Returnqty}</td>
                            <td>{val.returnamount}</td>
                            <td>{val.reason}</td>
                          </tr>
                        )
                      })
                    }
                  </tbody>
                </table>
              </div>
            </Modal.Body>
          </Modal> : null
      }

      <Modal show={isSuccessModal} onHide={handleSuccessModalClose} className='SubmitModal'>
        <Modal.Body>
          <div className='Details Success'>
            <div className='imgBox'>
              <Image src={require('../../../gsmecom/images/checked.png')} />
            </div>

            <h4>Succesfully Submitted</h4>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleSuccessModalClose}>
            Ok
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal show={isFailureModal} onHide={handleFailureModalClose} className='SubmitModal'>
        <Modal.Body>
          <div className='Details Success'>

            <div className='imgBox'>
              <Image src={require('../../../gsmecom/images/warning.png')} />
            </div>
            <h4>Failed</h4>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleFailureModalClose}>
            Ok
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );

});

export default SalesReturnOrReplacementList;
