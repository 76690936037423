import { action, set, makeAutoObservable, observable, runInAction } from 'mobx';
import { IOutletStockAnalysis } from '../model/index';
import apiAgent from '../../../APIAgent';
import { access, accessSync } from 'fs';

class OutletStockAnalysisStore {
    @observable outletstockReportDetails = new Map();


    constructor() {
        makeAutoObservable(this);
    }

    getOutletStockAnalysis = async (outletStockAnalysis: IOutletStockAnalysis) => {

        try {
            const data = await apiAgent.outletStockAnalysis?.getOutletStockAnalysis(outletStockAnalysis);
            runInAction(() => {
                this.outletstockReportDetails = JSON.parse(data);
            });
            return JSON.parse(data);
        }
        catch (error) {
            console.log(error);
        }
    }



}
export default OutletStockAnalysisStore;