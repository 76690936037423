import { runInAction, set, makeObservable, observable } from 'mobx';
import apiAgent from '../../../APIAgent';
import { IOutwardOutlet } from '../model';

class OutwardtoOutletStore {
    @observable outletItemDetails= new Map();
    @observable loadEmployess= new Map();
    @observable loadoutletRequest= new Map();
    @observable loadBatches= new Map();
    @observable loadItems = new Map();
    @observable loadItemWiseStockPOS = new Map();
    saveStatus = new Map();
    getoutwardlist=new Map();
    getoutwardDetlslist=new Map();
    entryNo = new Map();
    warehouseQty=new Map();
    

    loadOutletItems  = async ()=> {
        try {
          const inwardData= await apiAgent.outwardtoOutlet.loadOutletItems();
            
            runInAction(() => {
                this.outletItemDetails = inwardData;
            });
            return this.outletItemDetails;
        }
        catch (error) {
            console.log(error);
        }
    }
    getItemwiseStockPOS = async (prdcEtyBody) => {
        try {
            const loadgetItemwiseStockPOS = await apiAgent.employee.getItemwiseStockPOS(prdcEtyBody);
            runInAction(() => {
                    this.loadItemWiseStockPOS = JSON.parse(loadgetItemwiseStockPOS);
            });
            return JSON.parse(loadgetItemwiseStockPOS);
        }
        catch (error) {
            console.log(error);
        }
    }
    getLoadEmployess  = async ()=> {
        try {
          const employeesData= await apiAgent.employee.getLoadEmployees();
            
            runInAction(() => {
                this.loadEmployess = employeesData;
            });
            return this.loadEmployess;
        }
        catch (error) {
            console.log(error);
        }
    }
    getOutletDCprint = async (dcno)=>{
        try {
            const outletDC = await apiAgent.outwardtoOutlet.getOutletDCprint(dcno);
            
            return outletDC;
        }
        catch(error){
        console.log(error);
        }
    }
    loadOutletItemRequestnew= async (mode, outletId, wid,Reqno, itemid, Barcode)=> {
        try {
          const outletrequestItem= await apiAgent.outwardtoOutlet.loadOutletItemRequestnew(mode, outletId, wid,Reqno, itemid, Barcode);
            
            runInAction(() => {
                this.loadoutletRequest = outletrequestItem;
                
            });
            return JSON.parse(outletrequestItem);
        }
        catch (error) {
            console.log(error);
        }
    }
    loadOutletItemRequest = async (outletId, wid)=> {
        try {
          const outletrequestItem= await apiAgent.outwardtoOutlet.loadOutletItemRequest(outletId,wid);
            
            runInAction(() => {
                this.loadoutletRequest = outletrequestItem;
                
            });
            return JSON.parse(outletrequestItem);
        }
        catch (error) {
            console.log(error);
        }
    }
    loadOutletItem= async (outletItemId)=> {
        try {
          const Items= await apiAgent.outwardtoOutlet.loadOutletItem(outletItemId);
            
            runInAction(() => {
                this.loadItems = Items;
                
            });
            return (Items);
        }
        catch (error) {
            console.log(error);
        }
    }
    loadBatchs=async (itemId, wid)=> {
        try {
          const loadBatch= await apiAgent.outwardtoOutlet.loadBatchs(itemId, wid);
            
            runInAction(() => {
                this.loadBatches = loadBatch;
            });
            return this.loadBatches;
        }
        catch (error) {
            console.log(error);
        }
    }
    loadWarehouseQty=async (itemId)=> {
        try {
          const warehouse= await apiAgent.outwardtoOutlet.loadWarehouseQty(itemId);
            
            runInAction(() => {
                this.warehouseQty = warehouse;
            });
            return warehouse;
        }
        catch (error) {
            console.log(error);
        }
    }
    saveOutwardOutlet=async (outwardOutlet:IOutwardOutlet)=> {
        try {
          const status= await apiAgent.outwardtoOutlet.saveOutwardOutlet(outwardOutlet);
            return status;
        }
        catch (error) {
            console.log(error);
        }
    }

    getOutwardOutlets=async ()=> {
        try {
          const getoutwards= await apiAgent.outwardtoOutlet.getOutwardOutlets();
            
            runInAction(() => {
                
                this.getoutwardlist =  JSON.parse(getoutwards);
            });
            return this.getoutwardlist;
        }
        catch (error) {
            console.log(error);
        }
    }
    getOutwardOutletsDetls=async (entryNo)=> {
        
        try {
          const getoutwardsdetls= await apiAgent.outwardtoOutlet.getOutwardOutletsDetls(entryNo);
            
            runInAction(() => {
                
                this.getoutwardDetlslist =JSON.parse(getoutwardsdetls) ;
            });
            return this.getoutwardDetlslist;
        }
        catch (error) {
            console.log(error);
        }
    }
    getEntryNo = async (flag)=>{
        try{
            const entryNo = await apiAgent.helper.getWareHosueModuleEntryNo(flag);
            
            runInAction(()=>{
                this.entryNo = entryNo;
            })
            return this.entryNo;
        }
        catch(error){
        console.log(error);
        }
    }
    getOutwardOutletSearch  = async (SearchOptions)=> {
        try {
          const Data=await apiAgent.outwardtoOutlet.getOutwardOutletSearch(SearchOptions);
            this.getoutwardlist = Data;
            
            runInAction(() => {
                this.getoutwardlist = Data;
                
            })
            return this.getoutwardlist;
        
         
        }
        catch (error) {
            console.log(error);
        }
    }
    deleteOutwardOutletDetail = async (outwardDetailId) => {
        try {      
        const status=  await apiAgent.outwardtoOutlet.deleteOutwardOutletDetail(outwardDetailId);     
       return status;
        }
        catch (error) {
            console.log(error);
        }
    }
    deleteOutwardOutletMaster = async (outwardId) => {
        try {      
        const status=  await apiAgent.outwardtoOutlet.deleteOutwardOutletMaster(outwardId);     
       return status;
        }
        catch (error) {
            console.log(error);
        }
    }

    getStockSummaryItemWise = async (stockSummaryBody) => {
        try {
            const data = await apiAgent.outwardtoOutlet.getStockSummaryItemWise(stockSummaryBody);
            return JSON.parse(data);
        }
        catch (error) {
            console.log(error);
        }
    }
    getStockStummaryWarehouse = async (stockSummaryBody) => {
        try {
            const data = await apiAgent.outwardtoOutlet.getStockStummaryWarehouse(stockSummaryBody);
            return JSON.parse(data);
        }
        catch (error) {
            console.log(error);
        }
    }
    getStockSummaryAsOnJson= async (stockSummaryBody) => {
        try {
            const data = await apiAgent.outwardtoOutlet.getStockSummaryAsOnJson(stockSummaryBody);
            return JSON.parse(data);
        }
        catch (error) {
            console.log(error);
        }
    }
    
}

export default OutwardtoOutletStore;