import { useEffect, useState } from "react"

const AutoComplete = ({ value, getOptionLabel, disabled, clsName, options, emitOption, placeholder, freeSolo }:
    { value?: any, disabled?: any, getOptionLabel?: string, clsName?: string, options: Array<any>, placeholder: string, emitOption: (optmin1ion) => void, freeSolo?: boolean }) => {
    const [inputValue, setInputValue] = useState('');
    const [filterOption, setFilterOptions] = useState<any[]>(options);
        console.log("internal AutoComplete", value)
    useEffect(() => {
        setFilterOptions(options);
    }, [options])

    useEffect(() => {
        console.log("value..", getOptionLabel, value)
        if (value === '') {
            let event: any = {
                target: {
                    value: ''
                }
            }
            handleChange(event)
            setInputValue(value!)
        } else {
            setInputValue(value!)
        }
    }, [value])

    function getOptionalLable(obj: any) {
        if (Object.keys(obj)?.length != 0 && getOptionLabel) {
            const hasKey = obj.hasOwnProperty(getOptionLabel);
            if (hasKey) {
                return obj[getOptionLabel]?.toString()
            }
            else {
                return obj?.label?.toString()
            }
        }
        return '';
    }

    function handleChange(event) {
        const value = event.target.value
        setInputValue(value);

        const filterData = value?.length > 0 ? options?.filter(option => getOptionalLable(option)?.toLowerCase()?.includes(value?.toLowerCase())) : options;

        setFilterOptions(filterData);
    }

    function handleOptionClick(option) {
        setInputValue(getOptionalLable(option));
        setFilterOptions([]);
        // setSelectedOption(option);
        emitOption(option)
    }

    function handleAddOption() {
        let objArr: any[] = Object.assign([], options)
        objArr?.push({
            [getOptionLabel!]: inputValue,
        })
        setFilterOptions([...objArr])
        emitOption({ [getOptionLabel!]: inputValue })
    }

    const [visible, setVisible] = useState(false);

    function handleFocus() {
        setVisible(true)
    }
    function handleBlur() {
        setTimeout(() => {
            setVisible(false)
        }, 200)
    }
    return (
        <div style={{ position: "relative" }}>
            <input onFocus={handleFocus} onBlur={handleBlur} disabled={disabled} type="text" className={clsName ? clsName : "relative"} style={{ minWidth: "70%", height: '47px', padding: '0 10px' }} value={inputValue} onChange={handleChange} placeholder={placeholder} />
            {(visible && options?.length > 0) && (
                <div style={{ border: "1px solid lightgray", backgroundColor: 'white', maxHeight: "300px", zIndex: 1, minWidth: "100%", position: "absolute", overflow: "auto", marginTop: "6px" }} >
                    <ul style={{ listStyle: "none", padding: "0 12px" }}>
                        {(filterOption?.length > 0) ? filterOption.map((option) => {
                            return (
                                <li onClick={() => handleOptionClick(option)} className="p-1 py-2" style={{ fontSize: '0.9rem', textTransform: 'capitalize', cursor: 'pointer' }}>
                                    {getOptionalLable(option)}
                                </li>
                            )
                        }) : freeSolo ? <>
                            <li onClick={handleAddOption} className="p-1 pt-2" style={{ fontSize: '0.9rem', textTransform: 'capitalize', cursor: 'pointer' }}>
                                {`Add "${inputValue}"`}
                            </li>
                        </> : <li className="p-1">
                            No data
                        </li>}
                    </ul>
                </div>
            )}
        </div>
    )
}

export default AutoComplete;