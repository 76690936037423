import MemberDetailModal from './common/memberDetail';
import PaymentOptionModal from './common/paymentOption';
import DeliveryAddressModal from './common/deliveryAddress';
import HoldCustomerModal from './common/holdCustomer';
import EastRoundedIcon from '@mui/icons-material/EastRounded';
import { useState, useEffect, Suspense, useRef, useMemo, } from 'react';
import { Container, Button, Image, Modal, Spinner } from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTrash } from '@fortawesome/free-solid-svg-icons';
import { useNavigate, useParams } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import { observer } from 'mobx-react';
import './animate.css';
import './Pos2.css'
import {
  IPossalesMaster, IPosSalesDetails, IPOSHoldDetails, IPOSProductDetails,
  IPosBatchDetails, IPaymentRequest, IConfirmPaymentPayload, IMemberDetails, IAddress,
  IPOSCheckOfferRequest, IEntryNoRequest,
  IPosSalescoinchange
} from './model';
import _ from 'lodash';
import useStores from '../../hooks';
import ProgressBar from '../../common/shared/progressbar';
import Cookies from 'js-cookie';
import { Country, State, City } from 'country-state-city';
import addressValidation from './validation';
import moment from 'moment';
import NewMember from '../../BackOffice/NewMember/NewMemberRegistration';
import CustomerInvoiceModal from './common/customerInvoice';
import BarcodeBatches from './common/batchDetail';
import SalesReturnModal from './common/salesReturnModal';
import CheckOffers from './common/checkOffersModal';
import { InputAdornment, TextField } from '@mui/material';
import { IPaymentInfo } from './model/posmodal';
import { removeDuplicates } from '../../common/shared/utils';
import AutoComplete from '../../common/shared/autoComplete';

const ItemPossales = observer((): JSX.Element => {

  const { posMasterStore, userCreationStore } = useStores();

  const { loadItemListJson, getPosBillNo, loadPOSProductList, getPosItemOffers, getPosFreeItems, getPosItemMaster } = posMasterStore;

  const outletID = Number(Cookies.get('outletId'))

  const [memberDetail, setMemberDetail] = useState<IMemberDetails>({});

  const [posProductList, setPosProductList] = useState<any[]>(posMasterStore?.loadPOSProducts)
  const [posItemOffers, setPosItemOffers] = useState<any[]>([])
  const [posFreeItems, setPosFreeItems] = useState<any[]>(posMasterStore?.getFreeItems)
  const [posBatchDetails, setPOSBatchDetails] = useState<IPosBatchDetails[]>(new Array<IPosBatchDetails>());

  const [posDetail, setPosDetail] = useState<IPossalesMaster>({});
  const [posItemDetils, setPOStItemDetails] = useState<IPOSProductDetails[]>([]);

  console.log(posItemDetils, 'posItemDetils>>')

  const [posLocalFields, setPosLocalFields] = useState<any>({})
  const [countryOptions, setCountryOptions] = useState([]);
  const [address, setAddress] = useState<IAddress>({});
  const [posInvoiceDetails, setPosInvoiceDetails] = useState<any>({})
  console.log(posInvoiceDetails, 'posInvoiceDetails');

  const [isNewAddress, setNewAddress] = useState(false);
  const [cityOptions, setCityOptions] = useState([]);
  const [invoiceList, setInvoiceList] = useState<any[]>([]);

  const [isLoading, setLoading] = useState({ initialLoad: true, spinnerLoad: false, isSpinner: false });

  const [errors, setError] = useState<any>({})
  const [showReplacement, setShowReplacement] = useState(false)
  const [isProceed, setProceed] = useState(false)
  const [memberModal, setMemberModal] = useState({ isMember: false, isMemberModal: false })
  const [batchModal, setBatchModal] = useState(false)
  const [isLoadingDiv, setLoadingDiv] = useState(false);
  const [isOffers, setOffers] = useState(false);
  // const [isClearOffers, setClearOffers] = useState(false);
  const [show, setShow] = useState(false);
  const [isDelivery, setDelivery] = useState(false);
  const [hold, setHold] = useState(false);
  const [customerInvoice, setCutomersInvoice] = useState(false)
  const [isSuccessModal, setSuccessModal] = useState(false)
  const [isFailureModal, setFailureModal] = useState(false);
  const [isSuccessDeleteModal, setSuccessDeleteModal] = useState(false)
  const [returnAmt, setReturnAmt] = useState(0)
  const [newMemberModal, setNewMemberModal] = useState(false);
  const [barcode, setBarcode] = useState<string>('');
  const [isBarcodeBatches, setBarcodeBatches] = useState({ bool: false, batchArr: Array<any>() })
  const [isItemList, setIsItemList] = useState({ bool: false, batchArr: Array<any>() })
  const [assignedOffers, setAssignedOff] = useState<any[]>([])
  const [isAccessModal, setAccessModal] = useState(false);
  const [isLoadModal, setLoadModal] = useState(false);
  const [userRights, setRightsObj] = useState<any[]>([])
  const [itemMasterList, setItemMasterList] = useState<any[]>([])
  const navigate = useNavigate();

  const handleMemberDetails = async (e) => {
    const { name, value } = e.target;
    if (name === 'mobileNo' && value.length === 10) {
      posDetail.mobileNo = value;
      errors.memberId = "";
      setError(errors)
      setMemberModal({ ...memberModal, isMember: true, isMemberModal: true })
      await posMasterStore.getPOSCustomerDetails(value)
      setMemberDetail({
        ...memberDetail,
        memberId: posMasterStore.posCustomerDetails.memberId,
        memberType: posMasterStore.posCustomerDetails.memberType, memberCategory: posMasterStore.posCustomerDetails.memberCategory,
        memberCatId: posMasterStore?.posCustomerDetails?.memberType?.split('-')![0],
        memberName: posMasterStore.posCustomerDetails.memberName, memberAddress: posMasterStore.posCustomerDetails.memberAddress,
        mobileNo: value, memberEmail: posMasterStore.posCustomerDetails.memberEmail,
        earnedPoints: posMasterStore.posCustomerDetails.earnedPoints,
        balanceEarnedPoints: posMasterStore.posCustomerDetails.balanceEarnedPoints,
        redeemPoints: posMasterStore.posCustomerDetails.redeemPoints
      })
      const posItemOffer = await getPosItemOffers(posMasterStore.posCustomerDetails.memberType)
      if (posItemOffer) {
        let updatedList = posItemOffer?.map((each) => {
          if (each?.offerid) {
            each.offerdid = each?.offerid
            return each
          }
          return each
        })
        console.log(posItemOffer, 'posItemOffer');
        setPosItemOffers([...updatedList])
      }

      setMemberModal({ ...memberModal, isMember: false, isMemberModal: true })

      return;
    } else {
      setMemberDetail({ ...memberDetail, mobileNo: value })
    }
  }
  const handleAccessModalClose = () => {

    setAccessModal(false)
    setSuccessModal(false);
    navigate('/Admin/POS');
  }



  async function handleItemDetails(val, type) {
    console.log(val, 'itemvaluepass');
    if (memberDetail.memberId === undefined) {
      let error: any = {};
      error.memberId = 'Please Provide Member Details';
      setError(error);
    } else if (val != null) {
      let itemObj = posProductList?.find((obj) => obj?.itemid === val?.itemId)
      console.log(itemObj, 'itemObj2');
      getPosBatchStocks(itemObj?.Barcode ? itemObj?.Barcode : itemObj?.Gsmcode)
    }
  }

  function getPosBatchStocks(barcode) {

    if (memberDetail.memberId === undefined) {
      let error: any = {};
      error.memberId = 'Please Provide Member Details';
      setError(error);
    } else {
      setBarcode('');

      let itemBatchList: any[] = []
      itemBatchList = posProductList?.filter((obj) => obj?.Barcode == barcode)
      if (itemBatchList?.length === 0) {
        itemBatchList = posProductList?.filter((obj) => obj?.Gsmcode === barcode)
        console.log(itemBatchList, 'itemBatchListbatch');
      }
      if (barcode) {
        if (itemBatchList?.length > 1) {
          const itemCount = itemBatchList.reduce((acc, item) => {
            acc[item.Barcode] = (acc[item.Barcode] || 0) + 1;
            return acc;
          }, {});

          console.log(itemCount, 'itemCount');
          console.log(itemBatchList, 'itemBatchListbatch');
          itemBatchList.forEach((item) => {
            if (itemCount[item.itemid] > 1) {
              setBarcodeBatches({ ...isBarcodeBatches, bool: true, batchArr: itemBatchList })
            }
            else {
              setIsItemList({ ...isItemList, bool: true, batchArr: itemBatchList })
            }
          })
          // open batch modal to choose batch details
          //  setBarcodeBatches({ ...isBarcodeBatches, bool: true, batchArr: itemBatchList })
        } else if (itemBatchList?.length === 1) {

          console.log(itemBatchList, 'itemBatchListbatchelse');
          // add to cart function 
          // array has one obj send it to add to cart function
          let itemDetail: IPOSProductDetails = {
            posItemId: itemBatchList![0]?.itemid,
            availableQty: itemBatchList![0]?.stock,
            posItemName: itemBatchList![0]?.itemname,
            posItemQty: 1,
            posMinSalQty: itemBatchList[0]?.minsalqty,
            posItemMRP: Number(itemBatchList![0]?.Mrprate),
            posItemSellingRate: Number(memberDetail?.memberCatId === 'B2B' ? itemBatchList![0].BTBSellingRate : itemBatchList![0]?.BTCSellingRate),
            posTaxPercentage: itemBatchList![0]?.taxper,
            posDiscountPercentage: itemBatchList![0]?.Discount_Pv,
            posDiscountValue: itemBatchList![0]?.Discount_Pv,
            posDiscountMode: itemBatchList![0]?.Dis_Mode,
            posbatchNo: itemBatchList![0]?.batchno,
            posBarcode: itemBatchList![0]?.Barcode,
            posSubCategoryId: Number(itemBatchList![0]?.subcategory),
            isSodexo: itemBatchList![0]?.sudexo,
          }
          let isexistsItem = posItemDetils?.some((items) => isEqual(items, itemDetail));
          let existsItem = posItemDetils?.find((items) => isEqual(items, itemDetail));
          itemDetail.posItemQty = isexistsItem ? (Number(existsItem?.posItemQty) + 1) : 1;
          itemDetail.posItemQty = itemDetail?.posItemQty * Number(itemDetail?.posMinSalQty != 0 ? itemDetail?.posMinSalQty : 1)
          if (itemDetail?.availableQty!) {
            addToCart(itemDetail)
          }
        }
      }
    }
  }

  function isEqual(currentItem, newItem) {
    return (
      currentItem?.posItemId == newItem?.posItemId &&
      currentItem?.posItemMRP == newItem?.posItemMRP &&
      currentItem?.posItemSellingRate == newItem?.posItemSellingRate
    )
  }

  console.log(posItemOffers, 'posItemOffers')
  // console.log(posItemOffers, 'posItemOffers')
  // console.log(posFreeItems, 'posFreeItems')
  // console.log(assignedOffers, 'assignedOffers', posItemOffers)
  // console.log(posBatchDetails, 'posBatchDetails>>')

  function addToCart(itemBatch: IPOSProductDetails) {
    console.log(itemBatch, 'itemBatch55');

    let isexistsItem = posItemDetils?.some((items) => {
      return items?.posItemId === itemBatch?.posItemId && items?.posbatchNo === itemBatch?.posbatchNo
    })
    let filterSameItem = posItemDetils?.filter((items) => (items?.posFreeItemId === itemBatch?.posFreeItemId) || (items?.posItemId === itemBatch?.posItemId))
    let totalItemQty = filterSameItem?.reduce((a, v) => a = a + (v?.posItemQty! ?? 0), 0)
    let taxValue: number = 0;
    let discountValue: number = 0;
    let offerDiscountValue: number = 0;
    let totalPrice: number = 0;

    // if (itemBatch?.posTaxPercentage) {
    //   taxValue = (Number(itemBatch?.posItemSellingRate) - (Number(itemBatch?.posItemSellingRate!) / (1 + itemBatch?.posTaxPercentage! / 100)))?.toFixed(2) as any;
    // }
    posItemOffers?.map((offers) => {
      if (new Date() < new Date(offers?.effectivetoDate)) {
        if (offers?.AssinOfferType === "products" && offers?.itemId && itemBatch?.posItemId === offers?.itemId) {
          if (offers?.offertype === "Value Discount" && offers?.Dis_Mode === "Value") {
            let sumSellingRate = itemBatch?.posItemSellingRate! * itemBatch?.posItemQty!
            if (sumSellingRate >= offers.From_QV && sumSellingRate <= offers.To_QV) {
              offerDiscountValue = offers.Discount_PV!;
              if (offers?.offerid && !(assignedOffers?.some(offer => offer?.offerid === offers?.offerid))) {
                setAssignedOff([...assignedOffers, offers])
              }
            } else {
              offerDiscountValue = 0;
              let index = assignedOffers?.findIndex((obj) => obj?.offerid === offers?.offerid)
              if (index >= 0) {
                assignedOffers?.splice(index, 1)
                setAssignedOff([...assignedOffers])
              }
            }
          } else if (offers?.offertype === "Value Discount" && offers?.Dis_Mode === "Dis%") {
            let sumTotalPrice = (Number(itemBatch?.posItemSellingRate!) * Number(itemBatch?.posItemQty!))?.toFixed(2) as any;
            if (sumTotalPrice >= offers.From_QV && sumTotalPrice <= offers.To_QV) {
              offerDiscountValue = (sumTotalPrice * (Number(itemBatch?.posDiscountPercentage! ?? 0) / 100))?.toFixed(2) as any;
              console.log(offerDiscountValue, 'offerDiscountValuecalcuval');

            } else {
              offerDiscountValue = 0;
            }
          } else if (offers?.offertype === "Qty Discount" && offers?.Dis_Mode === "Value") {
            let itemQty = itemBatch?.posItemQty!;
            if (itemQty >= offers.From_QV && itemQty <= offers.To_QV) {
              offerDiscountValue = offers.Discount_PV!;
              if (offers?.offerid && !(assignedOffers?.some(offer => offer?.offerid === offers?.offerid))) {
                setAssignedOff([...assignedOffers, offers])
              }
            } else {
              offerDiscountValue = 0;
              let index = assignedOffers?.findIndex((obj) => obj?.offerid === offers?.offerid)
              if (index >= 0) {
                assignedOffers?.splice(index, 1)
                setAssignedOff([...assignedOffers])
              }
            }
          } else if (offers?.offertype === "Qty Discount" && offers?.Dis_Mode === "Dis%") {
            const filteredList = posItemOffers?.filter(offer => offer.itemId === itemBatch.posItemId);
            let itemQty = itemBatch?.posItemQty!;
            if (itemQty >= offers.From_QV && itemQty <= offers.To_QV) {
              offerDiscountValue = itemQty * (Number(itemBatch?.posDiscountPercentage! ?? 0) / 100)
              if (offers?.offerid && !(assignedOffers?.some(offer => offer?.offerid === offers?.offerid))) {
                setAssignedOff([...assignedOffers, offers])
              } else {
                let updatedList = assignedOffers?.map((each) => {
                  if (each?.offerid === offers?.offerid && each?.itemId === offers?.itemId) {
                    return each = offers;
                  }
                  return each
                })
                setAssignedOff([...updatedList])
                console.log(updatedList, 'updatedList ');

              }
            } else {
              let firstOffer = filteredList[0];
              let lastOffer = filteredList[filteredList?.length - 1];
              const firstFromQV = parseFloat(firstOffer.From_QV);
              const lastToQV = parseFloat(lastOffer.To_QV);
              if (!(itemQty >= firstFromQV && itemQty <= lastToQV)) {
                offerDiscountValue = 0;
                let index = assignedOffers?.findIndex((obj) => obj?.offerid === offers?.offerid)
                if (index >= 0) {
                  assignedOffers?.splice(index, 1)
                  setAssignedOff([...assignedOffers])
                }
              }
            }
          } else if (offers?.offertype === "Coupon Discount" && offers?.Dis_Mode === "Value") {

          } else if (offers?.offertype === "Coupon Discount" && offers?.Dis_Mode === "Dis%") {

          }
        }
        if (offers?.AssinOfferType === "category" && offers?.categoryId && itemBatch?.posSubCategoryId === offers?.categoryId) {
          if (offers?.offertype === "Value Discount" && offers?.Dis_Mode === "Value") {
            let sumSellingRate = itemBatch?.posItemSellingRate! * itemBatch?.posItemQty!
            if (sumSellingRate >= offers.From_QV && sumSellingRate <= offers.To_QV) {
              offerDiscountValue = offers.Discount_PV!;
              if (offers?.offerid && !(assignedOffers?.some(offer => offer?.offerid === offers?.offerid))) {
                setAssignedOff([...assignedOffers, offers])
              }
            } else {
              offerDiscountValue = 0;
              let index = assignedOffers?.findIndex((obj) => obj?.offerid === offers?.offerid)
              if (index >= 0) {
                assignedOffers?.splice(index, 1)
                setAssignedOff([...assignedOffers])
              }
            }
          } if (offers?.offertype === "Value Discount" && offers?.Dis_Mode === "Disc%") {

          } else if (offers?.offertype === "Qty Discount" && offers?.Dis_Mode === "Value") {
            let itemQty = itemBatch?.posItemQty!;
            if (itemQty >= offers.From_QV && itemQty <= offers.To_QV) {
              offerDiscountValue = offers.Discount_PV!;
              if (offers?.offerid && !(assignedOffers?.some(offer => offer?.offerid === offers?.offerid))) {
                setAssignedOff([...assignedOffers, offers])
              }
            } else {
              offerDiscountValue = (itemBatch?.posItemMRP! - itemBatch?.posItemSellingRate!);
              setAssignedOff(assignedOffers?.filter((obj) => obj?.offerid != offers?.offerid))
            }
          } else if (offers?.offertype === "Qty Discount" && offers?.Dis_Mode === "Disc%") {

          }
        }
      }
    })

    discountValue = itemBatch?.posItemMRP! - itemBatch?.posItemSellingRate!
    itemBatch.posDiscountPercentage = (((itemBatch?.posItemMRP! - itemBatch?.posItemSellingRate!) / itemBatch?.posItemMRP!) * 100)?.toFixed(2) as any

    totalPrice = (Number(itemBatch?.posItemSellingRate!) * Number(itemBatch?.posItemQty!))?.toFixed(2) as any

    if (itemBatch?.posTaxPercentage) {
      taxValue = (Number(totalPrice) - (Number(totalPrice!) / (1 + itemBatch?.posTaxPercentage! / 100)))?.toFixed(2) as any;
    }

    const netAmount = (Number(totalPrice) - offerDiscountValue)?.toFixed(2);

    itemBatch.posItemQty = itemBatch?.posItemQty;
    itemBatch.posItemDQty = totalItemQty != 0 ? totalItemQty : itemBatch?.posItemQty;
    itemBatch.posItemMRP = Number(itemBatch?.posItemMRP);
    itemBatch.posDiscountPercentage = !isNaN(itemBatch.posDiscountPercentage!) ? Number(itemBatch?.posDiscountPercentage) : 0;
    itemBatch.posItemSellingRate = Number(itemBatch?.posItemSellingRate);
    itemBatch.posTaxValue = Number(taxValue);
    //itemBatch.posDiscountValue = (discountValue + offerDiscountValue) ?? 0;
    // itemBatch.posDiscountValue = isNaN(discountValue + offerDiscountValue) ? 0 : (discountValue + offerDiscountValue);
    itemBatch.posDiscountValue = isNaN(discountValue) || isNaN(offerDiscountValue)
      ? 0
      : (discountValue + offerDiscountValue) * (itemBatch?.posItemQty || 0);
    itemBatch.posTotalPrice = Number(totalPrice);
    itemBatch.posNetAmount = Number(netAmount);

    if (itemBatch?.isSodexo) {
      itemBatch.posSudexoAmt = Number(netAmount);
    }

    const freeItems: any[] = checkForFreeItem(itemBatch)

    if (!isexistsItem) {
      if (freeItems) {
        freeItems?.forEach((each) => {
          let batchObj: IPosBatchDetails = {
            itemId: each?.posFreeItemId,
            barcode: each?.posBarcode?.toString(),
            batchNo: each?.posbatchNo,
            batchQty: each?.posItemQty
          }
          if (batchObj?.itemId) {
            updateBatchList(batchObj, isexistsItem, each)
          }
        })
      } else {
      }
      posItemDetils?.push(itemBatch);
      console.log(posItemDetils, itemBatch, 'posItemDetils555');
      setPOStItemDetails([...posItemDetils, ...freeItems])


      let batchObj: IPosBatchDetails = {
        itemId: itemBatch?.posItemId,
        barcode: itemBatch?.posBarcode?.toString(),
        batchNo: itemBatch?.posbatchNo,
        batchQty: itemBatch?.posItemQty
      }
      updateBatchList(batchObj, isexistsItem)
    } else {
      let prevState = posItemDetils?.map((item) => {
        const matchingItem = freeItems?.find((each, ind) => !each?.posItemId && each?.posFreeItemId === item?.posFreeItemId && each?.posbatchNo === item?.posbatchNo)
        if (item.posItemId === itemBatch.posItemId && item?.posbatchNo === itemBatch?.posbatchNo) {
          return item = itemBatch;
        }
        return matchingItem ?? item;
      })
      setPOStItemDetails([...prevState])
      let batchObj: IPosBatchDetails = {
        itemId: itemBatch?.posItemId,
        barcode: itemBatch?.posBarcode,
        batchNo: itemBatch?.posbatchNo,
        batchQty: itemBatch?.posItemQty
      }
      if (freeItems) {
        freeItems?.forEach((each) => {
          let batchObj: IPosBatchDetails = {
            itemId: each?.posFreeItemId,
            barcode: each?.posBarcode?.toString(),
            batchNo: each?.posbatchNo,
            batchQty: each?.posItemQty
          }
          if (batchObj?.itemId) {
            updateBatchList(batchObj, true, each)
          }
        })
      } else if (batchObj?.itemId) {
        updateBatchList(batchObj, true)
      }
    }
    setPosProductList(posProductList?.map((item, i) => {
      if (item.itemid === itemBatch.posItemId && itemBatch?.posbatchNo === item?.batchno) {
        item.stock = item.stock - itemBatch?.posItemQty!
        return item;
      }
      return item;
    }));
  }

  function checkForFreeItem(itemDetail: IPOSProductDetails) {
    let freeItems = posFreeItems?.filter((obj) => obj?.itemid === itemDetail?.posItemId);
    const freeItemRet = freeItems?.map((obj) => {
      let freeItem: IPOSProductDetails;
      if (new Date() < new Date(obj?.efftodate)) {
        if (obj?.isbatch === "Yes" && obj?.batch_no === itemDetail?.posbatchNo) {
          return freeItem = {
            posFreeItemId: obj?.is_same_item ? obj?.itemid : obj?.otheritemid,
            posItemName: obj?.is_same_item ? obj?.itemname : obj?.otheritem,
            posbatchNo: obj?.is_same_item ? obj?.batch_no : obj?.otheritembatchno,
            posBarcode: obj?.is_same_item ? itemDetail?.posBarcode?.toString() : obj?.otheritembarcode,
            posItemQty: itemDetail?.posItemDQty! * obj?.freeqty,
            posTaxValue: 0,
            posDiscountValue: 0,
            posTotalPrice: 0,
            posNetAmount: 0,
            posTaxPercentage: 0,
            posDiscountPercentage: 0,
          }
        } else if (obj?.issales === "Yes" && obj?.saleqty) {
          const divisions = Math.floor(itemDetail?.posItemQty! / (obj?.saleqty));
          return freeItem = {
            posFreeItemId: obj?.is_same_item ? obj?.itemid : obj?.otheritemid,
            posItemName: obj?.is_same_item ? obj?.itemname : obj?.otheritem,
            posbatchNo: obj?.is_same_item ? obj?.batch_no : obj?.otheritembatchno,
            posBarcode: obj?.is_same_item ? itemDetail?.posBarcode?.toString() : obj?.otheritembarcode,
            // posbatchNo: itemDetail?.posbatchNo,
            posItemQty: divisions * obj?.freeqty,
            posTaxValue: 0,
            posDiscountValue: 0,
            posTotalPrice: 0,
            posNetAmount: 0,
            posTaxPercentage: 0,
            posDiscountPercentage: 0
          }
        }
      }
    })

    return freeItemRet?.filter((obj) => obj != undefined);
  }

  const addProductToBillingList = (product: IPOSProductDetails, quantity: number) => {
    let billingList: IPOSProductDetails[] = [...posItemDetils]
    let freeItems = posFreeItems?.filter((obj) => obj?.batch_no === product?.posbatchNo && obj?.itemid === product?.posItemId);

    const freeItem: any = freeItems?.find(
      item => item?.isbatch === "Yes" && item?.itemid === product?.posItemId && item?.batch_no === product?.posbatchNo
    );

    const addOrUpdatePosItem = (orgItem: IPOSProductDetails, freeItem: any, quantityToAdd: number, isFreeItem: boolean = false) => {
      const itemIndex = billingList?.findIndex(
        item => item?.posbatchNo === (isFreeItem ? freeItem?.batch_no : orgItem?.posbatchNo) &&
          (isFreeItem ? item?.posFreeItemId : item?.posItemId) === (isFreeItem ? freeItem?.itemid : orgItem?.posItemId)
      );

      if (itemIndex !== -1) {
        billingList[itemIndex].posItemQty! += quantityToAdd;
      } else {
        if (isFreeItem) {
          billingList?.push({
            posFreeItemId: freeItem?.is_same_item ? freeItem?.itemid : freeItem?.otheritemid,
            posItemName: freeItem?.is_same_item ? freeItem?.itemname : freeItem?.otheritem,
            posbatchNo: freeItem?.is_same_item ? freeItem?.batch_no : freeItem?.otheritembatchno,
            posBarcode: freeItem?.is_same_item ? orgItem?.posBarcode?.toString() : freeItem?.otheritembarcode,
            posItemQty: quantityToAdd,
            posTaxValue: 0,
            posDiscountValue: 0,
            posTotalPrice: 0,
            posNetAmount: 0,
            posTaxPercentage: 0,
            posDiscountPercentage: 0,
          })
        } else {
          billingList?.push({
            ...orgItem,
            posItemQty: quantityToAdd
          })
        }
      }

    }

    let remainingQty = quantity;
    let freeItemCycleCount = freeItem ? freeItem?.freeqty : 0;
    let cycleCount = 0;

    while (remainingQty > 0) {
      // Add the product
      addOrUpdatePosItem(product, undefined, 1)
      remainingQty--;
      console.log(freeItem, 'freeItem');

      // Process free items based on the specified pattern
      if (freeItem && remainingQty > 0) {
        for (let i = 0; i < freeItemCycleCount && remainingQty > 0; i++) {
          let tempObj = new IPOSProductDetails();
          addOrUpdatePosItem(tempObj, freeItem, 1, true)
          remainingQty--;
        }
        cycleCount++;
      }
    }

    setPOStItemDetails([...billingList])
  };

  function updateBatchList(batchObj, isexistsItem, freeItems?: any, isRemove?: boolean) {
    if (!isexistsItem) {
      let batchItem: IPosBatchDetails = {
        itemId: freeItems?.posFreeItemId ? freeItems?.posFreeItemId : batchObj?.itemId,
        barcode: freeItems?.posFreeItemId ? freeItems?.posBarcode?.toString() : batchObj?.barcode,
        batchNo: freeItems?.posFreeItemId ? freeItems?.posbatchNo : batchObj?.batchNo,
        batchQty: freeItems?.posFreeItemId ? freeItems?.posItemQty : batchObj?.batchQty
      }
      posBatchDetails?.push(batchItem);
      setPOSBatchDetails([...posBatchDetails])
    } else if (isRemove || (!posItemDetils?.some((obj) => obj?.posItemId === (freeItems?.posFreeItemId ? freeItems?.posFreeItemId : batchObj?.itemId)) &&
      posBatchDetails?.some((obj) => obj?.itemId === (freeItems?.posFreeItemId ? freeItems?.posFreeItemId : batchObj?.itemId)))) {

      setPOSBatchDetails(posBatchDetails?.filter((batches) => (batches?.barcode != batchObj?.barcode && batches?.itemId != (freeItems?.posFreeItemId ? freeItems?.posFreeItemId : batchObj?.itemId) && batches?.batchNo != batchObj?.batchNo)))

    } else if (posItemDetils?.some((obj) => obj?.posItemId === (freeItems?.posFreeItemId ? freeItems?.posFreeItemId : batchObj?.itemId)) && posBatchDetails?.some((obj) => obj?.itemId === (freeItems?.posFreeItemId ? freeItems?.posFreeItemId : batchObj?.itemId))) {
      setPOSBatchDetails(posBatchDetails?.map((obj) => {
        if ((freeItems?.posFreeItemId ? freeItems?.posFreeItemId : batchObj?.itemId) && obj?.itemId === (freeItems?.posFreeItemId ? freeItems?.posFreeItemId : batchObj?.itemId) && obj?.batchNo === obj?.batchNo) {
          return obj = batchObj;
        }
        return obj
      }))
    } else if (posItemDetils?.some((obj) => obj?.posItemId === (freeItems?.posFreeItemId ? freeItems?.posFreeItemId : batchObj?.itemId)) && !posBatchDetails?.some((obj) => obj?.itemId === (freeItems?.posFreeItemId ? freeItems?.posFreeItemId : batchObj?.itemId))) {
      if ((freeItems?.posFreeItemId ? freeItems?.posFreeItemId : batchObj?.itemId)) {
        posBatchDetails?.push(batchObj)
        setPOSBatchDetails(posBatchDetails)
      }
    }
  }

  function useCartQuantityUpdater(type: string, obj: IPOSProductDetails, qty?: number) {
    let existsItem = posItemDetils?.find((items) => isEqual(items, obj));
    if (existsItem && Object?.keys(existsItem!)?.length !== 0) {
      if (type == 'add') {
        existsItem!.posItemQty! += qty! * Number(existsItem?.posMinSalQty != 0 ? obj?.posMinSalQty : 1);
      } else {
        existsItem!.posItemQty! -= qty! * Number(existsItem?.posMinSalQty != 0 ? obj?.posMinSalQty : 1);
      }
      addToCart(existsItem);
    } else {
      if (type == 'add') {
        obj.posItemQty = 0;
        obj.posItemQty! += qty! * Number(obj?.posMinSalQty != 0 ? obj?.posMinSalQty : 1);
      } else {
        obj.posItemQty = 0
        obj.posItemQty! -= qty! * Number(obj?.posMinSalQty != 0 ? obj?.posMinSalQty : 1);
      }
      addToCart(obj);
    }
  }

  async function checkOffers() {
    if (memberDetail.mobileNo === undefined) {
      let error: any = {}
      error.memberId = 'Please Provide Member MobileNo..';
      setError(error);
    } else if (posItemDetils?.length == 0) {
      let error: any = {}
      error.Product = "Select Product";
      setError(error);
    } else {
      setLoading({ ...isLoading, spinnerLoad: true });
      posDetail.billValue = Number(posItemDetils?.length && posItemDetils?.reduce((a, v) => a = a + Number(v?.posNetAmount!), 0)?.toFixed(2));

      let checkofferRequest: IPOSCheckOfferRequest = {}
      checkofferRequest.billValue = posDetail.billValue - Number(returnAmt ?? 0);
      checkofferRequest.earnedPoints = memberDetail.earnedPoints;
      checkofferRequest.category = memberDetail.memberType;

      await posMasterStore.getBillvalueOffer(checkofferRequest);
      setPosDetail({
        ...posDetail,
        earnedPoints: posMasterStore.offers.currentEarnedPoints,
        currentEarnedPoints: posMasterStore.offers.currentEarnedPoints,
        netvalue: posMasterStore.offers.netBillValue,
        offerName: posMasterStore.offers.offerName,
        finalAmount: posMasterStore.offers.finalValue,
        redeemPoints: posMasterStore.offers.redeemPoints,
        redeemPointsamt: posMasterStore.offers.redeemAmount
      });

      setLoading({ ...isLoading, spinnerLoad: false });
      setOffers(true);
      setProceed(true);
    }
  }

  function handleReplacemtn() {
    if (memberDetail.memberId === undefined) {
      let error: any = {}
      error.memberId = 'Please Provide Member Details';
      setError(error);
    } else {
      setShowReplacement(true)
      setError({});
    }
  }

  const processOrder = async () => {

    if (posDetail.deliveryCharges !== undefined && !posDetail.isDelivery) {
      errors.delivery = 'Please Select Delivery Option and Add Address';
      setError(errors);
    } else if (memberDetail.memberId === undefined) {
      errors.memberId = 'Please Provide Member Details';
      setError(errors);
    } else {
      setShow(true);
    }
  }

  const handleRadioChange = (e) => {
    const { name, value } = e.target;
    setPosDetail({ ...posDetail, paymentmode: value })
  }

  const handlebatchNo = (e, value) => {

    let { checked } = e.target;
    if (checked == true) {
      console.log(value, 'valuehandlebatchNo');
      if (value.length > 0) {
        setBarcodeBatches({ ...isBarcodeBatches, bool: true, batchArr: value })
      }
      else {
        let itemDetail: IPOSProductDetails = {
          posItemId: value?.itemid,
          availableQty: value?.stock,
          posItemName: value?.itemname,
          posItemQty: 1,
          posMinSalQty: value?.minsalqty,
          posItemMRP: Number(value?.Mrprate),
          posItemSellingRate: Number(memberDetail?.memberCatId === 'B2B' ? value.BTBSellingRate : value?.BTCSellingRate),
          posTaxPercentage: value?.taxper,
          posDiscountPercentage: value?.Discount_Pv,
          posDiscountValue: value?.Discount_Pv,
          posDiscountMode: value?.Dis_Mode,
          posbatchNo: value?.batchno,
          posBarcode: value?.Barcode,
          posSubCategoryId: Number(value?.subcategory),
          isSodexo: value?.sudexo,
        }
        debugger;
        //let isexistsItem = posItemDetils?.some((items) => isEqual(items, itemDetail));
        let isexistsItem = posItemDetils?.some((items) => {
          return items?.posItemId === itemDetail?.posItemId && items?.posbatchNo === itemDetail?.posbatchNo
        });
        let existsItem = posItemDetils?.find((items) => isEqual(items, itemDetail));
        itemDetail.posItemQty = isexistsItem ? (Number(existsItem?.posItemQty) + 1) : 1;
        itemDetail.posItemQty = itemDetail?.posItemQty * Number(itemDetail?.posMinSalQty != 0 ? itemDetail?.posMinSalQty : 1)
        if (itemDetail?.availableQty!) {
          addToCart(itemDetail)
          setIsItemList({ ...isItemList, bool: false, batchArr: [] })
        }
      }
      //  setIsItemList({ ...isItemList, bool: false, batchArr: [] })
    }
  }

  async function processtoBill(paymentAmount: IPaymentInfo, posSalescoinchange: IPosSalescoinchange[] | null) {
    let error: any = {};
    if (posDetail.paymentmode === undefined) {
      posDetail.paymentmode = "Cash";
    }
    posDetail.cashAmount = paymentAmount?.cashAmount ?? 0;
    posDetail.redeemAmount = paymentAmount?.redeemAmount ?? 0;
    posDetail.salesReturn = paymentAmount?.salesReturn ?? 0
    posDetail.cardAmount = paymentAmount?.cardAmount ?? 0;
    posDetail.qRcodeAmount = paymentAmount?.qRcodeAmount ?? 0;
    posDetail.onlinestoreorder = paymentAmount?.onlinestoreorder ?? 0;
    posDetail.onlinebankTR = paymentAmount?.onlinebankTR ?? 0;
    posDetail.sodexo = paymentAmount?.sodexo ?? 0;
    if (posSalescoinchange?.length) {
      console.log(posSalescoinchange, 'posSalescoinchange');

      posDetail.posSalescoinchange = posSalescoinchange;
    } else {
      posDetail.posSalescoinchange = []
    }
    setShow(false);
    if (posDetail.paymentmode !== undefined && posDetail.paymentmode === 'Cash') {
      setLoading({ ...isLoading, spinnerLoad: true })
      savePOSMaster();
    } else {
      setLoading({ ...isLoading, spinnerLoad: true })
      const paymentRequest: IPaymentRequest = {};
      let amount: any = 0;
      paymentRequest.name = memberDetail.memberName;
      paymentRequest.email = memberDetail.memberEmail !== "" ? memberDetail.memberEmail : undefined;
      paymentRequest.phoneNumber = memberDetail.mobileNo;
      paymentRequest.address = memberDetail.memberAddress;
      posDetail.netvalue! = Number(posItemDetils?.length && posItemDetils?.reduce((a, v) => a = a + Number(v?.posNetAmount!), 0)?.toFixed(2));
      amount! = posDetail.billValue ? posDetail.billValue : posDetail.netvalue;

      if (posDetail.deliveryCharges !== undefined) {
        posDetail.netvalue = amount! + Number(posDetail.deliveryCharges);
      } else {
        posDetail.netvalue = amount!;
      }
      paymentRequest.amount = Number(posDetail.netvalue!) - billDisounct;

      const response = await posMasterStore.ProceedPayment(paymentRequest);

      const options = {
        amount: paymentRequest.amount,
        name: 'Ganga Super Market',
        description: 'Ganga Super Market',
        image: '../../../image/Ganga_SuperMarket-1.png',
        order_id: response?.orderId,
        handler: async (response) => {
          setLoading({ ...isLoading, isSpinner: true })
          let confirmPaymentPayload: IConfirmPaymentPayload = {}
          confirmPaymentPayload.razorpay_order_id = response?.razorpay_order_id;
          confirmPaymentPayload.razorpay_payment_id = response?.razorpay_payment_id;
          confirmPaymentPayload.razorpay_signature = response?.razorpay_signature;

          const staus = await posMasterStore.confirmPayment(confirmPaymentPayload);

          if (staus === 'Payment Successful') {
            savePOSMaster();
          }
        },
        prefill: {
          name: posDetail?.memberName,
          email: paymentRequest?.email
        },
        theme: {
          color: '#DC3545'
        }
      };

      const _window = window as any;
      const rzp1 = new _window.Razorpay(options);
      rzp1.open();
      setLoading({ ...isLoading, spinnerLoad: false })
    }
    setError(error)
  }

  async function savePOSMaster() {
    posDetail.outletId = Number(Cookies.get('outletId'))
    posDetail.memberCategory = memberDetail.memberCategory;
    posDetail.address = address;
    posDetail.memberId = memberDetail.memberId;
    posDetail.counterNumber = Number(Cookies.get('terminalId'));
    posDetail.terminalId = Number(Cookies.get('terminalId'));
    posDetail.employeeId = Number(Cookies.get('userId'));
    posDetail.PosSalesDetails = posItemDetils;
    posDetail.posBatchDetails = posBatchDetails;
    posDetail.netvalue = calNetvalue();
    posDetail.billValue = (posItemDetils?.length ? posItemDetils?.reduce((a, v) => a = a + v?.posNetAmount!, 0) : 0);
    posDetail.billType = "POS";
    posDetail.billDate = moment(billDate).format('DD-MMM-YYYY hh:mm A');
    posDetail.itemImage = '';
    posDetail.earnedPoints = posDetail?.currentEarnedPoints;
    posDetail.mobileNo = memberDetail.mobileNo;
    posDetail.redeemPoints = posDetail.isReedem ? posDetail.redeemPoints : 0;
    posDetail.redeemPointsamt = (memberDetail?.earnedPoints && posDetail.isReedem) ? posDetail?.redeemPointsamt : 0;
    posDetail.taxValue = posItemDetils?.length ? posItemDetils?.reduce((a, v) => a = a + v?.posTaxValue!, 0) : 0;
    posDetail.discountValue = posItemDetils?.length ? posItemDetils?.reduce((a, v) => a = a + v?.posDiscountValue!, 0) : 0;
    posDetail.othercharges = "5";
    posDetail.offerDiscountValue = billDisounct;
    //posDetail.offerDiscountValue = posDetail.offerDiscountValue;
    let billnoRequest: IEntryNoRequest = {}; billnoRequest.flag = 'POSMaster';
    billnoRequest.outletId = posDetail.outletId;
    let entryNo = await posMasterStore.getPosBillNo(billnoRequest);
    entryNo = entryNo?.billNo.split('-');
    posDetail.billNo = Number(entryNo[1]);
   console.log(posDetail,'posDetail555');
   
    const status = await posMasterStore.savePosMaster(posDetail);
    const invoiceDetails = await posMasterStore.getInvoiceDetails(0)

    if (invoiceDetails) {
      setPosInvoiceDetails(invoiceDetails)
    }
    if (status.statusMessage === "Success") {
      setLoading({ ...isLoading, spinnerLoad: false })
      //posDetail.billNo = undefined;
      setShow(false);
      setSuccessModal(true);
      resetPosDetail();
      // setClearOffers(true)
      fetchPOSProducts()
    } else {
      setLoading({ ...isLoading, spinnerLoad: false })
      setFailureModal(true)
    }

  }

  async function resetPosDetail() {
    let posMaster: IPossalesMaster = {};
    let request: IEntryNoRequest = {};
    request.flag = 'POSMaster';
    request.outletId = Number(Cookies.get('outletId'));
    let posMasterDetails: IPosSalesDetails[] = [];
    setPosDetail(posMaster);
    setOffers(false);
    setPOStItemDetails(posMasterDetails);
    const entryNo = await posMasterStore.getPosBillNo(request);
    posLocalFields.billNo = entryNo?.billNo
    posMaster.billNo = entryNo?.billNo;
    setPosDetail(posMaster);
    setPosLocalFields(posLocalFields)
    setProceed(false)
    let memberDetail: any = {
      mobileNo: '',
      memberId: ''
    }
    setMemberDetail(memberDetail)
  }

  const handleEditItem = (itemId) => {
    setPOStItemDetails(posItemDetils?.filter(m => m.posItemId !== itemId));
    handleItemDetails(null, itemId);
  }

  const handleDeleteItem = (itemId, barcode, batchNo) => {

    setPOStItemDetails((prevItems) => {
      const newItems = [...prevItems];
      const itemIndex = newItems.findIndex((item) => item?.posItemId === itemId);
      if (itemIndex !== -1) {
        newItems.splice(itemIndex, 1);
        // Check if the next item is a free item associated with the removed item
        if (newItems[itemIndex] && newItems[itemIndex]?.posFreeItemId !== undefined) {
          newItems.splice(itemIndex, 1);
        }
      }
      return newItems;
    });

    // setPOStItemDetails(posItemDetils?.filter(m => m.posItemId !== itemId));
    let batchItem: IPosBatchDetails = {
      itemId: itemId,
      barcode: barcode,
      batchNo: batchNo,
      batchQty: 0
    }
    updateBatchList(batchItem, true, null, true)

    // setPOSBatchDetails(posBatchDetails?.filter((batches) => (batches?.barcode != barcode && batches?.itemId != itemId && batches?.batchNo != batchNo)))
  }

  const handleRedeemChange = (e) => {
    const { name, checked } = e.target;
    if (checked) {
      setPosDetail({ ...posDetail, isReedem: checked, billValue: posMasterStore.offers.finalValue })
    } else {
      setPosDetail({ ...posDetail, isReedem: false, billValue: posMasterStore.offers.netBillValue })
    }
  }

  const handleDelivery = () => {
    setDelivery(true);
    const isDelivery = posDetail?.isDelivery ? false : true
    setPosDetail({ ...posDetail, isDelivery: isDelivery });
    if (!isDelivery) {
      setAddress({})
    }
  }

  const holdClose = () => {
    setHold(false);
    // setCheckList(false);
    setDelivery(false);
    setNewAddress(false);
  }

  const handleItemClose = () => {
    setIsItemList({ ...isItemList, bool: false, batchArr: [] })
  }

  const handleAddressInputChange = (e) => {
    const { name, value } = e.target;
    setAddress({ ...address, [name]: value });
  }

  const handleCountryChange = (name, event, val) => {
    let getCities: any;
    getCities = City.getAllCities().filter(m => m.countryCode === val.isoCode);
    setCityOptions(getCities);
    setAddress({ ...address, country: val.name })
  }

  const handleCityChange = (name, event, val) => {
    if (val !== undefined) {
      let getState: any;
      getState = State.getAllStates().find(m => m.countryCode === val.countryCode && m.isoCode === val.stateCode);
      setAddress({ ...address, state: getState.name, city: val.name });
    } else {
      address.city = '';
    }
  }

  const handleNewAddress = () => {
    let error: any = {}
    error = addressValidation(address)
    setError(error);
    if (Object.keys(error).length === 0) {
      setPosDetail({ ...posDetail, isSameAddress: false })
      setNewAddress(false);
    }
  }

  const handleAddress = (e) => {
    setPosDetail({ ...posDetail, isSameAddress: true })
    setDelivery(false);
  }

  const handleInputChange = (e) => {
    let { name, value, checked } = e.target;

    if (name === 'isDelivery' && checked) {
      setDelivery(true);
      posDetail.isDelivery = true;
      setPosDetail({ ...posDetail, isDelivery: posDetail.isDelivery });
    }
    if (name === 'isDelivery' && !checked) {
      posDetail.isDelivery = false;
      value = 0;
      setPosDetail({ ...posDetail, isDelivery: posDetail.isDelivery });
      setAddress({})
    }
    if (name === "deliveryCharge" && value !== undefined) {
      setPosDetail({ ...posDetail, deliveryCharges: Number(value) });
    }
  }

  const handleNewDeliverAddress = () => {
    setDelivery(false);
    setNewAddress(true);
  }

  async function holdPOSView() {
  
    const data = await posMasterStore.getHoldPOS();
    if (data !== undefined) {
      setHold(true)
    }
  }

  const handleHold = async () => {
    let holdMaster: IPOSHoldDetails = {};
    holdMaster.posHoldTerminalId = Number(Cookies.get('terminalId'))
    holdMaster.posHoldEmployeeId = Number(Cookies.get('userId'));
    holdMaster.posHoldMemberId = Number(memberDetail.memberId ?? 0);
    holdMaster.posHoldItemDetails = posItemDetils;
    holdMaster.posBatchDetails = posBatchDetails as any
    if (posItemDetils?.length) {
      setLoading({ initialLoad: false, spinnerLoad: true, isSpinner: false })
      const holdStatus = await posMasterStore.holdcarts(holdMaster);
      setPOStItemDetails([])
      setPOSBatchDetails([])
      setLoading({ initialLoad: false, spinnerLoad: false, isSpinner: false })
    }
  }

  const holdMovetoBill = async (holdId) => {
   
     setLoading({ ...isLoading, initialLoad: true });
    const data = await posMasterStore.getHoldBillDetail(holdId);
    console.log(data,'data555');
    
    if (data !== undefined) {
      setLoading({ ...isLoading, initialLoad: false });
      setHold(false);
      mapPosDetails(data);
    }
  }

  // async function deleteHoldBill(holdId) {
  //   setLoading({ ...isLoading, isSpinner: true });
  //   const status = await posMasterStore.deleteHoldBill(holdId);
  //   if (status === 'Success') {
  //     setLoading({ ...isLoading, isSpinner: false });
  //     setSuccessDeleteModal(true)
  //   } else {
  //     setLoading({ ...isLoading, isSpinner: false })
  //     setFailureModal(true)
  //   }
  // }

  async function mapPosDetails(holdDetails) {
    console.log(holdDetails,'holdDetails55');
    
    let posBillbo: IEntryNoRequest = {};
    posBillbo.flag = 'POSMaster';
    posBillbo.outletId = Number(Cookies.get('outletId'));
    posDetail.memberId = holdDetails.memberId;
    posDetail.memberName = holdDetails.memberName;
    posDetail.mobileNo = holdDetails.mobileNo;
    posDetail.memberType = holdDetails.memberType;
    posDetail.memberCategory = holdDetails.memberCategory;
    posDetail.memberAddress = holdDetails.memberAddress;
    posDetail.employeeId = holdDetails.employeeId;
    posDetail.employeeName = holdDetails.employeeName;
    posDetail.counterNumber = holdDetails.counterNumber;
    posDetail.holdid=holdDetails.holdid;

    const entryNo = await posMasterStore.getPosBillNo(posBillbo);
    const billNo = entryNo?.billNo?.split('-');
    posDetail.billNo = billNo[1];
    setPosDetail(posDetail);
    holdDetails?.PosSalesDetails?.map((holdItems: IPOSProductDetails) => {
      posProductList?.map((products) => {
        if (products?.itemid === holdItems?.posItemId && products?.batchno === holdItems?.posbatchNo) {
          holdItems['posBarcode'] = products?.Barcode;
          holdItems['availableQty'] = products?.stock;
          holdItems['posSubCategoryId'] = Number(products?.subcategory);
          return holdItems;
        }
      })
    });

    let posItemDetails: IPOSProductDetails[] = holdDetails?.PosSalesDetails;
    setPOStItemDetails(posItemDetails!);

    let posBatchDetails = holdDetails.posBatchDetails;
    setPOSBatchDetails(posBatchDetails)

    await posMasterStore.getPOSCustomerDetails(holdDetails?.mobileNo)
    setMemberDetail({
      ...memberDetail, memberId: posMasterStore.posCustomerDetails.memberId,
      memberType: posMasterStore.posCustomerDetails.memberType, memberCategory: posMasterStore.posCustomerDetails.memberCategory,
      memberName: posMasterStore.posCustomerDetails.memberName, memberAddress: posMasterStore.posCustomerDetails.memberAddress,
      mobileNo: holdDetails?.mobileNo, memberEmail: posMasterStore.posCustomerDetails.memberEmail, earnedPoints: posMasterStore.posCustomerDetails.earnedPoints,
      balanceEarnedPoints: posMasterStore.posCustomerDetails.balanceEarnedPoints, redeemPoints: posMasterStore.posCustomerDetails.redeemPoints
    })

  }

  const handleSuccessModalClose = async () => {
    let printContents = document.getElementById('printablediv')!.innerHTML;
    const printWindow = window.open('', '_blank', 'width=900,height=700');
    if (printWindow) {
      printWindow.document.write(`
        <html>
          <head>
            <title>Print</title>
            <style>
              body { font-family: Arial, sans-serif; }
              /* Add other styles if needed */
            </style>
          </head>
          <body>
            ${printContents}
          </body>
        </html>
      `);
      printWindow.document.close();
      printWindow.onload = function () {
        printWindow.print();
        printWindow.close();
      };
    }
    // let printContents = document.getElementById('printablediv')!.innerHTML;
    // let originalContents = document.body.innerHTML;
    // document.body.innerHTML = printContents;
    // window.print();
  }



  function calcRoundOff() {
    const total = posInvoiceDetails?.posbilldetailsprint?.reduce((a, v) => a = a + v.amount!, 0)
    const decimalValue = Math.floor(total);
    const roundOff = total - decimalValue;
    const roundoffValue = total % 1 >= 0.5 ? `+ ${roundOff?.toFixed(2)}` : `- ${roundOff?.toFixed(2)}`
    // const roundOffDiff = roundOff > 0 ? `+ ${roundOff}` : `- ${roundOff}`
    return roundoffValue
  }

  function calcTotalAmt() {
    const total: number = posInvoiceDetails?.posbilldetailsprint?.reduce((a, v) => a = a + v.amount!, 0) - Number(posInvoiceDetails?.offerdiscount) - Number(posInvoiceDetails?.redeemPointsamt)
    // const roundoffValue = total % 1 >= 0.5 ? 1 : -1;
    const roundedTotal = Math.round(total);
    const totalAmount = roundedTotal + Number(posInvoiceDetails?.deliverycharge);

    return totalAmount;
  }

  function calNetvalue(): number {
    let netValue = posItemDetils?.length ? posItemDetils?.reduce((a, v) => a = a + v?.posNetAmount!, 0
      + Number(posDetail.deliveryCharges ?? 0)
      - billDisounct
      - (posDetail?.isReedem! ? posDetail?.redeemPointsamt! : 0)
      + paymentRoundOff)?.toFixed(2) : 0;

    return Number(netValue)
  }

  function handleInvoiceView() {
    debugger
    generateInVoiceList()
    setCutomersInvoice(true)
  }

  async function generateInVoiceList(fromdate?: string, mobileno?: string, billno?: string) {
    const outletid = Number(Cookies.get('outletId'));
    // let today = fromdate ? fromdate : moment().format("YYYY-MM-DD");
    let today;
    if (mobileno || billno) {
      today = fromdate ? fromdate : "";
    } else {
      today = fromdate ? fromdate : moment().format("YYYY-MM-DD")
    }

    let mobno = mobileno ? Number(mobileno) : 0;
    let bno = billno ? billno : "";
    let body: any = {}
    body["mobileno"] = mobno;
    body["billno"] = bno;
    body["outletid"] = outletid;
    body["fromdate"] = today;
    console.log(body, 'body');

    const invoiceList = await posMasterStore.getInvoiceList(body)
    setInvoiceList(JSON.parse(invoiceList))
  }

  async function invoicePrint(posid) {
    const invoiceDetails = await posMasterStore.getInvoiceDetails(posid)
    console.log(invoiceDetails, 'invoiceDetails');

    if (invoiceDetails) {
      setPosInvoiceDetails(invoiceDetails)
      setSuccessModal(true);
    }
    setCutomersInvoice(false)
  }

  const isInitialRender = useRef(true);

  async function fetchPOSProducts() {
    let outletId = Number(Cookies.get('outletId'));
    if (outletId) {
      const posProductList = await posMasterStore.loadPOSProductList(outletId);
      setPosProductList([])
      setPosProductList([...posProductList])
    }
  }

  async function fetchPreLoadingData() {
    const userRights = await Promise.all([userCreationStore?.getUserRight('POS')])
    console.log(userRights, 'userRightschk');
    setRightsObj([userRights]);
    console.log(userRights[0]?.add, 'userRights');

    if (userRights[0]?.add || userRights[0]?.edit || userRights[0]?.delete || userRights[0]?.print || userRights[0]?.update || userRights[0]?.view) {
      setAccessModal(false);
      setLoadModal(false);
    } else {
      setAccessModal(true);
      setLoadModal(true);
    }
    let request: IEntryNoRequest = {}
    if (Cookies.get('outletId') !== undefined) {
      request.outletId = 1;
      request.flag = 'POSMaster'
    }
    setLoading({ ...isLoading, initialLoad: false })
    const [entryNo, posProductList, posFreeItems] = await Promise.all([
      getPosBillNo(request), loadPOSProductList(request?.outletId),
      getPosFreeItems(),
      getPosItemMaster(),
    ])
    setPosProductList(posProductList)
    // let updatedList = posItemOffer?.map((each) => {
    //   if (each?.offerid) {
    //     each.offerdid = each?.offerid
    //     return each
    //   }
    //   return each
    // })
    // console.log(posItemOffer, 'posItemOffer');
    // setPosItemOffers([...updatedList])
    setPosFreeItems(posFreeItems)
    let getAllCountries: any;
    getAllCountries = Country.getAllCountries();

    setPosLocalFields({ ...posLocalFields, billNo: entryNo?.billNo });

    if (getAllCountries?.length) {
      setCountryOptions(getAllCountries);
    }
  }

  const [inputValue, setInputValue] = useState('');
  const [billDate, setBillDate] = useState(new Date().toISOString().substring(0, 10));
  console.log(billDate, 'billDate');

  const handleDateChange = (event) => {
    setBillDate(event.target.value);
  };

  interface Item {
    itemName: string;
  }

  const filteredOptions: Item[] = (Array.isArray(posMasterStore?.posItemMaster) ?
    posMasterStore.posItemMaster.filter((option: Item) => {
      if (!option || !option.itemName) return false;

      const normalizedInput = inputValue.trim().toLowerCase();
      const normalizedItemName = option.itemName.trim().toLowerCase();
      return normalizedItemName.includes(normalizedInput);
    }) : []) || [];


  console.log(filteredOptions, 'filteredOptions');

  useEffect(() => {
    if (isInitialRender.current) {
      fetchPreLoadingData();
      isInitialRender.current = false;
    }
    return () => { };
  }, [])

  const billDisounct: number = useMemo(() => {
    let offerDiscount: number = 0;
    let totalOfferDiscount = 0;
    if (posItemOffers?.length > 0 && posItemDetils?.length > 0 && posItemDetils?.every((obj) => obj?.posItemId != 0)) {
      let billlValue = posItemDetils?.reduce((a, v) => a = a + v?.posNetAmount!, 0)
      posItemOffers?.map((offers) => {
        if (new Date() < new Date(offers?.effectivetoDate)) {
          if (offers?.AssinOfferType === "billValue" || offers?.AssinOfferType === "everyBilling") {
            console.log(offers, 'offers');
            if (billlValue >= offers?.From_QV && billlValue <= offers?.To_QV) {
              if (offers?.Dis_Mode === "Dis%") {
                offerDiscount = Number(billlValue) * (Number(offers?.Discount_PV) / 100)
                console.log(billlValue, Number(offers?.Discount_PV), offerDiscount, 'offerDiscount');
              } else {
                offerDiscount = Number(offers?.Discount_PV)
                console.log(offerDiscount, 'offerDiscountper');
              }
              totalOfferDiscount += offerDiscount;
              offerDiscount = 0;
              if (offers?.offerid && !(assignedOffers?.some(offer => offer?.offerid === offers?.offerid))) {
                setAssignedOff([...assignedOffers, offers])
              }
            } else {
              offerDiscount = 0;
              let index = assignedOffers?.findIndex((obj) => obj?.offerid === offers?.offerid)
              if (index >= 0) {
                assignedOffers?.splice(index, 1)
                setAssignedOff([...assignedOffers])
              }
            }
          }
        }
      })
    }
    return totalOfferDiscount;
  }, [posItemDetils, posItemOffers])


  const paymentRoundOff: number = useMemo(() => {
    let total: number = 0;
    let decimal = 0;
    let roundOff: number = 0;
    total = posItemDetils?.reduce((a, v) => a = a + v?.posNetAmount!, 0 + Number(posDetail.deliveryCharges ?? 0) - billDisounct - (posDetail?.isReedem! ? posDetail?.redeemPointsamt! : 0))
    decimal = Math.round(total);
    roundOff = total - decimal;
    let plusMinus = - roundOff;
    return plusMinus;
  }, [posItemDetils, posItemOffers, posDetail?.isReedem])

  const convertArrayToMap = (students: any[]): Map<string, any[]> => {
    return students?.reduce((map, student) => {
      if (!map.has(student?.taxper)) {
        map.set(student?.taxper, []);
      }
      map.get(student?.taxper)!.push(student);
      return map;
    }, new Map<string, any[]>());
  };

  const payabaleAmount: number = useMemo(() => {
    if (posItemDetils?.length) {
      return posItemDetils?.reduce((a, v) => a = a + v?.posNetAmount!, 0 + Number(posDetail.deliveryCharges ?? 0) - (billDisounct ?? 0) - (posDetail?.isReedem! ? posDetail?.redeemPointsamt! : 0) + (paymentRoundOff ?? 0))
    }
    return 0
  }, [posItemDetils])


  if (isLoadModal) {
    return (
      <div className="container">
        {isAccessModal && <h1>Access Denied</h1>}
        <Modal show={isAccessModal} onHide={handleAccessModalClose} className='SubmitModal'>
          <Modal.Body>
            <div className='Details Success'>
              <div className='imgBox'>
                <Image src={require('../../../gsmecom/images/warning.png')} />
              </div>
              <h4>Access Denied</h4>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleAccessModalClose}>Ok</Button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
  else {
    return (
      <>
        {isLoading?.initialLoad ?
          <ProgressBar /> :
          <Container fluid className='Pos full-height'>
            {isLoading?.isSpinner ?
              <ProgressBar /> :
              <div className="row">
                {isLoading?.spinnerLoad ?
                  <div className='SpinnerBox'>
                    <Spinner animation="grow" size="sm" />
                  </div> :
                  null
                }
                <div className="flex-center-between">
                  <div>
                    <TextField size="small" placeholder="Bill No" value={posLocalFields?.billNo ?? ""} />
                    <div className='vertical-space-20'></div>
                  </div>
                  {/* <div>
                  <TextField size="small" placeholder="Bill Date" value={new Date()?.toISOString()?.substr(0, 10)} />
                  <div className='vertical-space-20'></div>
                </div> */}
                  <div>
                    <input
                      type="date"
                      value={billDate}
                      onChange={handleDateChange}
                      placeholder="Bill Date"
                      style={{
                        fontSize: '16px',
                        height: '40px',
                        padding: '8px',
                        boxSizing: 'border-box'
                      }}
                    />
                    <div className='vertical-space-20'></div>
                  </div>
                  {/* <div>
                  <Autocomplete
                    options={posMasterStore?.posItemMaster}
                    getOptionLabel={(option: any) => option?.itemName}
                    onChange={(event, val) => handleItemDetails(val, '')}
                    sx={{ width: 300 }}
                    renderInput={(params) => <TextField {...params} size="small" sx={{ height: '100%' }} placeholder="Select Product / Item" />}
                  />
                  {errors?.itemId ? <p style={{ color: 'red', marginBottom: '0' }}>{errors?.itemId}</p> : <div className='vertical-space-20'></div>}
                </div> */}
                  <div>
                    <AutoComplete
                      placeholder="Select Product / Item"
                      emitOption={(val) => handleItemDetails(val, '')}
                      // value={itemInward?.inwardItemName}
                      options={removeDuplicates(filteredOptions, 'itemName')}
                      getOptionLabel="itemName"
                      clsName="full-width"
                    />
                    {/* 
                  <Autocomplete
                      options={filteredOptions}
                      getOptionLabel={(option: Item) => option.itemName} // Specify the type here
                      onChange={(event, val) => handleItemDetails(val, '')}
                      onInputChange={(event, value) => setInputValue(value)} // Update input value
                      sx={{ width: 300 }}
                      renderInput={(params) => (
                          <TextField 
                              {...params} 
                              size="small" 
                              sx={{ height: '100%' }} 
                              placeholder="Select Product / Item" 
                          />
                      )}
                  /> */}
                    {errors?.itemId ? (
                      <p style={{ color: 'red', marginBottom: '0' }}>{errors.itemId}</p>
                    ) : (
                      <div className='vertical-space-20'></div>
                    )}
                  </div>
                  <div>
                    <TextField size="small" placeholder="Enter Barcode"
                      value={barcode}
                      onChange={(e) => setBarcode(e.target.value)}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="start" >
                            <EastRoundedIcon onClick={() => getPosBatchStocks(barcode)} />
                          </InputAdornment>
                        )
                      }}
                    />
                    <div className='vertical-space-20'></div>
                  </div>
                  <div>
                    <TextField size="small" placeholder="Mobile No" onChange={handleMemberDetails} name="mobileNo" value={memberDetail?.mobileNo} />
                    {errors?.memberId ? <p style={{ color: 'red', marginBottom: '0' }}>{errors?.memberId}</p> : <div className='vertical-space-20'></div>}
                  </div>
                  <div>
                    <Button onClick={handleReplacemtn} style={{ fontSize: '14', color: 'white', whiteSpace: 'nowrap', background: '#dc3545' }}>Replacement</Button>
                    <div className='vertical-space-20'></div>
                  </div>
                  <div>
                    <Button onClick={() => setNewMemberModal(true)} style={{ fontSize: '14', color: 'white', whiteSpace: 'nowrap', background: '#0ac074' }}>New Member</Button>
                    <div className='vertical-space-20'></div>
                  </div>
                  <div>
                    <Button onClick={handleHold} style={{ fontSize: '14', color: 'white', whiteSpace: 'nowrap', background: '#ff679b' }}>Hold</Button>
                    <div className='vertical-space-20'></div>
                  </div>
                  <div>
                    <Button onClick={holdPOSView} style={{ fontSize: '14', color: 'white', whiteSpace: 'nowrap', background: '#0228ff' }}>Unhold</Button>
                    <div className='vertical-space-20'></div>
                  </div>
                  <div>
                    <Button onClick={handleInvoiceView} style={{ fontSize: '14', color: 'white', whiteSpace: 'nowrap', background: '#0b3f8f' }}>View Bill</Button>
                    <div className='vertical-space-20'></div>
                  </div>
                  <div>
                    <Button onClick={processOrder} style={{ fontSize: '14', color: 'white', whiteSpace: 'nowrap', background: '#0ac074' }}>Proceed</Button>
                    <div className='vertical-space-20'></div>
                  </div>
                </div>
                <div className="overflow-auto" style={{ maxHeight: '550px' }}>
                  <table className="table table-striped min-w-full">
                    <thead>
                      <tr >
                        <th scope="col"></th>
                        <th scope="col">S.No</th>
                        <th scope="col">Product</th>
                        <th scope="col">Batchno</th>
                        <th scope="col">Qty</th>
                        <th scope="col">MRP</th>
                        <th scope="col">Sell.Price</th>
                        <th scope="col">Tax</th>
                        <th scope="col">Dis</th>
                        <th scope="col">Total</th>
                        <th scope="col">Net Amount</th>
                        <th scope="col"></th>
                        <th scope="col"></th>
                        <th scope="col"></th>
                      </tr>
                    </thead>
                    <tbody>
                      {posItemDetils?.map((val, key) => {
                        return (val?.posItemQty! > 0) && (
                          <tr key={key}>
                            <td
                              style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', background: '#dc3545', color: 'white', borderRadius: '3px', fontSize: '30px', padding: '8px 18px', cursor: 'pointer' }}
                              onClick={() => { val?.posItemQty! > 1 && useCartQuantityUpdater('minus', val, 1) }}
                            >
                              -
                            </td>
                            <td>{val.postRowId = key + 1}</td>
                            <td> {val.posItemName}</td>
                            <td> {val.posbatchNo}</td>
                            <td>{val.posItemQty}</td>
                            <td> {val.posItemMRP} </td>
                            <td> {val.posItemSellingRate}</td>
                            <td> {val.posTaxValue! + '(' + Number(val.posTaxPercentage) + '%)'}</td>
                            {val?.posDiscountMode === "Value" ?
                              <td> {(val?.posDiscountValue as any)?.toFixed(2)}</td>
                              :
                              <td> {val.posDiscountValue?.toFixed(2) + '(' + val.posDiscountPercentage + '%)'}</td>
                            }
                            <td>{val.posTotalPrice}</td>
                            <td> {val.posNetAmount?.toFixed(2)}</td>
                            <td><button><FontAwesomeIcon icon={faEdit} className='inputPlaceholderIcon' onClick={() => handleEditItem(val.posItemId)} /></button></td>
                            <td><FontAwesomeIcon icon={faTrash} className='inputPlaceholderIcon' onClick={() => handleDeleteItem(val?.posItemId, val?.posBarcode, val.posbatchNo)} /></td>
                            <td
                              onClick={() => { val?.posItemQty! < val?.availableQty! && useCartQuantityUpdater('add', val, 1) }}
                              style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', background: '#0ac074', color: 'white', borderRadius: '3px', fontSize: '30px', padding: '8px 18px', cursor: 'pointer' }}
                            >
                              +
                            </td>
                          </tr>
                        )
                      })}
                    </tbody>
                  </table>
                  {errors.Product && <p style={{ color: 'red' }}>{errors.Product}</p>}
                  {isLoadingDiv ? <div className="loader "> Loading...</div> : <></>}
                </div>

                <Suspense fallback={<div>Loading...</div>}>

                  <MemberDetailModal ismembermodel={memberModal?.isMemberModal}
                    modalClose={() => setMemberModal({ ...memberModal, isMember: false, isMemberModal: false })}
                    ismember={memberModal?.isMember}
                    memberDetail={memberDetail}
                  />

                  <BarcodeBatches isitemmodel={isBarcodeBatches?.bool} memberCatId={memberDetail?.memberCatId!} itemModalClose={() => setBarcodeBatches({ ...isBarcodeBatches, bool: false, batchArr: [] })}
                    itemImage={posDetail?.itemImage} itemBatchNo={isBarcodeBatches?.batchArr} addtoCart={(obj: any) => { useCartQuantityUpdater('add', obj, (Number(obj?.posItemQty) / Number(obj?.posMinSalQty != 0 ? obj?.posMinSalQty : 1))); }} setBarcodeBatches={() => setBarcodeBatches({ ...isBarcodeBatches, bool: false, batchArr: [] })} />

                  {/* {batchModal ? <BatchNoDetailModal isitemmodel={batchModal} itemModalClose={() => { setBatchModal(false); setLoadingDiv(false) }}
                  posDetail={posDetail} itemBatchNo={posBatchDetails} addtoCart={(filArr: any) => addtoCart(filArr)} /> : null} */}

                  <CheckOffers isOffers={isOffers} modalClose={() => setOffers(false)}
                    posMasterStore={posMasterStore} posDetail={posDetail} handleRedeemChange={handleRedeemChange} returnAmt={returnAmt} totOfferAmt={billDisounct} availOffers={posItemOffers} assignedOffers={assignedOffers}
                    posItemDetils={posItemDetils} />

                  {/* <CheckOffers isOffers={isClearOffers} modalClose={() => setOffers(false)}
                    posMasterStore={posMasterStore} posDetail={posDetail} handleRedeemChange={() => {}} returnAmt={0} totOfferAmt={0} availOffers={[]} assignedOffers={[]}
                    posItemDetils={[]} /> */}

                  {show ? <PaymentOptionModal posDetail={posDetail} show={show} returnAmt={returnAmt} payabaleAmount={payabaleAmount} modalClose={() => setShow(false)} handleRadioChange={handleRadioChange} posProductList={posProductList}
                    errors={errors} processtoBill={processtoBill} sudexoAmt={posItemDetils?.reduce((acc, va) => acc = acc + (va?.isSodexo ? Number(va?.posNetAmount! ?? 0) : 0), 0)} /> : null}

                  <DeliveryAddressModal isDelivery={isDelivery} isNewAddress={isNewAddress} holdClose={holdClose} countryOptions={countryOptions}
                    handleCountryChange={handleCountryChange} errors={errors} handleAddressInputChange={handleAddressInputChange}
                    address={address} cityOptions={cityOptions} handleNewAddress={handleNewAddress}
                    handleCityChange={handleCityChange} handleInputChange={handleInputChange} memberDetail={memberDetail}
                    handleAddress={handleAddress} handleNewDeliverAddress={handleNewDeliverAddress} />

                  {/* deleteHoldBill={deleteHoldBill} */}
                  <HoldCustomerModal hold={hold} holdClose={holdClose} getHolds={posMasterStore.getHolds}
                    holdMovetoBill={holdMovetoBill}
                  />

                  <CustomerInvoiceModal
                    hold={customerInvoice}
                    holdClose={() => setCutomersInvoice(false)}
                    getInvoice={invoiceList}
                    invoicePrint={(posid) => invoicePrint(posid)}
                    generateInvoice={(localFields) => generateInVoiceList(localFields?.fromdate, localFields?.mobileno, localFields?.billno)}
                  />

                  {showReplacement ? <SalesReturnModal memberCatId={memberDetail?.memberCatId!} replacement={showReplacement} handleClose={() => setShowReplacement(false)}
                    emitPosItemList={(posItem) => { addToCart(posItem); setReturnAmt(posItem?.returnAmount); setPosDetail({ ...posDetail, replacementposid: posItem?.replacementposid }) }} /> : null}
                </Suspense>
              </div>
            }

            <Modal show={isItemList?.bool} size='lg' onHide={handleItemClose} className='PriceHistoryModel' style={{ marginTop: '15px' }}>
              <Modal.Header closeButton>
                <Modal.Title>Item Details</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div className='container'>
                  <div className='vertical-space-20'></div>
                  <div className="flex">
                    <div className='inputFormBox'>
                      <div className='col-md-12'>
                        <div className='tableBox'>
                          {errors.batchChoosen && <p style={{ color: 'red' }}>{errors.batchChoosen}</p>}
                          <table style={{ overflow: 'auto' }}>
                            <thead>
                              <tr>
                                <th scope="col"> </th>
                                <th scope="col"> Item Name</th>
                                <th scope="col"> Batch No</th>
                                <th scope="col"> MRP</th>
                                <th scope="col">Available Qty</th>
                              </tr>
                            </thead>
                            <tbody>
                              {isItemList?.batchArr?.length > 0 && isItemList?.batchArr?.map((val, key) => {
                                return val.stock > 0 && (
                                  <tr key={key} className='self-center'>
                                    <td>
                                      <input type="checkbox" className='itemCheckbox' onChange={(e) => handlebatchNo(e, val)}></input>
                                    </td>
                                    <td>{val.itemname}</td>
                                    <td>{val.batchno}</td>
                                    <td>{val.Mrprate}</td>
                                    <td>{val?.stock}</td>
                                  </tr>
                                )
                              })
                              }
                            </tbody>
                          </table>
                        </div>
                      </div>
                      <div className='vertical-space-10'></div>
                    </div>
                  </div>
                </div>
              </Modal.Body>
            </Modal>
            <Modal show={isSuccessModal} onHide={() => setSuccessModal(false)} className='PriceHistoryModel' style={{ marginTop: '1px' }}>
              <Modal.Body style={{ width: '350px' }}>
                <div className='Details Success'>
                  <div id='printablediv' className='printablediv'>
                    <div className="address">
                      <h6 style={{ fontSize: '12px', fontFamily: 'system-ui', color: 'black', fontWeight: '700', textAlign: 'center', lineHeight: 1 }}>Ganga Super Market</h6>
                      <p style={{ fontSize: '10px', marginBottom: '13px', lineHeight: 0.5, fontFamily: 'system-ui', color: 'black', fontWeight: '700', textAlign: 'center' }}>3/824 A, Thuraiyur Road, Near Ponni Gas Godown</p>
                      <p style={{ fontSize: '10px', marginBottom: '13px', lineHeight: 0.5, fontFamily: 'system-ui', color: 'black', fontWeight: '700', textAlign: 'center' }}>Namakkal, 33 - Tamil Nadu, 637002</p>
                      <p style={{ fontSize: '10px', marginBottom: '13px', lineHeight: 0.5, fontFamily: 'system-ui', color: 'black', fontWeight: '700', textAlign: 'center' }}>GSTIN / UIN: 33BZUPS6837H1ZL</p>
                      <p style={{ fontSize: '10px', marginBottom: '13px', lineHeight: 0.5, fontFamily: 'system-ui', color: 'black', fontWeight: '700', textAlign: 'center' }}>FSSAI: 10020042007183</p>
                    </div>
                    <div className="taxinvoice">
                      <div style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'space-evenly', lineHeight: 1 }}>
                        <span style={{ fontSize: '10px', marginBottom: '13px', lineHeight: 0.5, fontFamily: 'system-ui', color: 'black', fontWeight: '700' }}>Tax Invoice</span>
                        <span style={{ fontSize: '10px', marginBottom: '13px', lineHeight: 0.5, fontFamily: 'system-ui', color: 'black', fontWeight: '700' }} className='invoiceSpan'>{posInvoiceDetails?.billno}</span>
                        <span style={{ fontSize: '10px', marginBottom: '13px', lineHeight: 0.5, fontFamily: 'system-ui', color: 'black', fontWeight: '700' }} className='invoiceSpan'>{posInvoiceDetails?.billdate}</span>
                      </div>
                      <span style={{ fontSize: '10px', fontFamily: 'system-ui', color: 'black', fontWeight: '700', marginTop: '7px', lineHeight: 1 }} className='invoiceSpan'>{posInvoiceDetails?.paymentmode}</span>
                    </div>
                    <div className="Customer" style={{ marginTop: '0px' }}>
                      <h6 style={{ fontSize: '9px', fontFamily: 'system-ui', color: 'black', fontWeight: '700', lineHeight: 1 }}>Customer:{posInvoiceDetails.memberName}</h6>
                      <p style={{ fontSize: '9px', marginBottom: '13px', lineHeight: 0.5, fontFamily: 'system-ui', color: 'black', fontWeight: '700' }}>Dr.No.: {posInvoiceDetails?.doorNo}{" "}{posInvoiceDetails.area}</p>
                      <p style={{ fontSize: '9px', marginBottom: '13px', lineHeight: 0.5, fontFamily: 'system-ui', color: 'black', fontWeight: '700' }}>{`${posInvoiceDetails?.city}, ${posInvoiceDetails?.state}, ${posInvoiceDetails?.pinCode}`}</p>
                      <p style={{ fontSize: '9px', marginBottom: '13px', lineHeight: 0.5, fontFamily: 'system-ui', color: 'black', fontWeight: '700' }}>Mobile: {posInvoiceDetails?.mobileNo}</p>
                    </div>
                    <hr />
                    <h5 className="address" style={{ fontSize: '9px', color: 'black', lineHeight: 1 }}>SKU - HSNC - Item Description</h5>
                    <table className="itemtable"  >
                      <thead>
                        <tr >
                          <th className="heading" style={{ fontSize: '9px', color: 'black' }}>Item</th>
                          <th className="heading" style={{ fontSize: '9px', color: 'black' }}>Qty</th>
                          <th className="heading" style={{ fontSize: '9px', color: 'black' }}>MRP</th>
                          <th className="heading" style={{ fontSize: '9px', color: 'black' }}>Price</th>
                          <th className="heading" style={{ fontSize: '9px', color: 'black' }}>Disc</th>
                          <th className="heading" style={{ fontSize: '9px', color: 'black' }}>Total</th>
                        </tr>
                      </thead>
                      <tbody>
                        {convertArrayToMap(posInvoiceDetails?.posbilldetailsprint)?.size > 0 ?
                          Array.from(convertArrayToMap(posInvoiceDetails?.posbilldetailsprint))?.map(([key, value], ind) => {
                            return (
                              <>
                                <div style={{ color: 'black', padding: '10px 0px 0px 16px', fontWeight: '700', fontSize: '9px' }}>
                                  {`${ind + 1}) CGST ${key ?? 0}% & SGST ${key ?? 0}%`}
                                </div>
                                {value?.map((val, index) => {
                                  return val?.qty > 0 && (
                                    <tr key={index}>
                                      <td style={{ padding: '0px 15px' }}>
                                        <div style={{ fontSize: '9px', fontFamily: 'system-ui', color: 'black', fontWeight: '700' }}>{val?.hsncode}</div>
                                        <div style={{ fontSize: '9px', fontFamily: 'system-ui', color: 'black', fontWeight: '700' }}>{val?.itemid === 0 ? "Free" : val.itemname}</div>
                                      </td>
                                      <td style={{ fontSize: '9px', padding: 0, fontFamily: 'system-ui', color: 'black', fontWeight: '700' }}>{val.qty}</td>
                                      <td style={{ fontSize: '9px', padding: 0, fontFamily: 'system-ui', color: 'black', fontWeight: '700' }}>{val.mrp}</td>
                                      <td style={{ fontSize: '9px', padding: 0, fontFamily: 'system-ui', color: 'black', fontWeight: '700' }}>{val.sellingPrice}</td>
                                      <td style={{ fontSize: '9px', padding: 0, fontFamily: 'system-ui', color: 'black', fontWeight: '700' }}>{val.discountvalue}</td>
                                      <td style={{ fontSize: '9px', padding: 0, fontFamily: 'system-ui', color: 'black', fontWeight: '700' }}>{val.amount}</td>
                                    </tr>
                                  )
                                })}
                              </>
                            )
                          }) : null}
                      </tbody>
                    </table>
                    <hr style={{ margin: 4 }} />
                    <table className="amounttable">
                      <tr>
                        <td style={{ fontSize: '9px', padding: 0, fontFamily: 'system-ui', color: 'black', fontWeight: '800' }}>Total Qty: {posInvoiceDetails?.posbilldetailsprint?.reduce((a, v) => a = a + v.qty!, 0)}</td>
                        <td style={{ fontSize: '9px', padding: 0, fontFamily: 'system-ui', color: 'black', fontWeight: '800' }}>Total Items: {posInvoiceDetails?.posbilldetailsprint?.length}</td>
                        <td style={{ fontSize: '9px', padding: 0, fontFamily: 'system-ui', color: 'black', fontWeight: '800' }}>Total</td>
                        <td className="tablesummeryvalue" style={{ fontSize: '9px', padding: 0, display: 'flex', color: 'black', justifyContent: 'end' }}>{posInvoiceDetails?.posbilldetailsprint?.reduce((a, v) => a = a + v.amount!, 0)?.toFixed(2)}</td>
                      </tr>
                      <tr>
                        <td style={{ fontSize: '9px', padding: 0, fontFamily: 'system-ui', color: 'black', fontWeight: '800' }}>Delivery Charges</td>
                        <td></td>
                        <td></td>
                        <td className="tablesummeryvalue" style={{ fontSize: '9px', padding: 0, display: 'flex', color: 'black', justifyContent: 'end' }}>{posInvoiceDetails?.deliverycharge}</td>
                      </tr>
                      <tr>
                        <td style={{ fontSize: '9px', padding: 0, fontFamily: 'system-ui', color: 'black', fontWeight: '800' }}>Round off:</td>
                        <td></td>
                        <td></td>
                        <td className="tablesummeryvalue" style={{ fontSize: '9px', padding: 0, display: 'flex', color: 'black', justifyContent: 'end' }}>{calcRoundOff()}</td>
                      </tr>
                      <tr>
                        <td style={{ fontSize: '9px', padding: 0, fontFamily: 'system-ui', color: 'black', fontWeight: '800' }}>Redeem Amount</td>
                        <td> </td>
                        <td></td>
                        <td style={{ fontSize: '9px', padding: 0, display: 'flex', color: 'black', justifyContent: 'end' }}>{posInvoiceDetails?.redeemPointsamt}</td>
                      </tr>
                      {(returnAmt != 0) ? <tr>
                        <td style={{ fontSize: '9px', padding: 0, color: 'black', }} className="tablesummeryvalue">Return Amount</td>
                        <td> </td>
                        <td></td>
                        <td className="tablesummeryvalue" style={{ fontSize: '9px', padding: 0, display: 'flex', color: 'black', justifyContent: 'end' }}>{(Number(returnAmt ?? 0))?.toFixed(2)}</td>
                      </tr> : null}
                      {(Number(posInvoiceDetails?.salesreturn) != 0) ? <tr>
                        <td style={{ fontSize: '9px', padding: 0, color: 'black', }} className="tablesummeryvalue">Return Amount</td>
                        <td> </td>
                        <td></td>
                        <td className="tablesummeryvalue" style={{ fontSize: '9px', padding: 0, display: 'flex', color: 'black', justifyContent: 'end' }}>{(Number(posInvoiceDetails?.salesreturn ?? 0))?.toFixed(2)}</td>
                      </tr> : null}
                      <tr>
                        <td style={{ fontSize: '9px', padding: 0, color: 'black', }} className="tablesummeryvalue">Amount Paid</td>
                        <td> </td>
                        <td></td>
                        <td className="tablesummeryvalue" style={{ fontSize: '9px', padding: 0, display: 'flex', color: 'black', justifyContent: 'end' }}>{(calcTotalAmt() - Number(returnAmt ?? 0))?.toFixed(2)}</td>
                      </tr>
                      <tr>
                        <td style={{ fontSize: '9px', padding: 0, color: 'black', }} className="tablesummeryvalue">You Saved Today</td>
                        <td> </td>
                        <td></td>
                        <td className="tablesummeryvalue" style={{ fontSize: '9px', padding: 0, color: 'black', display: 'flex', justifyContent: 'end' }}>
                          {(posInvoiceDetails?.posbilldetailsprint?.reduce((a, v) => a = a + (v.discountvalue!), 0 + Number(posInvoiceDetails?.offerdiscount)))?.toFixed(2)}
                        </td>
                      </tr>
                    </table>
                    <table className="itemtable">
                      <thead>
                        <tr>
                          <th className="heading" style={{ fontSize: '9px', color: 'black' }}>S.No</th>
                          <th className="heading" style={{ fontSize: '8px', color: 'black', }}>Taxable <br /> amount</th>
                          <th className="heading" style={{ fontSize: '8px', color: 'black', }}>CGST</th>
                          <th className="heading" style={{ fontSize: '8px', color: 'black', }}>SGST</th>
                          <th className="heading" style={{ fontSize: '8px', color: 'black', }}>IGST</th>
                          <th className="heading" style={{ fontSize: '8px', color: 'black', }}>CESS</th>
                          <th className="heading" style={{ fontSize: '8px', color: 'black', }}>Total <br /> amount</th>
                        </tr>
                      </thead>
                      <tbody>
                        {posInvoiceDetails?.posbilltaxdetailsprint?.map((val, key) => {
                          return (
                            <tr key={key}>
                              <td style={{ fontSize: '8px', padding: 0, fontFamily: 'system-ui', color: 'black', fontWeight: '800' }}>{key + 1}</td>
                              <td style={{ fontSize: '8px', padding: 0, fontFamily: 'system-ui', color: 'black', fontWeight: '800' }}>{(Number(val?.netvalue ?? 0) - val?.taxvalue)?.toFixed(2)}</td>
                              <td style={{ fontSize: '8px', padding: 0, fontFamily: 'system-ui', color: 'black', fontWeight: '800' }}>{`${val?.cgstvalue}` + `(${val?.cgstper}%)`}</td>
                              <td style={{ fontSize: '8px', padding: 0, fontFamily: 'system-ui', color: 'black', fontWeight: '800' }}>{`${val?.sgstvalue}` + `(${val?.sgstper}%)`}</td>
                              <td style={{ fontSize: '8px', padding: 0, fontFamily: 'system-ui', color: 'black', fontWeight: '800' }}></td>
                              <td style={{ fontSize: '8px', padding: 0, fontFamily: 'system-ui', color: 'black', fontWeight: '800' }}></td>
                              <td style={{ fontSize: '8px', padding: 0, fontFamily: 'system-ui', color: 'black', fontWeight: '800' }}>{(val?.netvalue)?.toFixed(2)}</td>
                            </tr>
                          )
                        })
                        }
                        <tr style={{ fontSize: '9px', margin: '1rem 0', color: 'inherit', border: 0, borderTop: '1px solid rgb(11,56,93, 0.25)' }}></tr>
                        <tr>
                          <td style={{ fontSize: '9px', padding: 0, fontFamily: 'system-ui', color: 'black', fontWeight: '800' }}>Total</td>
                          <td style={{ fontSize: '9px', padding: 0, fontFamily: 'system-ui', color: 'black', fontWeight: '800' }}>{(posInvoiceDetails?.posbilldetailsprint?.reduce((a, v) => a = a + (v?.amount! - v.taxvalue!), 0))?.toFixed(2)}</td>
                          <td style={{ fontSize: '9px', padding: 0, fontFamily: 'system-ui', color: 'black', fontWeight: '800' }}>{posInvoiceDetails?.posbilltaxdetailsprint?.reduce((a, v) => a = a + v?.cgstvalue!, 0)?.toFixed(2)}</td>
                          <td style={{ fontSize: '9px', padding: 0, fontFamily: 'system-ui', color: 'black', fontWeight: '800' }}>{posInvoiceDetails?.posbilltaxdetailsprint?.reduce((a, v) => a = a + v?.sgstvalue!, 0)?.toFixed(2)}</td>
                          <td></td>
                          <td></td>
                          <td style={{ fontSize: '9px', padding: 0, fontFamily: 'system-ui', color: 'black', fontWeight: '800' }}>{(posInvoiceDetails?.posbilldetailsprint?.reduce((a, v) => a = a + v.amount!, 0))?.toFixed(2)}</td>
                        </tr>
                      </tbody>
                    </table>
                    <div style={{ display: posInvoiceDetails?.posreplacementprint?.length ? 'block' : 'none' }}>
                      <h6 className="address" style={{ color: 'black', fontSize: '9px', marginTop: '20px', marginBottom: '20px' }}>Replacement Item</h6>
                      <table className="itemtable">
                        <thead>
                          <tr >
                            <th className="heading" style={{ fontSize: '9px', color: 'black' }}>Item</th>
                            <th className="heading" style={{ fontSize: '9px', color: 'black' }}>Qty</th>
                            <th className="heading" style={{ fontSize: '9px', color: 'black' }}>Amount</th>
                          </tr>
                        </thead>
                        <tbody>
                          {posInvoiceDetails?.posreplacementprint?.map((val, index) => {
                            return val?.qty > 0 && (
                              <tr key={index}>
                                <td style={{ fontSize: '9px', padding: 0, fontFamily: 'system-ui', color: 'black', fontWeight: '700' }}>{val.itemname}</td>
                                <td style={{ fontSize: '9px', padding: 0, fontFamily: 'system-ui', color: 'black', fontWeight: '700' }}>{val.qty}</td>
                                <td style={{ fontSize: '9px', padding: 0, fontFamily: 'system-ui', color: 'black', fontWeight: '700' }}>{val.amount}</td>
                              </tr>
                            )
                          })}
                        </tbody>
                      </table>
                    </div>
                    <div className="bottom">
                      <h4 style={{ fontSize: '9px', color: 'black', }}>Thank You</h4>
                      <p style={{ fontSize: '9px', color: 'black', }}>Customer Care: 9043426746</p>
                    </div>
                  </div>
                </div>
              </Modal.Body>
              <Modal.Footer>


                <Button variant="secondary" onClick={handleSuccessModalClose}>
                  Print
                </Button>
                <Button variant="secondary" onClick={handleAccessModalClose}>
                  Close
                </Button>
              </Modal.Footer>
            </Modal>

            <Modal show={isFailureModal} onHide={() => setFailureModal(false)} className='SubmitModal'>
              <Modal.Body>
                <div className='Details Success'>
                  <div className='imgBox'>
                    <Image src={require('../../../../image/warning.png')} />
                  </div>
                  <h4>Failed</h4>
                </div>
              </Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={() => setFailureModal(false)}>
                  Ok
                </Button>
              </Modal.Footer>
            </Modal>

            <Modal show={isSuccessDeleteModal} onHide={() => setSuccessDeleteModal(false)} className='SubmitModal'>
              <Modal.Body>
                <div className='Details Success'>
                  <div className='imgBox'>
                    <Image src={require('../../../../image/checked.png')} />
                  </div>
                  <h4>Succesfully Deleted</h4>
                </div>
              </Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={() => setSuccessDeleteModal(false)}>
                  Ok
                </Button>
              </Modal.Footer>
            </Modal>

            <Modal show={newMemberModal} onHide={() => setNewMemberModal(false)} className='PriceHistoryModel' style={{ marginTop: "0px", height: '90vh', overflow: 'auto' }}>
              <Modal.Header closeButton>
                <h6>New Member Registration</h6>
              </Modal.Header>
              <Modal.Body>
                <NewMember fromPOS={true} />
              </Modal.Body>
            </Modal>

            <div className="footerBottomReach">

              <div className="bottomDiv">

                <div className="">
                  Dis Amount : <span><span>&#8377;</span> {posItemDetils?.length ? posItemDetils?.reduce((a, v) => a = a + (v?.posDiscountValue!), 0 + billDisounct)?.toFixed(2) : 0}</span>
                  {/* Dis Amount : <span><span>&#8377;</span> {posItemDetils?.length ? posItemDetils?.reduce((a, v) => a = a + (v?.posItemQty! * v?.posDiscountValue!), 0 + billDisounct)?.toFixed(2) : 0}</span> */}
                </div>
                <div className="">
                  Tax Amount : <span><span>&#8377;</span> {posItemDetils?.length ? posItemDetils?.reduce((a, v) => a = a + (v?.posTaxValue!), 0)?.toFixed(2) : 0}</span>
                  {/* Tax Amount : <span><span>&#8377;</span> {posItemDetils?.length ? posItemDetils?.reduce((a, v) => a = a + (v?.posItemQty! * v?.posTaxValue!), 0)?.toFixed(2) : 0}</span> */}
                </div>
                {posDetail?.deliveryCharges && <div>Delivery Charge : <span><span>&#8377;</span> {posDetail.deliveryCharges}</span></div>}
                {posDetail?.deliveryCharges ?
                  <div>Bill Amount : <span><span>&#8377;</span> {posItemDetils?.length ? posItemDetils?.reduce((a, v) => a = a + v?.posNetAmount!, 0 + Number(posDetail.deliveryCharges))?.toFixed(2) : 0}</span></div> :
                  <div>Bill Amount : <span><span>&#8377;</span> {posItemDetils?.length ? posItemDetils?.reduce((a, v) => a = a + v?.posNetAmount!, 0)?.toFixed(2) : 0}</span></div>
                }
                {(posDetail?.redeemPointsamt && posDetail?.isReedem) ? <div>
                  Redemption : <span><span>&#8377;</span>{posDetail?.redeemPointsamt?.toFixed(2)}</span>
                </div> : null}
                {(returnAmt != 0) ? <div>
                  Return Amount : <span><span>&#8377;</span>{Number(returnAmt)?.toFixed(2)}</span>
                </div> : null}
                <div>
                  Round Off : <span><span>&#8377;</span>{paymentRoundOff?.toFixed(2)}</span>
                </div>

                {posDetail.deliveryCharges ?

                  <div>Payable Amount : <span><span>&#8377;</span> {posItemDetils?.length ? posItemDetils?.reduce((a, v) => a = a + v?.posNetAmount!, 0 + Number(posDetail.deliveryCharges) - Number(billDisounct) - (posDetail?.isReedem! ? posDetail?.redeemPointsamt! : 0) + Number(paymentRoundOff))?.toFixed(2) : 0}</span></div> :
                  <div>Payable Amount : <span><span>&#8377;</span> {posItemDetils?.length ? posItemDetils?.reduce((a, v) => a = a + v?.posNetAmount!, 0 - billDisounct - (posDetail?.isReedem! ? posDetail?.redeemPointsamt! : 0) + paymentRoundOff)?.toFixed(2) : 0}</span></div>
                }
              </div>
              <button
                onClick={checkOffers}
                style={{ background: '#0ac074', border: 'none', borderRadius: '6px', padding: '0 16px', color: 'white', fontSize: '12px', position: 'relative' }}
              >Check Offers
                {!isProceed && <span className='animate__animated bounce animate__infinite check-offers'>
                  Click check offers to proceed bill
                </span>}
              </button>
              <button
                onClick={handleDelivery}
                name='isDelivery'
                style={{ background: '#0ac074', border: 'none', borderRadius: '6px', padding: '0 16px', color: 'white', fontSize: '12px' }}
              >Delivery address
              </button>
            </div>
          </Container>
        }
      </>
    )
  }
})

export default ItemPossales;