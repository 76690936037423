
function inwardOutletValidation(inwardOutlet){
    let errors :any={};
      // if(!inwardOutlet.inwardOutletDate){
      //   errors.inwardOutletDate = "Select InwardOutlet Date";
      // }
      if(!inwardOutlet.itemName){
        errors.itemName = "Select Item Name";
      }
      if(!inwardOutlet.outwardOutletNo){
        errors.outwardOutletNo = "Select Outward No";
      }
      else if(inwardOutlet.outwardOutletNo!==undefined){
        if(!inwardOutlet.itemName){
            errors.itemName = "Select Item Name";
          }
      }
      if(!inwardOutlet.outletId){
        errors.outletId = "Select Outler Name";
      }
    return errors;
  };
    
    export default inwardOutletValidation;