import { runInAction, set, makeObservable, observable } from 'mobx';
import outletItemRequest, { IOutletItemRequest } from '../model/index';
import apiAgent from '../../../APIAgent';

class OutletItemRequestStore {
    @observable outletItemRequestDetls = outletItemRequest;
    @observable outletItemRequestList = new Map();
    @observable outletItemRequestDetails = new Map();
    isOutletRequest = true;

    saveOutletItemRequest = async (outletItemRequest: IOutletItemRequest) => {
        try {
            const status = await apiAgent.outletItemRequest.saveOutletItemRequest(outletItemRequest);
            if (status === "Success") {
                return status;
            }
        }
        catch (error) {
            console.log(error);
        }
    }
    getOutletItemRequest = async () => {
        try {
            const outletItemData = await apiAgent.outletItemRequest.getOutletItemRequest();
            runInAction(() => {
                this.outletItemRequestList =  JSON.parse(outletItemData);
                this.isOutletRequest = false;
            });
            return this.outletItemRequestList;
        }
        catch (error) {
            console.log(error);
        }
    }
    getOutletItemRequestDetails = async (entryNo) => {
        try {
            const outletItemDetlsData = await apiAgent.outletItemRequest.getOutletItemRequestDetails(entryNo);

            runInAction(() => {

                this.outletItemRequestDetails = outletItemDetlsData;
            });
            return this.outletItemRequestDetails;
        }
        catch (error) {
            console.log(error);
        }
    }
    getOutletItemRequestSearch = async (SearchOptions) => {
        try {
            const Data = await apiAgent.outletItemRequest.getOutletItemRequestSearch(SearchOptions);
            this.outletItemRequestList = Data;

            runInAction(() => {
                this.outletItemRequestList = Data;

            })
            return this.outletItemRequestList;
        }
        catch (error) {
            console.log(error);
        }
    }
    deleteOutletItemRequestDetail = async (outletItemDetailId) => {
        try {
            const status = await apiAgent.outletItemRequest.deleteOutletItemRequestDetail(outletItemDetailId);
            return status;
        }
        catch (error) {
            console.log(error);
        }
    }
    deleteOutletItemRequestMaster = async (outletItemId) => {
        try {
            const status = await apiAgent.outletItemRequest.deleteOutletItemRequestMaster(outletItemId);
            return status;
        }
        catch (error) {
            console.log(error);
        }
    }
}
export default OutletItemRequestStore;

