import React, { useState, useEffect, ChangeEvent, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAdd, faL, faPenToSquare, faSearch, faTrash, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { Form, Modal, ProgressBar, Spinner, Button, Image } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import { observer } from 'mobx-react-lite';
import { useNavigate } from 'react-router-dom';
import useStores from '../../hooks';
import { IMRPPrice, ISellingPrice, ISellingPriceUpdateSearchoptions } from '../sellingPriceUpdate/model';
import moment from 'moment';
import { QRCode } from 'react-qrcode-logo';
import SelectSearchCriteria from '../../common/shared/selectSearchCriteria';
import { currentPageDatas } from '../../common/shared/utils';
import { Pagination, Stack } from '@mui/material';
import Cookies from 'js-cookie';

const SellingPriceUpdateB2B = observer((): JSX.Element => {

    const {supplierMasterStore, sellingPriceUpdateStore, userCreationStore } = useStores();
    const [userRights, setRightsObj] = useState<any>({})
    const navigate = useNavigate();
    const [mrpPriceDetail, setMRPPriceDetail] = useState<IMRPPrice>({})
    const [sellingPriceDetail, setSellingPriceDetail] = useState<ISellingPrice>({})
    const [getsellingHistoryDetl, setSellingHistoryDetl] = useState<any[]>([])


    const { getSellingPriceUpdate, getsellingPriceUpdates, getSellingHistory,
        getsellingPriceMRPDetls, getMRPHistory, getsellingHistoryDetls, getSellingDetails, saveSellingDetails,
        getLastInwardDetls, getLastInwardHistory, saveMRPDetails } = sellingPriceUpdateStore;

    const [isLoading, setLoading] = useState(true);
    const [isSuccessModal, setSuccessModal] = useState(false);
    const [isFailureModal, setFailureModal] = useState(false);

    const [isSellingPrice, setSellingPriceModal] = useState(false);
    const [isSellingPriceMRP, setSellingPriceModalMRP] = useState(false);
    const [isLastInward, setLastInwardModel] = useState(false);
    const [isUpdateMRP, setUpdateMRP] = useState(false);
    const [isUpdateSelling, setUpdateSelling] = useState(false);
    const [isAccessModal, setAccessModal] = useState(false);
    const [isLoadModal, setLoadModal] = useState(false);
     console.log(sellingPriceDetail,'sellingPriceDetail');
     const [canAdd, setCanAdd] = useState(false);
  const [canEdit, setCanEdit] = useState(false);
  const [canView, setCanView] = useState(false);
  // const [canPrint, setCanPrint] = useState(false);  
  // const [canUpdate, setCanUpdate] = useState(false); 
     
    const [errors, setErrors] = useState<any>({})

    const [isSearch, setSearch] = useState(false);
    const [filteredData, setFilteredData] = useState<any[]>([])

    const handleFailureModalClose = () => setFailureModal(false);
    const handleAccessModalClose = () => {
        navigate('/Admin/Home');
      }
  
 
    async function fetchSellingPrice() {
        const [userRights, data] = await Promise.all([userCreationStore?.getUserRight('Selling_price_B2B'), getSellingPriceUpdate()])
        if (userRights) {
            setRightsObj({ ...userRights })
        }
        if (data !== undefined) {
            setLoading(false);
        }
        setLoading(false);
        const userRight = await Promise.all([userCreationStore?.getUserRight('Selling_price_B2B')])
        console.log(userRight, 'userRightschk');
        setRightsObj([userRight]);
        console.log(userRight[0]?.add, 'userRights');

        if (userRight[0]?.add || userRight[0]?.edit || userRight[0]?.delete || userRight[0]?.print || userRight[0]?.update || userRight[0]?.view) {
            setAccessModal(false);
            setLoadModal(false);
        } else {
            setAccessModal(true);
            setLoadModal(true);
        }
    }

    const isCurrentPage = useRef(true);

    // useEffect(() => {
    //     if (isCurrentPage.current) {
    //         fetchSellingPrice();
    //         isCurrentPage.current = false;
    //     }
    //     return () => { }
    // }, [])
    const isCurrenPage = useRef(true)
    const empid = Number(Cookies.get('userId'));
    const formName = 'Selling_price_B2B';
  
    useEffect(() => {
  
      const fetchRights = async () => {
  
        try {
          const data = await supplierMasterStore.fetchRights(empid, formName);
 
          if (Array.isArray(data) && data.length > 0) {
            const specificFieldValue = data[0].sts;
            const rightsValue = data[0].rights;
            const lettersArray = rightsValue.split('');
            if (specificFieldValue === 1) {
              if (isCurrenPage.current) {
                fetchSellingPrice();
               
                isCurrenPage.current = false
                if (lettersArray.includes('a')) {
                  setCanAdd(true);
                } else {
                  navigate('/Admin/SellingPriceUpdateB2B');
                }
                if (lettersArray.includes('e')) {
                  setCanEdit(true);
                }
                // if (lettersArray.includes('v')) {
                //   setCanView(true); 
                // }     
                // if (lettersArray.includes('p')) {
                //   setCanPrint(true); 
                // }       
                // if (lettersArray.includes('u')) {
                //   setCanUpdate(true); 
                // }                     
              }
            } else if (specificFieldValue === 0) {
              setLoadModal(true)
            }
          }
         
        } catch (error) {
          console.error("Error fetching rights:", error);
        }
      };
      fetchRights();
    }, [empid, formName]);
  

    const handleSuccessModalClose = () => {
        setSuccessModal(false)
        // setLoading(true);
    }

    async function handleMRPHistory(itemId, batchno, e) {
        e.preventDefault();
        const getmrphis = await getMRPHistory(itemId, batchno);
        setSellingPriceModal(true);
    }

    async function handleSellingPriceMRP(itemId, batchno, e) {
        e.preventDefault();
        const getsellhis = await getSellingHistory(itemId, batchno, 'B2B');
        console.log(getsellhis,'getsellhis');
        
        setSellingHistoryDetl(getsellhis);
        console.log(getsellingHistoryDetl,'getsellingHistoryDetl');
        
        setSellingPriceModalMRP(true);
    }

    async function handleLastInward(itemId, batchno, e) {
        e.preventDefault();
        await getLastInwardHistory(itemId, batchno);
        setLastInwardModel(true);
    }

    async function handleSellingPricee(itemId, batchNo,mrpPrice, e,rsno) {
        let sellingObject: ISellingPrice = {};
        setSellingPriceDetail(sellingObject);
        e.preventDefault();
        let data = getsellingPriceUpdates?.find((each) => each?.itemId === itemId && each?.batchno === batchNo && each?.newMrp === mrpPrice && each?.rsno === rsno)
        // const data = await getSellingDetails(itemId, batchNo,Number(mrpPrice));
        if (data !== undefined) {
            setUpdateSelling(true);
            sellingPriceDetail.itemName = data.itemName
            sellingPriceDetail.Batchno = batchNo
            sellingPriceDetail.itemId = data.itemId
            sellingPriceDetail.itemMrpId = data.itemMRPId
            sellingPriceDetail.b2BPrice = data.b2B;
            sellingPriceDetail.b2CPrice = data.b2C;
            sellingPriceDetail.b2BNewPrice = data.b2BNewPrice;
            sellingPriceDetail.b2CnewPrice = data.b2CNewPrice;
            sellingPriceDetail.b2BStPer = data.b2BStP;
            sellingPriceDetail.b2CStPer = data.b2CStP;
            sellingPriceDetail.rsno = data.rsno;
            sellingPriceDetail.oldEffectiveDate = moment(data.oldEffectiveDate).format('YYYY-MM-DD hh:mm A');
            sellingPriceDetail.mrpPrice = data.newMrp;
            setSellingPriceDetail(sellingPriceDetail);
        }
        setUpdateSelling(true);
    }

    useEffect(() => {
        if (getsellingHistoryDetl.length > 0) {
            console.log(getsellingHistoryDetl, 'getsellingHistoryDetl after state update');
        }
    }, [getsellingHistoryDetl]);

    const handleClose = () => {
        setSellingPriceModal(false)
        setUpdateMRP(false)
        setUpdateSelling(false);
        setUpdateSelling(false);
    }

    const handleCloseMRP = () => {
        setSellingPriceModalMRP(false)
    }

    const handleCloseInward = () => {
        setLastInwardModel(false)
    }

    const handleMRPPrice = async (itemId, batchNo,rsno) => {
        let mrpObject: IMRPPrice = {};
        setMRPPriceDetail(mrpObject);
        let findObj = getsellingPriceUpdates?.find((each) => each?.itemId === itemId && each?.batchno === batchNo&& each?.rsno === rsno)
        if (findObj !== undefined) {
            mrpPriceDetail.itemName = findObj.itemName
            mrpPriceDetail.Batchno = batchNo
            mrpPriceDetail.itemId = findObj.itemId
            mrpPriceDetail.itemMrpId = findObj.itemMRPId
            mrpPriceDetail.mrp = Number(findObj.newMrp);
            mrpPriceDetail.oldEffectiveDate = moment(findObj.newEffectiveDateTime).format('YYYY-MM-DD hh:mm A');
            mrpPriceDetail.newMRP = undefined;
            setMRPPriceDetail(mrpPriceDetail);
            setUpdateMRP(true);
        }
    }

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setMRPPriceDetail({ ...mrpPriceDetail, [name]: value })
    }

    const handleSellingInput = (e) => {
        let b2bdiscount: any = 0;
        let b2bnewPrice: number = 0;

        const { name, value } = e.target;
        if (name === 'b2BNewPrice') {
            b2bdiscount = (((Number(sellingPriceDetail.mrpPrice) - Number(value)) / Number(sellingPriceDetail.mrpPrice)) * 100)?.toFixed(2);
            sellingPriceDetail.b2BStPer! = Number(b2bdiscount);
        } else if (name === 'b2BStPer') {
            if (value > 0) 
            {
                b2bnewPrice = (Number(sellingPriceDetail.mrpPrice) * Number(value!)) / 100;
                b2bnewPrice = Number(sellingPriceDetail.mrpPrice) - Number(b2bnewPrice!);
                sellingPriceDetail.b2BNewPrice! = b2bnewPrice
            }        
        }

        setSellingPriceDetail({ ...sellingPriceDetail, [name]: (name === 'b2BNewPrice' || name === 'b2BStPer') ? Number(value) : value })
    }

    async function UpdateSellingPrice(e) {
        let error: any = {};
        if (!sellingPriceDetail?.b2BNewPrice) {
            error.b2BNewPrice = 'Enter B2B New Price'
        }
        // if (!sellingPriceDetail?.b2BStPer) {
        //     error.b2BStPer = 'Enter B2B Percentage'
        // }
        if (!sellingPriceDetail?.effectiveDate) {
            error.effectiveDate = 'Enter Effective Date'
        }
        setErrors({ ...error })
        if (Object.keys(error)?.length === 0) {
            sellingPriceDetail.b2BNewPrice! = Number(sellingPriceDetail.b2BNewPrice);
            sellingPriceDetail.b2BStPer! = Number(sellingPriceDetail.b2BStPer!);
            sellingPriceDetail.effectiveDate = moment(sellingPriceDetail.effectiveDate).format('YYYY-MM-DD hh:mm A');
            sellingPriceDetail.mrpPrice = Number(sellingPriceDetail.mrpPrice);
            setUpdateSelling(false);
            e.preventDefault();

            const status = await saveSellingDetails(sellingPriceDetail);

            if (status === "Success") {
                setLoading(false);
                setSuccessModal(true)
                fetchSellingPrice();
            } else {
                setUpdateSelling(true);
                setFailureModal(true);
            }
        }
    }

    async function UpdateMRPPrice(e) {
        let error: any = {}
        if (!mrpPriceDetail?.newMRP) {
            error.newMRP = 'Enter MRP Price'
        }
        if (!mrpPriceDetail?.effectiveDate) {
            error.effectiveDate = 'Select Effective Date'
        }
        setErrors({ ...error })
        if (Object.keys(error)?.length === 0) {
            mrpPriceDetail.effectiveDate = moment(mrpPriceDetail.effectiveDate).format('YYYY-MM-DD hh:mm A');
            setUpdateMRP(false);
            e.preventDefault();
            const status = await saveMRPDetails(mrpPriceDetail);
            if (status === "Success") {
                fetchSellingPrice();
                setLoading(false);
                setSuccessModal(true)
            } else {
                setUpdateMRP(true);
                setFailureModal(true);
            }
        }
    }

    const [totalPages, setTotalPage] = useState(1);
    const [currentPageData, setCurrentPageData] = useState<any[]>([])

    useEffect(() => {
        if (getsellingPriceUpdates?.length) {
            goToPage(1)
        } else {
            setCurrentPageData(getsellingPriceUpdates)
        }
    }, [getsellingPriceUpdates])

    const goToPage = (value: number) => {
        const currentPageList = currentPageDatas(getsellingPriceUpdates, value, 10)
        setTotalPage(currentPageList?.totalPages)
        setCurrentPageData(currentPageList?.currentPageData)
    };

    if (isLoadModal) {
        return (
            <div className="container">
                {isLoadModal && <h1>Access Denied</h1>}
                <Modal show={isLoadModal} onHide={handleAccessModalClose} className='SubmitModal'>
                    <Modal.Body>
                        <div className='Details Success'>
                            <div className='imgBox'>
                                <Image src={require('../../../gsmecom/images/warning.png')} />
                            </div>
                            <h4>Access Denied</h4>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleAccessModalClose}>Ok</Button>
                    </Modal.Footer>
                </Modal>
            </div>
        );
    }
    else {
        return (
            <div className='container-fluid'>
                {
                    isLoading ?
                        <div className='SpinnerBox'>
                            <Spinner animation="border" role="status">
                                <span className="visually-hidden">Loading...</span>
                            </Spinner>
                        </div> :
                        <>
                            <div className='vertical-space-20'></div>
                            <SelectSearchCriteria searchEnable={(bool) => setSearch(bool)} searchArray={getsellingPriceUpdates}
                                searchTitles={[{ key: "itemName", value: "Item Name" }]}
                                emitSearchArray={(filterArray: any) => setFilteredData(filterArray)} />
                            <div className='inputBoxLists'>
                                <div className='vertical-space-20'></div>
                                <div className='ItemInwardInputBox' style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                    <div className='btnBox'>
                                        <button className='totalrecordBtn' type='submit' style={{ width: '20ch' }}>{getsellingPriceUpdates?.length} Records</button>
                                    </div>
                                    <Stack spacing={2} style={{ padding: '0px 24px 20px' }}>
                                        <Pagination count={totalPages} onChange={(ev, value) => goToPage(value)} color='primary' />
                                    </Stack>
                                </div>
                            </div>
                            <div className='vertical-space-20'></div>
                            <div className='tableListDetails'>
                                <table className="table table-striped">
                                    <thead>
                                        <tr style={{ textAlign: 'center' }}>
                                            <th scope='col'>Item Name </th>
                                            <th scope='col'>Batchno </th>
                                            <th scope='col'>MRP</th>
                                            <th scope='col'>Effective Date</th>
                                            <th scope='col'>New MRP</th>
                                            <th scope='col'>New Effective Date</th>
                                            <th scope='col'>MRP History </th>
                                            <th scope='col'>Inward </th>
                                            <th scope='col'>Update </th>
                                            <th scope='col'>B2B <br></br> Price</th>
                                            {/* <th scope='col'>B2C <br></br> Price</th> */}
                                            <th scope='col'> New  Price <br></br> B2B </th>
                                            {/* <th scope='col'> New  Price <br></br> B2C  </th> */}
                                            <th scope='col'>B2B st <br></br>Dis %</th>
                                            {/* <th scope='col'>B2C st <br></br> Dis %</th> */}
                                            <th scope='col'>Effective Date & Time</th>
                                            <th scope='col'>SP History </th>
                                            <th scope='col'>Update </th>
                                        </tr>
                                    </thead>
                                    <tbody style={{ textAlign: 'center' }}>
                                        {(isSearch ? filteredData : currentPageData)?.length > 0 &&
                                            (isSearch ? filteredData : currentPageData)?.map((val, key) => {
                                                console.log(filteredData, 'filteredData>>')
                                                return (
                                                    <tr key={key}>
                                                        <td>{val.itemName} </td>
                                                        {/* {val.batchno ?
                                                        <td><QRCode value={val.batchno} size={100} /></td> :
                                                        <td>{val.batchno}</td>
                                                    } */}
                                                        <td>{val.batchno} </td>
                                                        <td>{val.mrp} </td>
                                                        <td >{val.oldEffectiveDateTime}</td>
                                                        <td  >{(val.newMrp)}</td>
                                                        <td  >{moment(val.newEffectiveDateTime).format('DD-MMM-YYYY')}</td>
                                                        <td><button onClick={(e) => handleMRPHistory(val.itemId, val.batchno, e)}>History</button></td>
                                                        <td><button onClick={(e) => handleLastInward(val.itemId, val.batchno, e)}>Inward</button></td>
                                                        <td><button style={{ backgroundColor: '#dc3545', color: 'white' }} type='submit' onClick={() => handleMRPPrice(val.itemId, val.batchno,val?.rsno)}>Update</button></td>
                                                        <td >{val.b2B} </td>
                                                        {/* <td >{val.b2C} </td> */}
                                                        <td >{val.b2BNewPrice} </td>
                                                        {/* <td >{val.b2CNewPrice} </td> */}
                                                        <td >{val.b2BStP} </td>
                                                        {/* <td >{val.b2CStP} </td> */}
                                                        <td >{val.b2effectiveDateTime ? moment(val.b2effectiveDateTime).format('YYYY-MM-DD hh:mm A') : ''}</td>
                                                        <td><button disabled={userRights?.view} onClick={(e) => handleSellingPriceMRP(val.itemId, val?.batchno, e)}>History</button></td>
                                                        <td><button disabled={userRights?.view} style={{ backgroundColor: '#dc3545', color: 'white' }} type='submit' onClick={(e) => handleSellingPricee(val.itemId, val.batchno,val.newMrp, e, val?.rsno)}>Update</button></td>
                                                    </tr>
                                                )
                                            })
                                        }

                                    </tbody>
                                </table>
                            </div>
                            {
                                isSellingPrice ?
                                    <Modal show={isSellingPrice} onHide={handleClose} className='PriceHistoryModel'>
                                        <Modal.Header closeButton>
                                            <Modal.Title>Detail</Modal.Title>
                                        </Modal.Header>
                                        <Modal.Body>
                                            <div className='tableBox'>
                                                <table className="table table-striped">
                                                    <thead>
                                                        <tr>
                                                            <th scope="col">MRP Rate</th>
                                                            <th scope="col">Eff. Date</th>

                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {
                                                            getsellingPriceMRPDetls && getsellingPriceMRPDetls.map((val, key) => {
                                                                return (
                                                                    <tr key={key}>
                                                                        <td>{val.mrpRate} </td>
                                                                        <td>{moment(val.effecticeDate).format('DD-MMM-YYYY')}</td>
                                                                    </tr>
                                                                )
                                                            })
                                                        }
                                                    </tbody>
                                                </table>
                                            </div>
                                        </Modal.Body>
                                    </Modal> : null
                            }
                            {
                                isSellingPriceMRP ?
                                    <Modal show={isSellingPriceMRP} onHide={handleCloseMRP} className='PriceHistoryModel'>
                                        <Modal.Header closeButton>
                                            <Modal.Title>Detail</Modal.Title>
                                        </Modal.Header>
                                        <Modal.Body>
                                            <div className='tableBox'>
                                                <table className="table table-striped">
                                                    <thead>
                                                        <tr>
                                                            <th scope="col">B2B Price</th>
                                                            <th scope="col">B2B st.Dis % </th>
                                                            {/* <th scope="col">B2C Price </th> */}
                                                            {/* <th scope="col">B2C st.Dis %</th> */}
                                                            <th scope="col">Eff.From </th>

                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {Array.isArray(getsellingHistoryDetl) &&
                                                            getsellingHistoryDetl.map((val, key) => (
                                                                <tr key={key}>
                                                                    <td>{val.B2BNewPrice}</td>
                                                                    <td>{val.B2Bstp}</td>
                                                                    <td>{val.B2Effectdate ? moment(val.B2Effectdate).format('YYYY-MM-DD hh:mm A') : ''}</td>
                                                                </tr>
                                                            ))}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </Modal.Body>
                                    </Modal> : null
                            }
                            {
                                isLastInward ?
                                    <Modal show={isLastInward} onHide={handleCloseInward} className='PriceHistoryModel'>
                                        <Modal.Header closeButton>
                                            <Modal.Title>Inward</Modal.Title>
                                        </Modal.Header>
                                        <Modal.Body>
                                            <div className='tableBox'>
                                                <table className="table table-striped">
                                                    <thead>
                                                        <tr>
                                                            <th scope="col">Inward no</th>
                                                            <th scope="col">Supplier Name</th>
                                                            <th scope="col">Inward Date</th>
                                                            <th scope="col">Rate </th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {
                                                            getLastInwardDetls.map((val, key) => {
                                                                return (
                                                                    <tr key={key}>
                                                                        <td>{val.inwardNo} </td>
                                                                        <td>{val.supplierName} </td>
                                                                        <td>{moment(val.inwardDate).format('DD-MMM-YYYY')}</td>
                                                                        <td>{val.rate} </td>
                                                                    </tr>
                                                                )
                                                            })
                                                        }
                                                    </tbody>
                                                </table>
                                            </div>
                                        </Modal.Body>
                                    </Modal> : null
                            }
                            {
                                mrpPriceDetail ?
                                    <Modal show={isUpdateMRP} onHide={handleClose} className='PriceHistoryModel'>
                                        <Modal.Header closeButton>
                                            <Modal.Title>Update MRP Price</Modal.Title>
                                        </Modal.Header>
                                        <Modal.Body>
                                            <div className='container'>

                                                <div className='vertical-space-20'></div>
                                                <div className='outletInputField inputFormBox'>
                                                    <div className='inputBoxLists pd-b-0'>
                                                        <div className='LgInputListsBox mr-left-0'>
                                                            <div className='row'>
                                                                <div className='col-md-6'>
                                                                    <div className='inputBox'>
                                                                        <label>Item Name</label>
                                                                        <input type="input" disabled style={{ width: "100%" }} name='itemName'
                                                                            value={mrpPriceDetail.itemName}
                                                                        ></input>
                                                                    </div>
                                                                    <div className='vertical-space-10'></div>
                                                                </div>
                                                            </div>
                                                            <div className='row'>
                                                                <div className='col-md-5'>
                                                                    <div className='inputBox'>
                                                                        <label>MRP</label>
                                                                        <input type="input" disabled style={{ width: "100%" }} name='mrp'
                                                                            value={mrpPriceDetail.mrp}
                                                                        ></input>
                                                                    </div>
                                                                    <div className='vertical-space-10'></div>
                                                                </div>
                                                                <div className='col-md-5'>
                                                                    <div className='inputBox'>
                                                                        <label>New MRP <span>*</span></label>
                                                                        <input type="input" style={{ width: "100%" }} name='newMRP'
                                                                            onChange={handleInputChange}
                                                                            value={mrpPriceDetail.newMRP}
                                                                        ></input>
                                                                    </div>
                                                                    {errors?.newMRP && <p style={{ color: 'red' }}>{errors?.newMRP}</p>}
                                                                    <div className='vertical-space-10'></div>
                                                                </div>
                                                            </div>
                                                            <div className='row'>
                                                                <div className='col-md-5'>
                                                                    <div className='inputBox'>
                                                                        <label>Effective Date</label>
                                                                        <input type="datetime-local" style={{ width: "100%" }} name='oldEffectiveDate'
                                                                            placeholder="DD-MMM-YYYY" data-date="" data-date-format="DD MMMM YYYY" disabled
                                                                            value={(mrpPriceDetail.oldEffectiveDate || '').toString().substring(0, 16)}
                                                                        ></input>
                                                                    </div>
                                                                    <div className='vertical-space-10'></div>
                                                                </div>
                                                                <div className='col-md-5'>
                                                                    <div className='inputBox'>
                                                                        <label>New Effective Date <span>*</span></label>
                                                                        <input type="datetime-local" style={{ width: "100%" }} name='effectiveDate'
                                                                            onChange={handleInputChange}
                                                                            value={mrpPriceDetail.effectiveDate}
                                                                        ></input>
                                                                    </div>
                                                                    {errors?.effectiveDate && <p style={{ color: 'red' }}>{errors?.effectiveDate}</p>}
                                                                    <div className='vertical-space-10'></div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='btnBox'>
                                                        <button className='secondaryBtn' >Cancel</button>
                                                        <button className='dfBtn' type='submit' onClick={UpdateMRPPrice} >Submit</button>
                                                    </div>
                                                </div>

                                            </div>
                                        </Modal.Body>
                                    </Modal> : null
                            }
                            {
                                sellingPriceDetail ?
                                    <Modal show={isUpdateSelling} onHide={handleClose} className='PriceHistoryModel'>
                                        <Modal.Header closeButton>
                                            <Modal.Title>Update Selling Price</Modal.Title>
                                        </Modal.Header>
                                        <Modal.Body>
                                            <div className='container'>
                                                <div className='vertical-space-20'></div>
                                                <div className='outletInputField inputFormBox'>
                                                    <div className='inputBoxLists pd-b-0'>
                                                        <div className='LgInputListsBox mr-left-0'>
                                                            <div className='row'>
                                                                <div className='col-md-6'>
                                                                    <div className='inputBox'>
                                                                        <label>Item Name</label>
                                                                        <input type="input" disabled style={{ width: "100%" }} name='itemName'
                                                                            value={sellingPriceDetail.itemName}
                                                                        ></input>
                                                                    </div>
                                                                    <div className='vertical-space-10'></div>
                                                                </div>
                                                                <div className='col-md-6'>
                                                                    <div className='inputBox'>
                                                                        <label> Batch No</label>
                                                                        <input type="input" disabled style={{ width: "100%" }} name='Batchno'
                                                                            value={sellingPriceDetail.Batchno}
                                                                        ></input>
                                                                    </div>
                                                                    <div className='vertical-space-10'></div>
                                                                </div>
                                                                <div className='col-md-4'>
                                                                    <div className='inputBox'>
                                                                        <label>B2B Price</label>
                                                                        <input type="input" disabled style={{ width: "100%" }} name='b2BPrice'
                                                                            value={sellingPriceDetail.b2BPrice}
                                                                        ></input>
                                                                    </div>
                                                                    <div className='vertical-space-10'></div>
                                                                </div>
                                                                <div className='col-md-4'>
                                                                    <div className='inputBox'>
                                                                        <label>New B2B Price <span>*</span></label>
                                                                        <input type="number" style={{ width: "100%" }} name='b2BNewPrice'
                                                                            value={sellingPriceDetail.b2BNewPrice}
                                                                            onChange={handleSellingInput}
                                                                        ></input>
                                                                    </div>
                                                                    {errors?.b2BNewPrice && <p style={{ color: 'red' }}>{errors?.b2BNewPrice}</p>}
                                                                    <div className='vertical-space-10'></div>
                                                                </div>
                                                                <div className='col-md-4'>
                                                                    <div className='inputBox'>
                                                                        <label>B2B Std Dis% <span>*</span></label>
                                                                        <input type="number" style={{ width: "100%" }} name='b2BStPer'
                                                                            value={sellingPriceDetail.b2BStPer}
                                                                            onChange={handleSellingInput}
                                                                        ></input>
                                                                    </div>
                                                                    {errors?.b2BStPer && <p style={{ color: 'red' }}>{errors?.b2BStPer}</p>}
                                                                    <div className='vertical-space-10'></div>
                                                                </div>
                                                                <div className='col-md-5'>
                                                                    <div className='inputBox'>
                                                                        <label>Effective Date</label>
                                                                        <input type="datetime-local" style={{ width: "100%" }} name='oldEffectiveDate'
                                                                            placeholder="DD-MMM-YYYY" data-date="" data-date-format="DD MMMM YYYY" disabled
                                                                            value={(sellingPriceDetail.oldEffectiveDate || '').toString().substring(0, 16)}
                                                                        ></input>
                                                                    </div>
                                                                    <div className='vertical-space-10'></div>
                                                                </div>
                                                                <div className='col-md-5'>
                                                                    <div className='inputBox'>
                                                                        <label>New Effective Date <span>*</span></label>
                                                                        <input type="datetime-local" style={{ width: "100%" }} name='effectiveDate'
                                                                            value={sellingPriceDetail.effectiveDate}
                                                                            onChange={handleSellingInput}
                                                                        ></input>
                                                                    </div>
                                                                    {errors?.b2CStPer && <p style={{ color: 'red' }}>{errors?.b2CStPer}</p>}
                                                                    <div className='vertical-space-10'></div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='btnBox'>
                                                        <button className='secondaryBtn' onClick={handleClose}>Cancel</button>
                                                        <button className='dfBtn' type='submit' onClick={UpdateSellingPrice} >Submit</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </Modal.Body>
                                    </Modal> : null
                            }
                            <Modal show={isSuccessModal} onHide={handleSuccessModalClose} className='SubmitModal'>
                                <Modal.Body>
                                    <div className='Details Success'>
                                        <div className='imgBox'>
                                            <Image src={require('../../../../image/checked.png')} />
                                        </div>
                                        <h4>Succesfully Submitted</h4>
                                    </div>
                                </Modal.Body>
                                <Modal.Footer>
                                    <Button variant="secondary" onClick={handleSuccessModalClose}>
                                        Ok
                                    </Button>
                                </Modal.Footer>
                            </Modal>
                            <Modal show={isFailureModal} onHide={handleFailureModalClose} className='SubmitModal'>
                                <Modal.Body>
                                    <div className='Details Success'>

                                        <div className='imgBox'>
                                            <Image src={require('../../../../image/warning.png')} />
                                        </div>
                                        <h4>Failed</h4>
                                    </div>
                                </Modal.Body>
                                <Modal.Footer>
                                    <Button variant="secondary" onClick={handleFailureModalClose}>
                                        Ok
                                    </Button>
                                </Modal.Footer>
                            </Modal>
                        </>
                }
            </div>
        );
    }
});

export default SellingPriceUpdateB2B;
