import { action, set, makeAutoObservable, observable, runInAction } from 'mobx';
import apiAgent from '../../../APIAgent';

class PurchaseBillRegStore {
    @observable purchaseItemDetailsExport= new Map();  
    constructor() {
        makeAutoObservable(this);
    }

    getPurchaseBillRegDetails = async (salesRegisterRequestExport) => {
        try {
            const data = await apiAgent.purchaseBillReg.loadpurchaseitemsExport(salesRegisterRequestExport);
            return  JSON.parse(data);
        }
        catch (error) {
            console.log(error);
        }
    }
    

}
export default PurchaseBillRegStore;