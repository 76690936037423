import { getCurrentMonthFirstDate } from "../../../common/shared/utils";
import Cookies from 'js-cookie';

export class IOutletStockAnalysis {
    fromDate?: string = getCurrentMonthFirstDate()?.firstDate;
    toDate?: string = getCurrentMonthFirstDate()?.currentDate;
    itemId?: number = 0;
    category?: string = '';
    subCategoryId?: number = 0;
    outletId?: number = Number(Cookies.get('outletId'));
}
export class ISalesRegisterRequest {
    startDate?: string = getCurrentMonthFirstDate()?.firstDate;
    endDate?: string = getCurrentMonthFirstDate()?.currentDate;
    outletId?: number = 0;
}
