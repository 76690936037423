import axios, { AxiosError, AxiosResponse } from 'axios';
import { ILogin, IMemberLogin, IVerifyOTP } from '../Login/model';
import { ICartProduct, IProduct, IProductMember } from '../Products/model';
import { IProductDeatils } from '../CheckOut/model';


 axios.defaults.baseURL = 'https://api.varthagam.in/api/';
  // axios.defaults.baseURL = 'http://localhost:/api/';
//   axios.defaults.baseURL = 'http://localhost:49882/api/';

const responseBody = <T>(response: AxiosResponse<T>) => response.data;

var config = {

  headers: {
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Methods': '*',
    'Access-Control-Allow-Headers': '*',
    'Content-Type': 'application/json; charset=utf-8',
    'Accept': '*/*'
  }
};

const sleep = (delay: number) => {
  return new Promise((resolve) => {
    setTimeout(resolve, delay);
  })
}

axios.interceptors.response.use(async response => {
  if (process.env.NODE_ENV === 'development') await sleep(1000);
  return response;
}, (error: AxiosError) => {
  return Promise.reject(error);
})

const request = {
  get: <T>(url: string, id: 0) => axios.get<T>(url).then(responseBody),
  post: <T>(url: string, body: {}) => axios.post<T>(url, body).then(responseBody),
  put: <T>(url: string, body: {}) => axios.put<T>(url, body).then(responseBody),
  delete: <T>(url: string) => axios.delete<T>(url).then(responseBody)
};

const memberLogin = {
  saveEcomMember: (memberLogin: IMemberLogin) => {

    return axios.post('Member/EcomMemberSave', JSON.stringify(memberLogin), config).then(res => {

      return res.data;
    }).catch(function (err) {
      if (err.response) {

        console.log(err.response)
      } else if (err.request) {

        console.log(err.request)
      } else {
        console.log("Error", err.message);
      }
      console.log(err.config);
    })
  },
  verifyOTP: (verifyOTP: IVerifyOTP) => {
    return axios.post('Member/VerifyOTP', JSON.stringify(verifyOTP), config).then(res => {

      return res.data;
    }).catch(function (err) {
      if (err.response) {

        console.log(err.response)
      } else if (err.request) {

        console.log(err.request)
      } else {
        console.log("Error", err.message);
      }
      console.log(err.config);
    })
  },
  UpdateEmail: (verifyOTP: IVerifyOTP) => {

    return axios.post('Member/UpdateEmailUser', JSON.stringify(verifyOTP), config).then(res => {

      return res.data;
    }).catch(function (err) {
      if (err.response) {

        console.log(err.response)
      } else if (err.request) {

        console.log(err.request)
      } else {

        console.log("Error", err.message);
      }
      console.log(err.config);
    })
  },
  getLogin: (login: ILogin) => {

    return axios.post('Member/GetLogin', JSON.stringify(login), config).then(res => {

      return res.data;
    }).catch(function (err) {
      if (err.response) {

        console.log(err.response)
      } else if (err.request) {

        console.log(err.request)
      } else {

        console.log("Error", err.message);
      }
      console.log(err.config);
    })
  },
  getAdminLogin: (login: ILogin) => {
    return axios.post('Member/GetAdminLogin', JSON.stringify(login), config)
      .then(res => {

        return res.data;
      }).catch(function (err) {
        if (err.response) {

          console.log(err.response)
        } else if (err.request) {

          console.log(err.request)
        } else {

          console.log("Error", err.message);
        }
        console.log(err.config);
      })
  },
  checkEmailExist: async (emailAddress) => {

    return axios.get('Member/ForgotPasswordOTP', {
      params: {
        emailAddress: JSON.stringify(emailAddress)
      }

    }).then(res => {

      return res.data;

    }).catch(err => {
      if (err.response) {

        console.log(err.response)
      } else if (err.request) {

        console.log(err.request)
      } else {
        // anything else 
      }
    })
  },
}
const ecomHome = {
  getLoadItemImages: async () => {
    return axios.get('EcomHome/GetLoadItemImages').then(res => {
      return res.data;
    }).catch(err => {
      if (err.response) {

        console.log(err.response)
      } else if (err.request) {

        console.log(err.request)
      } else {
        // anything else 
      }
    })
  },
  getOnlineItems: async () => {
    return axios.get('EcomHome/GETItemwiseStockOnlineJson').then(res => {
      return res.data;
    }).catch(err => {
      if (err.response) {

        console.log(err.response)
      } else if (err.request) {

        console.log(err.request)
      } else {
        // anything else 
      }
    })
  },
  saveProductCart: (productCart: ICartProduct) => {
    return axios.post('EcomHome/SaveProductCarts', JSON.stringify(productCart), config).then(res => {
      return res.data;
    }).catch(function (err) {
      if (err.response) {
        console.log(err.response)
      } else if (err.request) {
        console.log(err.request)
      } else {
        console.log("Error", err.message);
      }
      console.log(err.config);
    })
  },
  getCartDetails: async (memberId) => {
    return axios.get<any>(`EcomHome/GetCartProducts?memberId=${memberId}`, config).then(res => {
      return res.data;
    }).catch(err => {
      if (err.response) {

        console.log(err.response)
      } else if (err.request) {

        console.log(err.request)
      } else {
        // anything else 
      }
    })
  },
}
const product = {
  saveProductCart: (productCart: ICartProduct) => {
    return axios.post('Products/SaveProductCarts', JSON.stringify(productCart), config).then(res => {
      return res.data;
    }).catch(function (err) {
      if (err.response) {
        console.log(err.response)
      } else if (err.request) {
        console.log(err.request)
      } else {
        console.log("Error", err.message);
      }
      console.log(err.config);
    })
  },
  getItemDetails: async (productMember: IProductMember) => {
    return axios.post<any>('Products/GetProductDetail', productMember, config).then(res => {

      return res.data;

    }).catch(err => {
      if (err.response) {

        console.log(err.response)
      } else if (err.request) {

        console.log(err.request)
      } else {
        // anything else 
      }
    })
  },
}
const carts = {

  getCartDetails: async (cartProduct: IProductMember) => {

    return axios.post<any>('Products/GetCartProducts', JSON.stringify(cartProduct), config).then(res => {

      return res.data;

    }).catch(err => {
      if (err.response) {

        console.log(err.response)
      } else if (err.request) {

        console.log(err.request)
      } else {
        // anything else 
      }
    })
  },
  getCartDetailsCookies: async (productIds) => {

    return axios.get<any>('Products/GetCartProductsCookies', {
      params: {
        productIds: productIds
      }
    }).then(res => {

      return res.data;

    }).catch(err => {
      if (err.response) {

        console.log(err.response)
      } else if (err.request) {

        console.log(err.request)
      } else {
        // anything else 
      }
    })
  },
}
const checkout = {

  saveProduct: async (products: IProductDeatils) => {

    return axios.post<any>('Products/SaveProducts', JSON.stringify(products), config).then(res => {
      return res.data;
    }).catch(err => {
      if (err.response) {

        console.log(err.response)
      } else if (err.request) {

        console.log(err.request)
      } else {
        // anything else 
      }
    })
  },
}
const ecomAPIAgent = {
  memberLogin,
  ecomHome,
  product,
  carts,
  checkout
}
export default ecomAPIAgent